<div id="header">
  <p>
    Die einzelnen Prozessdialoge folgen stets einem gleichbleibenden Aufbau der folgenden drei
    Sektionen:
  </p>
  <p>&nbsp;</p>
  <ol>
    <li>
      <p>
        <strong>Kontextdaten:</strong>
      </p>
      <ul>
        <li>
          Angabe des Prozessnamens und des aktuell konkreten Prozessschrittes gefolgt der
          eindeutigen Vorgangsnummer.
        </li>
        <li>
          Anschließend folgt eine Zusammenstellung von Kontextdaten, die einen übergeordneten Bezug
          zum Prozessdialog haben. Sie bleiben im gesamten Prozessverlauf gleich.
        </li>
        <li>Diese Sektion kann bei Bedarf auf- bzw. zusammengeklappt werden.</li>
      </ul>
    </li>
    <li>
      <p><strong>Hilfe:</strong></p>
      <ul>
        <li>Beschreibt den Aufbau der Hilfe-Sektionen im Allgemeinen.</li>
        <li>
          Beschreibt in einem zweiten Abschnitt die Verwendung des aktuell aktiven Prozessdialoges.
          Dieser Abschnitt ist geteilt in:
          <ul>
            <li>
              Zielbeschreibung: Sie beschreibt an oberster Stelle das Ziel des Prozessdialoges und
              begründet ggf. dessen Notwendigkeit.
            </li>
            <li>Detaillierte Hinweise zum Formularbereich.</li>
          </ul>
        </li>
        <li>Diese Sektion kann bei Bedarf auf- bzw. zusammengeklappt werden.</li>
      </ul>
    </li>
    <li>
      <p><strong>Tabellarischer Formularbereich:</strong></p>
      <ul>
        <li>In diesem Abschnitt findet die eigentliche Dateneingabe durch den Benutzer statt.</li>
        <li>
          In einem tabellarischen Formular werden begleitende Informationen der Datensätze sowie
          Eingabefelder zeilenweise angeboten.
        </li>
        <li>
          Wenn zusätzliche Informationen zu einer Tabellenzelle verfügbar sind erhalten Sie diese,
          indem Sie mit dem Mauszeiger über dem entsprechenden Symbol oder Inhalt verharren.
        </li>
      </ul>
    </li>
  </ol>
  <p><strong>Zielbeschreibung zum aktuellen Prozessdialog:</strong></p>
</div>

<div id="main" class="mt-3">
  <div *ngIf="activity === 'ut_report_service_level'">
    <p>Sie befinden sich im Prozessdialog <strong>Service Level melden.</strong></p>
    <p>&nbsp;</p>
    <p>
      Hier können Sie als TI-SDM die in Bezug der Kontextdaten gültigen Vereinbarungen (z.B.
      Vorgaben aus der TI-Spezifikation oder Verträge) noch ausstehende oder anzureichernde Service
      Level (SL) Informationen melden. In diesem Prozessschritt werden dabei nur die Service Level
      behandelt, die für die entsprechende Berichtsperiode nicht automatisch ermittelt, manuell zu
      berichten oder verletzt wurden. Wurde ein Service Level nicht erreicht sind zu diesen weiteren
      Angaben bereitzustellen.
    </p>
    <p>&nbsp;</p>
    <p>
      Erst wenn alle notwendigen Angaben eingetragen wurden, erlaubt das System das Melden der
      Service Level-Berichtsinformationen und damit diesen Prozessdialog abzuschließen.
    </p>
    <p>&nbsp;</p>
    <p>
      Die Vorgangsbearbeitung wird zur weiteren Bearbeitung an die gematik im Rahmen der
      <strong>formellen und materiellen Prüfung</strong> übertragen. Werden im Rahmen dieser Prüfung
      nicht alle Service Level-Datensätze von der gematik akzeptiert kann es vorkommen, dass dieser
      Prozessschritt für die von der gematik nicht akzeptierten Datensätze wiederholt durchgeführt
      wird.
    </p>
    <p>&nbsp;</p>
    <p><strong>Tabellarischer – informativer – Formularbereich (Angaben je Spalte):</strong></p>
    <ul>
      <li>
        Service Level
        <ul>
          <li>
            Enthält die Kennzahl Identifikation und den Namen des Service Level - abgleitet aus den
            Vorgaben der gematik GmbH.
          </li>
          <li>
            Handelt es sich um einen technischen Service Level werden hier weitere Angaben zur
            logischen Produktinstanz aufgeführt.
          </li>
        </ul>
      </li>
      <li>
        Sollwertebereich
        <ul>
          <li>
            Hier ist der gemäß der aktuellen und gültigen Vereinbarung erlaubte Sollwertebereich
            angegeben.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <strong>Tabellarischer Formularbereich mit Eingabefunktionen (Angaben je Spalte): </strong>
    </p>
    <ul>
      <li>
        SL-Wert
        <ul>
          <li>
            Dieses Feld unterscheidet nach drei Szenarien:
            <ol>
              <li>
                Ist mit dem automatischen oder bereits übermittelten Wert gefüllt. Dieser liegt dann
                außerhalb des Sollwertebereiches. Eine Eingabe ist nicht möglich.
              </li>
              <li>
                Ist durch Sie einzugeben, wenn ein Fehler bei der automatischen Service
                Level-Ermittlung aufgetreten ist. Dieses Szenario ist an dem Warnhinweis-Icon zu
                erkennen. Verharren Sie mit dem Mauszeiger über diesem Icon erhalten Sie weitere
                Informationen.
              </li>
              <li>
                Handelt es sich um einen manuell zu berichtende Service Level kann dieser hier
                eingeben werden.
              </li>
            </ol>
          </li>
          <li>
            Für die Szenarien 2 und 3 ist die Eingabe eines Wertes verpflichtend. Beachten Sie bitte
            die allgemeinen Hinweise zur Eingabe von Service Level Werten - weiter unten.
          </li>
          <li>
            Mögliche Konsequenz einer Eingabe: Liegt der eingegebene Zahlenwert außerhalb des
            Sollbereiches erscheint das Auswahlfeld <strong><i>Begründungkategorie.</i></strong>
          </li>
        </ul>
      </li>
      <li>
        Begründungskategorie
        <ul>
          <li>
            Die Angabe einer Begründungskategorie ist immer dann notwendig wenn, der zugehörige Wert
            im Feld <strong>SL-Wert</strong> außerhalb des Sollwertebereiches liegt.
          </li>
          <li>
            Folgende Kategorien stehen zur Verfügung
            <ul>
              <li>
                Fehlerhafte Rohdaten/Messwerte:
                <ul>
                  <li>
                    Bitte auswählen, wenn Performance Daten oder Log-Dateien, die zur Berechnung der
                    Service Level herangezogen wurden, fehlerhaft waren bzw. sind.
                  </li>
                  <li>
                    Konsequenz der Auswahl: Sie erhalten die Möglichkeit zur Neubewertung dieses
                    Service Levels.
                  </li>
                </ul>
              </li>
              <li>
                Fehlerhafte SL-Berechnung:
                <ul>
                  <li>Bitte auswählen, wenn die Service Level-Berechnung selbst fehlerhaft ist.</li>
                  <li>
                    Konsequenz der Auswahl: Sie erhalten die Möglichkeit zur Neubewertung dieses
                    Service Levels.
                  </li>
                </ul>
              </li>
              <li>
                Systematischer Fehler:
                <ul>
                  <li>
                    Bitte auswählen, wenn die Ursache auf falsche, unvollständige oder falsch
                    interpretierte Vorgaben oder Verfahrensbeschreibungen zurückzuführen sind.
                  </li>
                  <li>
                    Konsequenz der Auswahl: Für die jeweiligen Datensätze werden Sie gebeten eine
                    Begründung sowie durchgeführten und geplanten Maßnahmen anzugeben.
                  </li>
                </ul>
              </li>
              <li>
                Technischer Fehler:
                <ul>
                  <li>
                    Bitte auswählen, wenn technische Hilfsmittel oder involvierte Systeme nicht
                    korrekt gearbeitet haben.
                  </li>
                  <li>
                    Konsequenz der Auswahl: Für die jeweiligen Datensätze werden Sie gebeten eine
                    Begründung sowie durchgeführten und geplanten Maßnahmen anzugeben.
                  </li>
                </ul>
              </li>
              <li>
                Menschlicher Fehler:
                <ul>
                  <li>Bitte auswählen, wenn keine der anderen Kategorien passend erscheint.</li>
                  <li>
                    Konsequenz der Auswahl: Für die jeweiligen Datensätze werden Sie gebeten eine
                    Begründung sowie durchgeführten und geplanten Maßnahmen anzugeben.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Neubewertung
        <ul>
          <li>
            Die Angabe einer Neubewertung wird immer dann von Ihnen erwartet, wenn im Feld
            <strong><i>Begründungskategorie</i></strong> der Wert „<i
              >Fehlerhafte Rohdaten/Messwerte</i
            >“ oder „<i>Fehlerhafte Service Level-Berechnung</i>“ ausgewählt wurde.
          </li>
          <li>
            Beachten Sie bitte die allgemeinen Hinweise zur Eingabe von Service Level Werten -
            weiter unten.
          </li>
        </ul>
      </li>
      <li>
        Begründung
        <ul>
          <li>
            Die Angabe einer Begründung ist immer dann notwendig,
            <ul>
              <li>
                wenn der zugehörige Service Level Wert oder dessen Neubewertung außerhalb des
                Sollwertebereiches liegt.
              </li>
              <li>
                wenn der zugehörige Service Level Wert oder dessen Neubewertung mit „<i>NV</i>“ bzw.
                „<i>NB</i>“ gemeldet wurde.
              </li>
            </ul>
          </li>
          <li>
            Bitte beschreiben Sie die konkrete Ursache des Service Level Verstoßes und
            berücksichtigen Sie dabei die zuvor im Prozess ausgewählte Begründungskategorie.
          </li>
        </ul>
      </li>
      <li>
        Geplante Maßnahmen
        <ul>
          <li>
            Die Angabe einer geplanten Maßnahme ist immer dann notwendig, wenn der zugehörige
            Service Level Wert oder dessen Neubewertung außerhalb des Sollwertebereiches liegt.
          </li>
          <li>
            Bitte beschreiben Sie die geplanten Maßnahmen, die zukünftig das Wiedereintrittsrisiko
            der Ursache mitigieren/verhindern sollen. Bitte benennen Sie den konkreten
            Umsetzungstermin oder ab welcher Berichtsperiode die Maßnahme wirksam sein wird. Ist
            durch eine bereits durchgeführte Maßnahme die Beschreibung einer "geplanten Maßnahme"
            obsolet geworden, geben Sie hier bitte den entsprechenden Hinweis an.
          </li>
        </ul>
      </li>
      <li>
        Durchgeführte Maßnahmen:
        <ul>
          <li>
            Die Angabe einer durchgeführten Maßnahme ist immer dann notwendig, wenn der zugehörige
            Service Level Wert oder dessen Neubewertung außerhalb des Sollwertebereiches liegt.
          </li>
          <li>
            Bitte beschreiben Sie die bereits durchgeführten Maßnahmen, die zukünftig das
            Wiedereintrittsrisiko der Ursache mitigieren/verhindern sollen. Bitte benennen Sie den
            konkreten Umsetzungstermin oder seid welcher Berichtsperiode die Maßnahme wirksam ist.
            Sind noch keine Maßnahmen durchgeführt worden, geben Sie bitte an dieser Stelle eine
            kurze Begründung an.
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div *ngIf="activity === 'ut_formal_material_check'">
    <p>
      Sie befinden sich im Prozessdialog <strong><i>Formelle und materielle Prüfung.</i></strong>
    </p>
    <p>&nbsp;</p>
    <p>
      Hier können Sie als GTI SDM die durch den TI-ITSM-Teilnehmer bzw. des TI SDM gemeldeten
      Service Level (SL) Daten formell und materiell prüfen. Das bedeutet, dass dieser
      Prozessschritt dem GTI SDMs ermöglicht die Annahme von qualitativ oder auch inhaltlich
      unvollständigen Service Level-Meldungen insgesamt oder einzeln zu akzeptieren bzw. abzulehnen.
    </p>
    <p>&nbsp;</p>
    <p>
      Der Prozessdialog ist so aufgebaut, dass im Standard alle gemeldeten Service Level-Daten im
      Feld <strong><i>Akzeptanzkategorie</i></strong> auf „<i>Akzeptiert</i>“. Sollen alle oder
      einzelne Service Level-Daten eines Vorgangs nicht akzeptiert und damit dem TI-ITSM-Teilnehmer
      zur Überarbeitung zurückgesendet werden, können Sie das über die Anpassung des Feldes anzeigen
      und ein <strong><i>Ablehnungshinweis</i></strong> für den TI-ITSM-Teilnehmer hinterlegen.
    </p>
    <p>&nbsp;</p>
    <p>
      Wurden alle SL-Datensätze des Vorgangs akzeptiert, wandert die Vorgangsbearbeitung in den
      nächsten Prozessschritt zur <strong>Inhaltlichen Prüfung</strong>. Wurden eine oder mehrere
      Service Level-Datensätze nicht akzeptiert so wird die Vorgangsbearbeitung erneut in den Dialog
      <strong>Service Level melden</strong> übertragen.
    </p>
    <p>&nbsp;</p>
    <p><strong>Tabellarischer – informativer – Formularbereich (Angaben je Spalte): </strong></p>
    <ul>
      <li>
        Service Level
        <ul>
          <li>
            Enthält die Kennzahl Identifikation und den Namen des Service Level - abgleitet aus den
            Vorgaben der gematik GmbH.
          </li>
          <li>
            Handelt es sich um einen technischen Service Level werden hier weitere Angaben zur
            logischen Produktinstanz aufgeführt.
          </li>
        </ul>
      </li>
      <li>
        Sollwertebereich
        <ul>
          <li>
            Hier ist der gemäß der aktuellen und gültigen Vereinbarung erlaubte Sollwertebereich
            angegeben.
          </li>
        </ul>
      </li>
      <li>
        SL-Wert
        <ul>
          <li>
            In diesem Feld wird der initiale – ermittelte bzw. gemeldete – Service Level Wert
            angezeigt.
          </li>
          <li>
            Der Wert kann mit der Angabe „<i>FB</i>“ (Fehler SL-Berechnung) oder „<i>FM</i>“
            (Fehlerhafte Rohdaten/Messwerte) ergänzt sein. In diesem Fall wurde durch den
            TI-ITSM-Teilnehmer eine Neubewertung vorgenommen – siehe nächstes Feld.
          </li>
          <li>
            Beachten Sie bitte die allgemeinen Hinweise zur Eingabe von Service Level Werten -
            weiter unten.
          </li>
        </ul>
      </li>
      <li>
        Neubewertung
        <ul>
          <li>
            Wurde im bisherigen Prozessverlauf eine Neubewertung des Service Level erforderlich, ist
            diese Neubewertung hier einzusehen.
          </li>
          <li>
            Beachten Sie bitte die allgemeinen Hinweise zur Eingabe von Service Level Werten -
            weiter unten.
          </li>
        </ul>
      </li>
      <li>
        Begründung
        <ul>
          <li>
            Die Angabe einer Begründung ist immer dann vorhanden, wenn der zugehörige Service Level
            Wert oder dessen Neubewertung außerhalb des Sollwertebereiches liegt.
          </li>
          <li>
            Die Angabe einer Begründung ist immer dann vorhanden, wenn der zugehörige Service Level
            Wert oder dessen Neubewertung mit „<i>NV</i>“ bzw. „<i>NB</i>“ gemeldet wurde.
          </li>
        </ul>
      </li>
      <li>
        Geplante Maßnahmen
        <ul>
          <li>
            Die Angabe einer geplanten Maßnahme ist immer dann vorhanden, wenn der zugehörige
            Service Level Wert oder dessen Neubewertung außerhalb des Sollwertebereiches liegt.
          </li>
        </ul>
      </li>
      <li>
        Durchgeführte Maßnahmen:
        <ul>
          <li>
            Die Angabe einer durchgeführten Maßnahme ist immer dann vorhanden, wenn der zugehörige
            Service Level Wert oder dessen Neubewertung außerhalb des Sollwertebereiches liegt.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <strong>Tabellarischer Formularbereich mit Eingabefunktionen (Angaben je Spalte):</strong>
    </p>
    <ul>
      <li>
        Akzeptanzkategorie:
        <ul>
          <li>
            Wählen Sie eine Option aus den 5 verfügbaren Akzeptanzkategorien. Im Folgenden finden
            Sie die Beschreibungen zu den Optionen sowie die zugehörigen Verfahrensanweisungen und
            Kriterien.
            <p>
              <strong><u>Verfahrensanweisung:</u></strong> Die Option "Datensatz akzeptieren" ist
              nur dann erlaubt, wenn:
            </p>
            <ul>
              <li>die Bedingungen für keine der anderen Optionen erfüllt sind und</li>
              <li>
                der Datensatz nicht zur erneuten Bearbeitung an den TI-ITSM-Teilnehmer übergeben
                werden soll.
              </li>
              <li>Diese Auswahl ist für alle Datensätze dieses Prozessdialoges voreingestellt.</li>
              <li>Die Auswahl dieser Option blendet das Eingabefeld Ablehnungshinweis aus.</li>
            </ul>
            <p>
              <strong><u>Verfahrensanweisung:</u></strong> Die Option "Datensatz formell abgelehnt"
              ist zu wählen, wenn in den Anreicherungen des TI-ITSM-Teilnehmers eines bzw. mehrere
              der folgenden Kriterien zutreffen:
            </p>
            <ul>
              <li>Nicht vereinbarte Fremdsprachen wurden verwendet.</li>
              <li>Syntaktisch unverständliche Texte wurden verwendet.</li>
              <li>Semantisch unverständliche Texte wurden verwendet.</li>
              <li>Unverständliche Zeichen oder Wortfolgen wurden verwendet.</li>
              <li>
                Verweise auf Ticket-Nummern wurden ohne den Kontext einer sinngebenden Beschreibung
                verwendet.
              </li>
              <li>
                Der Inhalt des Feldes <strong><i>Begründung</i></strong> in den Details zum
                Datensatz enthält keine tatsächliche Begründung.
              </li>
              <li>
                Die Inhalte der Felder <strong><i>Durchgeführte Maßnahme</i></strong> und
                <strong><i>Geplante Maßnahme</i></strong> in den Details zum Datensatz sind textlich
                oder semantisch identisch.
              </li>
              <li>
                Die Auswahl dieser Option blendet das Eingabefeld
                <strong><i>Ablehnungshinweis</i></strong> ein.
              </li>
            </ul>

            <p>
              <strong><u>Verfahrensanweisung:</u></strong> Die Option "Datensatz materiell
              abgelehnt" ist zu wählen, wenn in den Anreicherungen des TI-ITSM-Teilnehmers ein bzw.
              mehrere Kriterien zutreffen:
            </p>
            <ul>
              <li>
                Die Angaben sind widersprüchlich, also z.B.
                <ul>
                  <li>
                    Die "<i>geplante Maßnahme</i>" wird durch die "<i>durchgeführte Maßnahme</i>"
                    überflüssig und der entsprechende Hinweis fehlt.
                  </li>
                  <li>
                    Die "<i>durchgeführte Maßnahme</i>" wird Zugunsten einer "<i
                      >geplanten Maßnahme</i
                    >" nicht durchgeführt und der entsprechende Hinweis fehlt.
                  </li>
                </ul>
              </li>
              <li>Die Angaben sind nicht nachvollziehbar.</li>
              <li>Die Angaben werden bestritten.</li>
              <li>
                Die Auswahl dieser Option blendet das Eingabefeld
                <strong><i>Ablehnungshinweis</i></strong> ein.
              </li>
            </ul>
            <p>
              <strong><u>Verfahrensanweisung:</u></strong> Die Option "Datensatz formell u.
              materiell abgelehnt" ist zu wählen, wenn in den Anreicherungen des
              TI-ITSM-Teilnehmers:
            </p>
            <ul>
              <li>Die Angaben sowohl formell als auch materielle abgelehnt werden.</li>
              <li>
                Die Auswahl dieser Option blendet das Eingabefeld
                <strong><i>Ablehnungshinweis</i></strong> ein.
              </li>
            </ul>
            <p>
              <strong><u>Verfahrensanweisung:</u></strong> Die Option "Korrekturwunsch
              TI-ITSM-Teilnehmer" ist zu wählen, wenn:
            </p>
            <ul>
              <li>
                der zuständigen SDM der gematik GmbH den Korrekturwunsch des TI-ITSM-Teilnehmers
                fristgerecht erhalten hat.
              </li>
              <li>
                Die Auswahl dieser Option blendet das Eingabefeld
                <strong><i>Ablehnungshinweis</i></strong> ein.
              </li>
              <li>
                Nutzen Sie ggf. weitere Kommunikationskanäle (Telefon, persönliches Gespräch, etc.),
                um sich vor der abschließenden Entscheidung über das Vorliegen von Ablehnungsgründen
                zu informieren.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Ablehnungshinweis
        <ul>
          <li>
            Dieses Eingabefeld ist für Hinweise an den TI-ITSM-Teilnehmer bestimmt. Es soll kurze
            und substantiierte Hinweise enthalten, die dem TI-ITSM-Teilnehmer die Korrektur seiner
            erneut zu machenden Angaben erleichtern soll.
          </li>
          <li>Es sind mindestens 40 und max. 300 Zeichen für den jeweiligen Hinweis anzugeben.</li>
          <li>
            Das Eingabefeld steht nur zur Verfügung, wenn eine andere Option als "<i
              >Datensatz akzeptieren</i
            >" gewählt wurde.
          </li>
          <li>
            Bereits erstellte Ablehnungshinweise werden verworfen, wenn die Option "<i
              >Datensatz akzeptieren</i
            >" gewählt wird.
            <p>
              <strong><u> Verfahrensanweisung:</u></strong>
            </p>
            <ul>
              <li>
                Bei „<i>Formell und/oder materiell abgelehnt</i>“: Bitte beziehen Sie konkrete und
                substantiierte Hinweise auf jede Fundstelle für eine Ablehnung, also z.B.:
                <ul>
                  <li>
                    "Geplante Maßnahmen": ... dolor sit amet, conset ... -> unverständliche
                    Fremdsprache
                  </li>
                  <li>Neubewertung: 500ms -> zu hoch, 300ms erscheinen angemessen</li>
                  <li>Neubewertung: 100% -> nicht nachvollziehbar, max. 80% akzeptabel</li>
                </ul>
              </li>
              <li>
                Bei „<i>Korrekturwunsch TI-Teilnehmer</i>“: Bitte verweisen Sie auf ein Gespräch
                oder eine eMail inkl. Namen der an der Absprache beteiligten Personen und einen
                Zeitstempel, also z.B.:
                <ul>
                  <li>
                    Telefonat: 30.07.2020 10:30 Uhr: Maria Mustermann (gematik GmbH) und Max Meier
                    (Firma ABC).
                  </li>
                  <li>eMail vom: 29.02.2020 17:30:30 Uhr: maria.mustermann&#64;gematik.de</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div *ngIf="activity === 'ut_content_check'">
    <p>
      Sie befinden sich im Prozessdialog <strong><i>Inhaltliche Prüfung.</i></strong>
    </p>
    <p>&nbsp;</p>
    <p>
      Hier können Sie als GTI SDM die durch den TI-ITSM-Teilnehmer gemeldeten Service Level (SL)
      Daten inhaltlich prüfen. In diesem Prozessdialog kann der GTI SDM inhaltlichen Einfluss auf
      die vom TI-ITSM-Teilnehmer gemeldeten und angenommen Service Level Werte nehmen.
    </p>
    <p>&nbsp;</p>
    <p>
      Der Prozessdialog ist so aufgebaut, dass im Standard alle gemeldeten Service Level-Daten im
      Feld <strong><i>Entscheidung GTI SDM</i></strong> mit „<i>Akzeptiert</i>“ vorbelegt ist. Bei
      verletzt gemeldeten Service Level kann der GTI SDM eine Kulanz einleiten. Hat der GTI SDM den
      Bedarf einen neuen Service Level Wert zu setzen, ist dies ebenfalls hier je Service Level
      möglich. Eine von der Akzeptanz abweichende Entscheidung muss durch den GTI SDM immer
      dokumentiert werden. Dafür stellt der Dialog entsprechende Felder zur Verfügung.
    </p>
    <p>&nbsp;</p>
    <p>
      Wurden vom GTI SDM alle Service Level akzeptiert und/oder mit dem Merkmal „<i>Kulanz</i>“
      versehen, erhalten alle Personen der dem Prozess zugeordneten Rollen (TI SDM und GTI SDM),
      nach dem Senden der Daten eine automatisch vom System erstellte Benachrichtigung per E-Mail
      und der
      <strong>Vorgang ist beendet</strong>. Wurde eine oder mehrere Service Level inhaltlich vom GTI
      SDM verändert, so wird die Vorgangsbearbeitung zur Prüfung dieser Änderungen in den
      Prozessschritt <strong><i>Bearbeitungsergebnisse überprüfen</i></strong> an den
      TI-ITSM-Teilnehmer (TI SDM) übergeben.
    </p>
    <p>&nbsp;</p>
    <p><strong>Tabellarischer – informativer – Formularbereich (Angaben je Spalte):</strong></p>
    <ul>
      <li>
        Service Level
        <ul>
          <li>
            Enthält die Kennzahl Identifikation und den Namen des Service Level - abgleitet aus den
            Vorgaben der gematik GmbH.
          </li>
          <li>
            Handelt es sich um einen technischen Service Level werden hier weitere Angaben zur
            logischen Produktinstanz aufgeführt.
          </li>
        </ul>
      </li>
      <li>
        Sollwertebereich
        <ul>
          <li>
            Hier ist der gemäß der aktuellen und gültigen Vereinbarung erlaubte Sollwertebereich
            angegeben.
          </li>
        </ul>
      </li>
      <li>
        SL-Wert
        <ul>
          <li>
            In diesem Feld wird der initiale – ermittelte bzw. gemeldete – Service Level Wert
            angezeigt.
          </li>
          <li>
            Der Wert kann mit der Angabe „<i>FB</i>“ (Fehler SL-Berechnung) oder „<i>FM</i>“
            (Fehlerhafte Rohdaten/Messwerte) ergänzt sein. In diesem Fall wurde durch den
            TI-ITSM-Teilnehmer eine Neubewertung vorgenommen – siehe nächstes Feld.
          </li>
          <li>
            Beachten Sie bitte die allgemeinen Hinweise zur Eingabe von Service Level Werten -
            weiter unten.
          </li>
        </ul>
      </li>
      <li>
        Neubewertung TI SDM
        <ul>
          <li>
            Wurde im bisherigen Prozessverlauf eine Neubewertung des Service Level erforderlich, ist
            diese Neubewertung hier einzusehen.
          </li>
          <li>
            Beachten Sie bitte die allgemeinen Hinweise zur Eingabe von Service Level Werten -
            weiter unten.
          </li>
        </ul>
      </li>
      <li>
        Begründung
        <ul>
          <li>
            Die Angabe einer Begründung ist immer dann vorhanden, wenn der zugehörige Service Level
            Wert oder dessen Neubewertung außerhalb des Sollwertebereiches liegt.
          </li>
          <li>
            Die Angabe einer Begründung ist immer dann vorhanden, wenn der zugehörige Service Level
            Wert oder dessen Neubewertung mit „<i>NV</i>“ bzw. „<i>NB</i>“ gemeldet wurde.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <strong>Tabellarischer Formularbereich mit Eingabefunktionen (Angaben je Spalte): </strong>
    </p>
    <ul>
      <li>
        Entscheidung GTI SDM
        <ul>
          <li>Das Auswahlfeld in dieser Spalte ist mit dem Wert "<i>Akzeptiert</i>" vorbelegt.</li>
          <li>
            <strong><u>Verfahrensanweisung:</u></strong> Wählen Sie pro Datensatz je eine der
            folgenden Optionen:
            <ul>
              <li>
                Akzeptiert:
                <ul>
                  <li>
                    Die Angaben zum nicht erfüllte oder auch erfüllte Service Level werden
                    akzeptiert. Ein Pönale bei nicht erfüllten Service Level für den
                    TI-ITSM-Teilnehmer kann die Konsequenz sein.
                  </li>
                  <li>Die Prozessverarbeitung für diesen Service Level ist damit abgeschlossen.</li>
                </ul>
              </li>
              <li>
                Abgelehnt und Neubewertung:
                <ul>
                  <li>
                    Wählen Sie diese Option, wenn eines der folgenden Kriterien zutrifft:
                    <ul>
                      <li>
                        Der vom TI -ITSM-Teilnehmer vorliegende Wert für den Service Level wird
                        bestritten.
                      </li>
                      <li>
                        Die vom TI-ITSM-Teilnehmer vorliegende Neubewertung für den Service Level
                        wird bestritten.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Das Pflichtfeld <strong><i>Neubewertung GTI SDM</i></strong> wird eingeblendet.
                  </li>
                  <li>
                    Das Pflichtfeld <strong><i>Hinweis für den TI SDM</i></strong> wird
                    eingeblendet.
                  </li>
                </ul>
              </li>
              <li>
                Kulanz:
                <ul>
                  <li>
                    Die Entscheidungsmöglichkeit steht nur bei Service Leveln zur Verfügung die
                    außerhalb des Sollwertebereiches liegen.
                  </li>
                  <li>
                    Nutzen Sie die „<i>Kulanz</i>“, wenn:
                    <ul>
                      <li>Sie für den entsprechenden Service Level Kulanz gewähren wollen und</li>
                      <li>
                        es einen objektiven Grund für die Kulanz gibt, welcher im Feld
                        <strong><i>Hinweis für den TI SDM</i></strong> beschrieben wird.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Das Pflichtfeld <strong><i>Hinweis für den TI SDM</i></strong> wird
                    eingeblendet.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Neubewertung GTI SDM
        <ul>
          <li>
            Die Angabe einer Neubewertung wird immer dann von Ihnen erwartet, wenn die Entscheidung
            Abgelehnt und Neubewertung für diesen Service Level ausgewählt wurde. wurde.
          </li>
          <li>
            Beachten Sie bitte die allgemeinen Hinweise zur Eingabe von Service Level Werten -
            weiter unten.
          </li>
        </ul>
      </li>
      <li>
        Hinweis für TI SDM
        <ul>
          <li>
            Bitte nutzen Sie dieses Feld, um einen substantiierten Hinweis für die Kulanz oder die
            Neubewertung an den TI-ITSM-Teilnehmer zu übermitteln.
          </li>
          <li>
            <strong><u>Verfahrensanweisung:</u></strong> Der Hinweis sollte folgende Informationen
            enthalten:
            <ul>
              <li>Den Zeitpunkt einer Vereinbarung.</li>
              <li>Die Namen der beteiligten Personen.</li>
              <li>Einen objektiven Grund für die Kulanz bzw. die Neubewertung.</li>
              <li>Ggf. zusätzliche Bedingungen, für die Gewährung der Kulanz.</li>
              <li>
                Beispiele:
                <ul>
                  <li>
                    Telefonat vom 10.11.20 - 11:30 Uhr MEZ, mit Erika Mustermann (Firma XYZ):
                    Einmalig wegen Fremdverschulden gewährt
                  </li>
                  <li>
                    Telefonat vom 02.01.20 - 14:00 Uhr MEZ, mit Max Mustermann (Firma XYZ): Manuelle
                    Angabe deutlich überhöht. 90% erscheinen realistisch
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Interne Notiz
        <ul>
          <li>
            Dieses Feld ist als Hinweis für die interne Auswertung der Prozessdokumentation im
            "Customer Relationship Management"-Tool (CRM) bestimmt.
          </li>
          <li>
            Verfahrensanweisung: Der Hinweis ist optional und kann bspw. folgende Informationen
            beinhalten:
            <ul>
              <li>
                Verweise auf ausgleichende Kulanz, die die gematik GmbH von der Gegenseite erhalten
                hat.
              </li>
              <li>Gründe, warum Kulanz explizit nicht gewährt wurde.</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div *ngIf="activity === 'ut_result_check'">
    <p>
      Sie befinden sich im Prozessdialog <strong><i>Bearbeitungsergebnisse überprüfen.</i></strong>
    </p>
    <p>&nbsp;</p>
    <p>
      Hier können Sie als TI SDM die durch den GTI SDM abgelehnt und neubewerteten Service Level
      überprüfen. Für Service Level, die vom GTI SDM unverändert akzeptiert oder Kulanz gewährt
      wurde, ist die Prozessbearbeitung bereits abgeschlossen.
    </p>
    <p>&nbsp;</p>
    <p>
      Der Prozessdialog ist so aufgebaut, dass im Standard alle zu prüfenden Datensätze im Feld
      <strong><i>Stellungnahme</i></strong> mit „<i>Akzeptiert</i>“ vorbelegt sind. Besteht Bedarf
      ein Widerspruch zu der vom GTI SDM vorgenommen Änderung einzuleiten, können Sie dies durch
      Kennzeichnung im gleichen Feld auswählen. Ein Widerspruch muss durch den TI SDM immer
      begründet werden. Dafür stellt der Dialog entsprechendes Kommentierungsfeld zur Verfügung.
    </p>
    <p>&nbsp;</p>
    <p>
      Wurden von Ihnen als TI SDM alle Service Level akzeptiert, erhalten alle Personen der dem
      Prozess zugeordneten Rollen (TI SDM und GTI SDM), nach dem Senden der Daten eine automatisch
      vom System erstellte Benachrichtigung per E-Mail und der <b>Vorgang ist beendet</b>. Wurde von
      Ihnen für eine oder mehrere Service Level ein Widerspruch ausgelöst, so werden diese zur
      finalen Prüfung an den GTI SDM in den Prozessschritt <b>Widersprüche bewerten</b> übergeben.
    </p>
    <p>&nbsp;</p>
    <p><strong>Tabellarischer – informativer – Formularbereich (Angaben je Spalte): </strong></p>
    <ul>
      <li>
        Service Level
        <ul>
          <li>
            Enthält die Kennzahl Identifikation und den Namen des Service Level - abgleitet aus den
            Vorgaben der gematik GmbH.
          </li>
          <li>
            Handelt es sich um einen technischen Service Level werden hier weitere Angaben zur
            logischen Produktinstanz aufgeführt.
          </li>
        </ul>
      </li>
      <li>
        Sollwertebereich
        <ul>
          <li>
            Hier ist der gemäß der aktuellen und gültigen Vereinbarung erlaubte Sollwertebereich
            angegeben.
          </li>
        </ul>
      </li>
      <li>
        SL-Wert
        <ul>
          <li>
            In diesem Feld wird der initiale – ermittelte bzw. gemeldete – Service Level Wert
            angezeigt.
          </li>
          <li>
            Der Wert kann mit der Angabe „<i>FB</i>“ (Fehler SL-Berechnung) oder „<i>FM</i>“
            (Fehlerhafte Rohdaten/Messwerte) ergänzt sein.
          </li>
          <li>
            Wenn Sie mit dem Mauszeiger über dem SL-Wert verharren, erhalten Sie alle im bisherigen
            Prozessverlauf ermittelten Werte:
            <ul>
              <li>Automatisch ermittelt</li>
              <li>Manuell ermittelt</li>
              <li>Neubewertung TI SDM</li>
              <li>Neubewertung GTI SDM</li>
            </ul>
          </li>
          <li>
            Beachten Sie bitte die allgemeinen Hinweise zur Eingabe von Service Level Werten -
            weiter unten.
          </li>
        </ul>
      </li>
      <li>
        Entscheidung GTI SDM
        <ul>
          <li>Hier wird die Entscheidung des GTI SDM für den Service-Level angezeigt.</li>
        </ul>
      </li>
      <li>
        Neubewertung GTI SDM
        <ul>
          <li>Stellt die vom GTI SDM durchgeführte Neubewertung des Service Level Wertes dar.</li>
        </ul>
      </li>
      <li>
        Hinweis für TI SDM
        <ul>
          <li>In diesem Feld teilt der GTI Ihnen den Grund für eine Neubewertung mit.</li>
        </ul>
      </li>
    </ul>
    <p>
      <strong>Tabellarischer Formularbereich mit Eingabefunktionen (Angaben je Spalte):</strong>
    </p>
    <ul>
      <li>
        Stellungnahme
        <ul>
          <li>Das Auswahlfeld in dieser Spalte ist mit dem Wert "<i>Akzeptiert</i>" vorbelegt.</li>
          <li>
            Folgende Optionen stehen Ihnen bei der Stellungnahme zur Verfügung:
            <ul>
              <li>
                Akzeptiert:
                <ul>
                  <li>Sie akzeptieren die durch den GTI SDM vorgenommen Änderungen.</li>
                  <li>Die Prozessverarbeitung für diesen Service Level ist damit abgeschlossen.</li>
                </ul>
              </li>
              <li>
                Widerspruch:
                <ul>
                  <li>Sie legen zu der vom GTI SDM vorgenommen Änderung einen Widerspruch ein.</li>
                  <li>
                    Durch diese Auswahl wird das Pflichtfeld
                    <strong><i>Widerspruchsbegründung</i></strong> eingeblendet.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Widerspruchsbegründung
        <ul>
          <li>
            Dieses Pflichtfeld steht für die substantiierte Begründung eines Widerspruches zur
            Verfügung. Bitte beachten Sie folgende Hinweise:
            <ul>
              <li>
                Bitte teilen Sie der gematik GmbH objektive und erfolgversprechende Gründe für Ihren
                Widerspruch mit.
              </li>
              <li>
                Vermeiden Sie bitte Referenzen auf Ticket-Nummern ohne diese sinnstiftend zu
                kommentieren.
              </li>
              <li>Dieses Pflichtfeld wird nur zur Begründung eines Widerspruches eingeblendet.</li>
              <li>
                Nutzen Sie ggf. weitere Kommunikationskanäle (Telefon, persönliches Gespräch, etc.),
                um vor der abschließenden Entscheidung des Widerspruchsverfahrens eine Klärung oder
                Einigung herbei zu führen.
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div *ngIf="activity === 'ut_objection_handling'">
    <p>
      Sie befinden sich im Prozessdialog <strong><i>Widersprüche bewerten.</i></strong>
    </p>
    <p>&nbsp;</p>
    <p>
      Hier können Sie als GTI SDM der von Ihnen im Rahmen der inhaltlichen Prüfung allerdings vom TI
      SDM widersprochenen vorgenommen Service Level Neubewertungen einer finalen Bewertung
      unterziehen. Für Service Level die von Ihnen als GTI SDM im Rahmen der inhaltlichen Prüfung
      neubewertet und vom TI SDM akzeptiert wurden, ist die Prozessbearbeitung bereits
      abgeschlossen.
    </p>
    <p>&nbsp;</p>
    <p>
      Der Prozessdialog ist so aufgebaut, dass im Standard alle zu prüfenden Datensätze im
      Auswahlfeld <strong><i>Finale Entscheidung GTI SDM</i></strong> mit „<i
        >Letzte Angabe GTI SDM</i
      >“ vorbelegt sind. Sind die TI SDM vorgebrachte Widerspruchsbegründungen valide, kann aus
      weiteren Optionen gewählt werden und ggf. eine neue <u>finale</u> Neubewertung erfolgen. Die
      Kommentierungsfelder <strong><i>Hinweis für TI SDM</i></strong> und
      <strong><i>Interne Notiz</i></strong> können in diesem Dialog letztmalig aktualisiert werden.
    </p>
    <p>&nbsp;</p>
    <p>
      Nachdem alle vom TI SDM widersprochenen Service Level durch den GTI SDM final entschieden
      wurden, ist die Prozessbearbeitung abgeschlossen. Nach dem Senden der Daten erhalten alle
      Personen der dem Prozess zugeordneten Rollen (TI SDM und GTI SDM), eine automatisch vom System
      erstellte Benachrichtigung per E-Mail und der <strong><i>Vorgang ist beendet.</i></strong>
    </p>
    <p>&nbsp;</p>
    <p><strong>Tabellarischer – informativer – Formularbereich (Angaben je Spalte): </strong></p>
    <ul>
      <li>
        Service Level
        <ul>
          <li>
            Enthält die Kennzahl Identifikation und den Namen des Service Level - abgleitet aus den
            Vorgaben der gematik GmbH.
          </li>
          <li>
            Handelt es sich um einen technischen Service Level werden hier weitere Angaben zur
            logischen Produktinstanz aufgeführt.
          </li>
        </ul>
      </li>
      <li>
        Sollwertebereich
        <ul>
          <li>
            Hier ist der gemäß der aktuellen und gültigen Vereinbarung erlaubte Sollwertebereich
            angegeben.
          </li>
        </ul>
      </li>
      <li>
        SL-Wert
        <ul>
          <li>
            In diesem Feld wird der initiale – ermittelte bzw. gemeldete – Service Level Wert
            angezeigt.
          </li>
          <li>
            Der Wert kann mit der Angabe „<i>FB</i>“ (Fehler SL-Berechnung) oder „<i>FM</i>“
            (Fehlerhafte Rohdaten/Messwerte) ergänzt sein.
          </li>
          <li>
            Wenn Sie mit dem Mauszeiger über dem SL-Wert verharren, erhalten Sie alle im bisherigen
            Prozessverlauf ermittelten Werte:
            <ul>
              <li>Automatisch ermittelt</li>
              <li>Manuell ermittelt</li>
              <li>Neubewertung TI SDM</li>
              <li>Neubewertung GTI SDM</li>
            </ul>
          </li>
          <li>
            Beachten Sie bitte die allgemeinen Hinweise zur Eingabe von Service Level Werten -
            weiter unten.
          </li>
        </ul>
      </li>
      <li>
        Widerspruchsbegründung
        <ul>
          <li>
            Hier wird die Widerspruchsbegründung des TI-ITSM-Teilnehmers angezeigt, um eine
            abschließende Bewertung vornehmen zu können.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <strong>Tabellarischer Formularbereich mit Eingabefunktionen (Angaben je Spalte): </strong>
    </p>
    <ul>
      <li>
        Finale Entscheidung GTI-SDM
        <ul>
          <li>
            Das Auswahlfeld in dieser Spalte ist mit dem Wert "Letzte Angabe GTI SDM" vorbelegt.
          </li>
          <li>
            Folgende Optionen stehen Ihnen bei der Stellungnahme zur Verfügung:
            <ul>
              <li>
                Letzte Angabe GTI SDM
                <ul>
                  <li>
                    <strong><u>Verfahrensanweisung:</u></strong> Bitte wählen Sie diese Option nur
                    aus, wenn die folgenden Bedingungen erfüllt sind:
                    <ul>
                      <li>Die Widerspruchsbegründung ist objektiv und nachweislich falsch.</li>
                      <li>
                        Die letzte Angabe des GTI SDM soll als finaler Service Level Wert verwendet
                        werden.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Das Aktivieren dieser Auswahl führt zu:
                    <ul>
                      <li>
                        Das Feld <strong><i>Finale Festlegung</i></strong> wird mit der letzten
                        Angabe des GTI SDM gefüllt und ist nicht änderbar.
                      </li>
                      <li>
                        Ermöglicht die Aktualisierung der Felder
                        <strong><i>Hinweis für TI SDM</i></strong> und
                        <strong><i>Interne Notiz.</i></strong>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Letzte Angabe TI SDM
                <ul>
                  <li>
                    <strong><u>Verfahrensanweisung:</u></strong> Bitte wählen Sie diese Option nur
                    aus, wenn die folgenden Bedingungen erfüllt sind:
                    <ul>
                      <li>Die Widerspruchsbegründung ist objektiv und nachweislich korrekt.</li>
                      <li>
                        Die letzte Angabe des TI SDM soll als finaler Service Level Wert verwendet
                        werden.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Das Aktivieren dieser Auswahl führt zu:
                    <ul>
                      <li>
                        Das Feld <strong><i>Finale Festlegung</i></strong> wird mit der letzten
                        Angabe des TI SDM gefüllt und ist nicht änderbar.
                      </li>
                      <li>
                        Ermöglicht die Aktualisierung der Felder
                        <strong><i>Hinweis für TI SDM</i></strong> und
                        <strong><i>Interne Notiz.</i></strong>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Finale Festlegung
                <ul>
                  <li>
                    <strong><u>Verfahrensanweisung:</u></strong> Bitte wählen Sie diese Option nur
                    aus, wenn die folgenden Bedingungen erfüllt sind:
                    <ul>
                      <li>
                        Es sind neue Erkenntnisse vorhanden, die eine neue finale Bewertung des
                        Service Level erforderlich machen.
                      </li>
                      <li>
                        Keine der bisherigen Service Level Werte sind inhaltlich korrekt
                        wiedergegeben.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Das Aktivieren dieser Auswahl führt zu:
                    <ul>
                      <li>
                        Das Feld <strong><i>Finale Festlegung</i></strong> wird für eine neue
                        verpflichtende Eingabe aktiviert.
                      </li>
                      <li>
                        Ermöglicht die Aktualisierung der Felder
                        <strong><i>Hinweis für TI SDM</i></strong> und
                        <strong><i>Interne Notiz.</i></strong>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Finale Festlegung
        <ul>
          <li>Dieses Feld zeigt den finalen Service Level Wert an.</li>
          <li>
            Wird im Feld <strong><i>Finale Entscheidung GTI-SDM</i></strong> der Wert „<i
              >Finale Festlegung</i
            >“ ausgewählt, ist durch den Nutzer der final zu verwendende Service Level-Wert
            eingegeben werden.
          </li>
        </ul>
      </li>
      <li>
        Hinweis für TI SDM
        <ul>
          <li>
            Bitte nutzen Sie dieses Feld, um einen substantiierten Hinweis für die Kulanz oder die
            Neubewertung an den TI-ITSM-Teilnehmer zu übermitteln.
          </li>
          <li>
            <strong><u>Verfahrensanweisung:</u></strong> Der Hinweis sollte folgende Informationen
            enthalten:
            <ul>
              <li>Den Zeitpunkt einer Vereinbarung.</li>
              <li>Die Namen der beteiligten Personen.</li>
              <li>Einen objektiven Grund für die Kulanz bzw. die Neubewertung.</li>
              <li>Ggf. zusätzliche Bedingungen, für die Gewährung der Kulanz.</li>
              <li>
                Beispiele:
                <ul>
                  <li>
                    Telefonat vom 10.11.20 - 11:30 Uhr MEZ, mit Erika Mustermann (Firma XYZ):
                    Einmalig wegen Fremdverschulden gewährt
                  </li>
                  <li>
                    Telefonat vom 02.01.20 - 14:00 Uhr MEZ, mit Max Mustermann (Firma XYZ): Manuelle
                    Angabe deutlich überhöht. 90% erscheinen realistisch
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Interne Notiz
        <ul>
          <li>
            Dieses Feld ist als Hinweis für die interne Auswertung der Prozessdokumentation im
            "Customer Relationship Management"-Tool (CRM) bestimmt.
          </li>
          <li>
            Verfahrensanweisung: Der Hinweis ist optional und kann bspw. folgende Informationen
            beinhalten:
            <ul>
              <li>
                Verweise auf ausgleichende Kulanz, die die gematik GmbH von der Gegenseite erhalten
                hat.
              </li>
              <li>Gründe, warum Kulanz explizit nicht gewährt wurde.</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<div id="footer" class="mt-3">
  <p><strong>Allgemeinen Hinweise:</strong></p>
  <ol>
    <li>
      Detailinformationen zu jedem Service Level erhalten sie immer über die Betätigung des
      i-Symbols.
    </li>
    <li>
      Das System erlaubt das Zwischenspeichern von begonnen, evtl. noch nicht vollständig
      ausgefüllten Prozessdialogen. Bei Bedarf kann ein Zwischenstand immer über das Betätigen des
      Buttons „Arbeitsstand Speichern“ erfolgen. Bei späterer Auswahl des Vorgangs wird der Dialog
      immer mit dem zuletzt gespeicherten Stand geöffnet.
    </li>
    <li>
      Eingabe von Service Level Werten:
      <ul>
        <li>
          Das System unterstützt Sie dabei immer nur valide Werte einzugeben. Das Eingabefeld
          erlaubt daher grundsätzlich nur Zahlenwerte im erlaubten Eingabebereich. Der erlaubte
          Eingabebereich kann immer über das jeweilige i-Symbol ermittelt werden.
        </li>
        <li>
          Eingabe von „NB“: Wird der Service Level nach ihrem Verständnis unberechtigt abgefragt,
          geben Sie bitte „NB“ (Nicht berechtigt) in das Formularfeld ein. Wenn die Eingabe von „NB“
          erfolgt werden:
          <ul>
            <li>andere Eingabeoptionen zum Service Level deaktiviert und</li>
            <li>in der jeweiligen Datenzeile getätigte Eingaben verworfen.</li>
          </ul>
        </li>
        <li>
          Eingabe von „NV“: Sind Vorgänge zum Service Level im TI-ITSM nicht aufgetreten oder liegen
          technische Messungen zum Service Level nicht vor, geben Sie bitte „NV“ (Nicht-Verfügbar)
          in das Formularfeld ein. Wenn die Eingabe von „NV“ erfolgt werden:
          <ul>
            <li>andere Eingabeoptionen zum Service Level deaktiviert und</li>
            <li>in der jeweiligen Datenzeile getätigte Eingaben verworfen.</li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      Durch „Klick“ auf ein Texteingabefeld erscheint immer ein Pop-up, über dass Sie den Inhalt
      übersichtlicher einsehen oder auch bearbeiten können.
    </li>
    <li>
      Tastaturverwendung im Formular:
      <ul>
        <li>
          Sie können die Tabulatortaste verwenden, um mit der Tastatur zum nächsten Eingabefeld zu
          gelangen.
        </li>
        <li>Sie können die Leertaste verwenden, um Checkboxen zu aktivieren/deaktivieren.</li>
      </ul>
    </li>
  </ol>
</div>
