import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

// rxjs
import { Observable } from "rxjs";

// models

// services
import { OAuthService } from "angular-oauth2-oidc";
import { TokenHelperService } from "gematik-form-library";

@Injectable()
export class AuthGuard {
  constructor(
    public tokenHelperService: TokenHelperService,
    public router: Router,
    private oauthService: OAuthService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.tokenHelperService.isLoggedIn()) {
      this.oauthService.initLoginFlow(state.url);
      return false;
    }

    return true;
  }
}
