import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "gem-checkbox",
  templateUrl: "./gem-checkbox.component.html",
  styleUrls: ["./gem-checkbox.component.scss"],
})
export class GemCheckboxComponent implements OnInit {
  inputControl: UntypedFormControl;

  @Input() label: string;
  @Input() disabled: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
  }

  @Output() change = new EventEmitter();
  @Output() labelClick = new EventEmitter();

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor() {}

  ngOnInit() {}

  emitChange(event: any) {
    this.change.emit(event);
  }

  onLabelClick(): void {
    this.labelClick.emit();
  }
}
