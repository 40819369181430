import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { AbstractControl, FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ServiceLevelDto } from "../../../services/service-level.service";
import { ValueLabel } from "gematik-form-library/lib/gem-components/value-label";
import { ServiceLevelFrontEndService } from "../service-level-front-end.service";
import { SlrFormat, SlrFormatService } from "../slr-format.service";

@Component({
  selector: "service-level-entry",
  templateUrl: "./service-level-entry.component.html",
  styleUrls: ["./service-level-entry.component.scss"],
  animations: [
    trigger("openClose", [
      state("true", style({ width: "*", opacity: 1 })),
      state("false", style({ width: "0px", opacity: 0 })),
      transition("false <=> true", animate(3000)),
    ]),
  ],
})
export class ServiceLevelEntryComponent implements OnInit {
  @Input() slDto: ServiceLevelDto;
  @Input() slInput: UntypedFormGroup;
  @Input() activity: string;

  constructor(
    private serviceLevelFrontEndService: ServiceLevelFrontEndService,
    private slrFormatService: SlrFormatService,
  ) {}

  ngOnInit(): void {
    if (this.slDto?.szenario === 3 || this.slDto?.szenario === 4) {
      this.slInput.get("sltIstMessWertEingabe").disable();
    }
    this.slInput.get("sltIstMessWertEingabe").setValidators([Validators.required]);
    this.slInput.get("sltVerfehltBegruendungsKategorie").setValidators([Validators.required]);
    this.slInput.get("sltIstMessWertNeubewertung").setValidators([Validators.required]);
    this.slInput
      .get("sltVerfehltBegruendung")
      .setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
    this.slInput
      .get("sltVerfehltMassnahmeGeplant")
      .setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
    this.slInput
      .get("sltVerfehltMassnahmeDurchgefuehrt")
      .setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
  }

  openMeasures() {
    this.serviceLevelFrontEndService.openMeasures(this.slInput, this.activity);
  }

  getReasonOptions(): ValueLabel[] {
    let szenario = this.slDto.szenario;
    if (szenario === 3) {
      return this.serviceLevelFrontEndService.reasonOptions;
    }
    return this.serviceLevelFrontEndService.reducedReasonOptions;
  }

  getAcceptanceOptions(): ValueLabel[] {
    return this.serviceLevelFrontEndService.acceptanceOptions;
  }

  showMeasures() {
    return this.serviceLevelFrontEndService.showMeasures(this.slInput, this.slDto);
  }

  showReason() {
    return this.serviceLevelFrontEndService.showReason(this.slInput, this.slDto);
  }

  showRevaluation() {
    return this.serviceLevelFrontEndService.showRevaluation(this.slInput);
  }

  showReasonCategory() {
    return this.serviceLevelFrontEndService.showReasonCategory(this.slInput, this.slDto);
  }

  getRejectionDecisionToolTip() {
    return this.serviceLevelFrontEndService.getRejectionDecisionToolTip(this.slInput);
  }

  getRejectionRemarksToolTip() {
    return this.serviceLevelFrontEndService.getRejectionRemarksToolTip(this.slInput);
  }

  hasRejection() {
    return this.serviceLevelFrontEndService.hasRejection(this.slInput);
  }

  openDetailsDialog() {
    this.serviceLevelFrontEndService.openDetailsDialog(this.slInput, this.slDto);
  }

  getSetpointRange() {
    return this.slrFormatService.getSetpointRange(this.slDto);
  }

  getSlrFormat(value: any): SlrFormat {
    return this.slrFormatService.getFormat(value, this.slDto.unitId);
  }

  isNumber() {
    return this.slrFormatService.isNumber(this.slDto);
  }

  isDecimal() {
    return this.slrFormatService.isDecimal(this.slDto);
  }

  hasError(control: AbstractControl) {
    return this.serviceLevelFrontEndService.hasError(control);
  }
}
