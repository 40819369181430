<div class="row mt-3">
  <div class="col-sm-12">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>
</div>

<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-12">
      <p [innerHTML]="data.text"></p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <gem-textarea
        [placeholder]="'sltVerfehltBegruendung' | translate"
        [control]="slInput?.get('sltVerfehltBegruendung')"
        [minChars]="40"
        [maxChars]="300"
        [hint]="
          translateService.instant('characters') +
          (slInput?.get('sltVerfehltBegruendung')?.value?.length || 0) +
          ' / 300'
        "
      ></gem-textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <gem-textarea
        *ngIf="slInput?.get('sltVerfehltMassnahmeGeplant')"
        [placeholder]="'sltVerfehltMassnahmeGeplant' | translate"
        [control]="slInput?.get('sltVerfehltMassnahmeGeplant')"
        [minChars]="40"
        [maxChars]="300"
        [hint]="
          translateService.instant('characters') +
          (slInput?.get('sltVerfehltMassnahmeGeplant')?.value?.length || 0) +
          ' / 300'
        "
      ></gem-textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <gem-textarea
        *ngIf="slInput?.get('sltVerfehltMassnahmeDurchgefuehrt')"
        [placeholder]="'sltVerfehltMassnahmeDurchgefuehrt' | translate"
        [control]="slInput?.get('sltVerfehltMassnahmeDurchgefuehrt')"
        [minChars]="40"
        [maxChars]="300"
        [hint]="
          translateService.instant('characters') +
          (slInput?.get('sltVerfehltMassnahmeDurchgefuehrt')?.value?.length || 0) +
          ' / 300'
        "
      ></gem-textarea>
    </div>
  </div>
</div>
<div mat-dialog-actions class="text-right mb-3">
  <button class="btn btn-secondary resetFilter" (click)="cancelAction()">
    {{ "CANCEL" | translate }}
  </button>
  <button class="btn btn-primary" (click)="confirmAction()">Ok</button>
</div>
