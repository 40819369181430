<div class="row sl-entry">
  <div class="col-sm-2">
    <div class="bold">{{ slDto.alias ? slDto.alias : slDto?.sl }}</div>
    <p>{{ slDto.zisName }}</p>
    <div>
      <hr class="ci-seperator" />
      <div class="anzeigename">{{ slDto.ciDisplayName }}</div>
    </div>
  </div>
  <div class="col-sm-1">
    <p>
      {{ getSetpointRange() }}
    </p>
  </div>
  <div class="col-sm-1">
    <div>
      <div class="float-left">
        <!-- {{ slInput.get("sltIstMessWertEingabe").value + " " + getSlrFormat(null).suffix }} -->
        {{ getValueOrDisplayDash(slInput.get("sltIstMessWertEingabe").value) }}
      </div>
      <div
        *ngIf="showRevaluation()"
        [ngbTooltip]="this.getReasonCategoryLabelForValue() | translate"
      >
        &nbsp;({{ this.slInput.get("sltVerfehltBegruendungsKategorie").value }})
      </div>
    </div>
  </div>
  <div class="col-sm-1">
    <div *ngIf="slInput.get('sltIstMessWertNeubewertung').value" class="float-left">
      <!-- {{ slInput.get("sltIstMessWertNeubewertung").value + " " + getSlrFormat(null).suffix }} -->
      {{ getValueOrDisplayDash(slInput.get("sltIstMessWertNeubewertung").value) }}
    </div>
  </div>
  <div class="col-sm-1">
    <div *ngIf="showReason()" class="measures" (click)="openMeasures()">
      {{
        slInput.get("sltVerfehltBegruendung").value &&
        slInput.get("sltVerfehltBegruendung").value.length > 45
          ? (slInput.get("sltVerfehltBegruendung").value | slice : 0 : 45) + "..."
          : slInput.get("sltVerfehltBegruendung").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <div *ngIf="showMeasures()" class="measures" (click)="openMeasures()">
      {{
        slInput.get("sltVerfehltMassnahmeGeplant").value &&
        slInput.get("sltVerfehltMassnahmeGeplant").value.length > 45
          ? (slInput.get("sltVerfehltMassnahmeGeplant").value | slice : 0 : 45) + "..."
          : slInput.get("sltVerfehltMassnahmeGeplant").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <div *ngIf="showMeasures()" class="measures" (click)="openMeasures()">
      {{
        slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value &&
        slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value.length > 45
          ? (slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value | slice : 0 : 45) + "..."
          : slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value
      }}
    </div>
  </div>
  <div class="col-sm-2">
    <gem-select
      [placeholder]="'sl_acceptance'"
      [control]="slInput.get('akzeptanzKategorie')"
      [options]="getAcceptanceOptions()"
    ></gem-select>
  </div>
  <div class="col-sm-1">
    <div
      *ngIf="showRejectionNote()"
      [ngClass]="
        hasError(slInput.get('ablehnungsHinweis')) ? 'measures measures-error' : 'measures'
      "
      (click)="openRejectionNote()"
    >
      {{
        slInput.get("ablehnungsHinweis").value && slInput.get("ablehnungsHinweis").value.length > 45
          ? (slInput.get("ablehnungsHinweis").value | slice : 0 : 45) + "..."
          : slInput.get("ablehnungsHinweis").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <span class="material-icons icons" (click)="openDetailsDialog()">info</span>
  </div>
</div>
<hr />
