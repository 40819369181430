import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ServiceLevelDto } from "../../../services/service-level.service";
import { ValueLabel } from "gematik-form-library/lib/gem-components/value-label";
import { ServiceLevelFrontEndService } from "../service-level-front-end.service";
import { SlrFormat, SlrFormatService } from "../slr-format.service";
import { Subscription } from "rxjs";

@Component({
  selector: "service-level-objection-handling",
  templateUrl: "./service-level-objection-handling.component.html",
  styleUrls: ["./service-level-objection-handling.component.scss"],
})
export class ServiceLevelObjectionHandlingComponent implements OnInit, OnDestroy {
  @Input() slDto: ServiceLevelDto;
  @Input() slInput: UntypedFormGroup;
  @Input() activity: string;

  subscription: Subscription;

  constructor(
    private serviceLevelFrontEndService: ServiceLevelFrontEndService,
    private slrFormatService: SlrFormatService,
  ) {}

  ngOnInit(): void {
    if (!this.slInput.get("gemWiderspruchStatus").value) {
      this.slInput.get("gemWiderspruchStatus").setValue("ACCEPTED_GTI_SDM");
    }

    this.slInput.get("gemWiderspruchStatus").setValidators([Validators.required]);
    this.slInput.get("sltIstMessWertNeubewertungSdmTwo").setValidators([Validators.required]);

    this.subscription = this.slInput
      .get("gemWiderspruchStatus")
      .valueChanges.subscribe((change) => {
        if (change === "REJECTED") {
          this.slInput.get("sltIstMessWertNeubewertungSdmTwo").setValue("");
        }
      });
  }

  showReason() {
    return this.serviceLevelFrontEndService.showReason(this.slInput, this.slDto);
  }

  showReasonCategory() {
    return this.serviceLevelFrontEndService.showReasonCategory(this.slInput, this.slDto);
  }

  getReasonCategoryLabelForValue() {
    return this.serviceLevelFrontEndService.getReasonCategoryLabelForValue(this.slInput);
  }

  showMeasures() {
    return this.serviceLevelFrontEndService.showMeasures(this.slInput, this.slDto);
  }

  openMeasures() {
    this.serviceLevelFrontEndService.openMeasures(this.slInput, this.activity);
  }

  showRevaluation() {
    return this.serviceLevelFrontEndService.showRevaluation(this.slInput);
  }

  openDetailsDialog() {
    this.serviceLevelFrontEndService.openDetailsDialog(this.slInput, this.slDto);
  }

  getAcceptanceOptions(): ValueLabel[] {
    return this.serviceLevelFrontEndService.acceptanceOptions;
  }

  getDecisionOptions() {
    return this.serviceLevelFrontEndService.getDecisionOptions(this.slInput, this.slDto);
  }

  openDecisionNote() {
    this.serviceLevelFrontEndService.openDecisionNote(this.slInput, this.activity);
  }

  openInternalNote() {
    this.serviceLevelFrontEndService.openInternalNote(this.slInput, this.activity);
  }

  openFinalInternalNote() {
    this.serviceLevelFrontEndService.openFinalInternalNote(this.slInput);
  }

  showDecisionNote() {
    return this.serviceLevelFrontEndService.showDecisionNote(this.slInput);
  }

  showSdmRevaluation() {
    return this.serviceLevelFrontEndService.showSdmRevaluation(this.slInput);
  }

  showFinalRevaluation() {
    return this.serviceLevelFrontEndService.showFinalRevaluation(this.slInput, this.slDto);
  }

  isObjectionRejected() {
    return this.slInput.get("objectionState").value === "REJECTED";
  }

  getObjectionOptions() {
    return this.serviceLevelFrontEndService.objectionOptions;
  }

  showObjectionNote() {
    return this.serviceLevelFrontEndService.showObjectionNote(this.slInput);
  }

  openObjectionNote() {
    this.serviceLevelFrontEndService.openObjectionNote(this.slInput, this.activity);
  }

  getDecisionLabelForValue() {
    return this.serviceLevelFrontEndService.getDecisionLabelForValue(this.slInput);
  }

  getValueOrDisplayDash(value: string) {
    return this.serviceLevelFrontEndService.getValueOrDisplayDash(value, this.slDto);
  }

  getEnteredValueOrDisplayDash(value: string) {
    return this.serviceLevelFrontEndService.getEnteredValueOrDisplayDash(value, this.slDto);
  }

  getSetpointRange() {
    return this.slrFormatService.getSetpointRange(this.slDto);
  }

  getSlrFormat(value: any): SlrFormat {
    return this.slrFormatService.getFormat(value, this.slDto.unitId);
  }

  isNumber() {
    return this.slrFormatService.isNumber(this.slDto);
  }

  isDecimal() {
    return this.slrFormatService.isDecimal(this.slDto);
  }

  hasError(control: AbstractControl) {
    return this.serviceLevelFrontEndService.hasError(control);
  }
  getObjectionResponseOptions() {
    return this.serviceLevelFrontEndService.objectionResponseOptions;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
