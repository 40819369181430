import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { EnvService, TokenHelperService } from "gematik-form-library";

export const SECURITY_GROUP_BASIC: string = "G.CLOUD.PIP.Basis";
export const SECURITY_GROUP_ADMIN: string = "G.CLOUD.PIP.UWL_Admin";

export interface EntraIdGroup {
  id: string;
  name: string;
}

@Injectable()
export class EntraIdService {
  constructor(
    private http: HttpClient,
    private env: EnvService,
    private tokenHelperService: TokenHelperService,
  ) {}

  getGroupNamesByUserId(): Observable<EntraIdGroup[]> {
    return this.http
      .get<string[]>(
        `${
          this.env.backendUrl
        }/api/entra-id/groupsByUserId/${this.tokenHelperService.getUsername()}`,
      )
      .pipe(
        map((groups) =>
          groups.map((group) => {
            return {
              id: group,
              name: group,
            };
          }),
        ),
      );
  }
}
