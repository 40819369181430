import { createAction, props } from "@ngrx/store";
import { EntraIdGroup } from "../../services/entra-id.service";

export enum EntraIdGroupActions {
  FETCH_GROUPS = "[Entra ID Groups] Fetch Groups",
  FETCH_GROUPS_SUCCESS = "[Entra ID Groups] Fetch Groups Success",
  FETCH_GROUPS_FAILURE = "[Entra ID Groups] Fetch Groups Failure",
}

export const fetchEntraIdGroups = createAction(EntraIdGroupActions.FETCH_GROUPS);

export const fetchEntraIdGroupsSuccess = createAction(
  EntraIdGroupActions.FETCH_GROUPS_SUCCESS,
  props<{ groups: EntraIdGroup[] }>(),
);

export const fetchEntraIdGroupsFailure = createAction(
  EntraIdGroupActions.FETCH_GROUPS_FAILURE,
  props<{ payload: any }>(),
);
