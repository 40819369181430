import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

declare type TaskType =
  | "SIMPLE"
  | "SIMPLE_INPUT"
  | "FORM"
  | "EXTERNAL"
  | "DEFAULT"
  | "DEFAULT_INPUT"
  | "EXTENDED"
  | "EXTENDED_INPUT";

export class GematikTaskDto {
  id: string;
  name: string;
  taskDefinitionKey: string;
  processName: string;
  assignee: string;
  assigneeName: string;
  completedBy: string;
  role: string;
  partnerType: string;
  partner: string;
  created: Date;
  completed: Date;
  due: Date;
  followUp: Date;
  owner: string;
  priority: number;
  formKey: string;
  buid: string;
  description: string;
  customTaskContentOne: string;
  customTaskContentTwo: string;
  customTaskContentThree: string;
  customTaskContentOneLabel: string;
  customTaskContentTwoLabel: string;
  customTaskContentThreeLabel: string;
  variables: Record<string, string>;
  possibleOutcomes: String[];
  type: TaskType;
  backendUrl: string;
  frontendUrl: string;
  reason: string;
  outcome: string;
  htmlContent: string;
  inputFieldName: string;
  caseLogId: string;
  crmUrl: string;
}

export class GematikProcessDto {
  id: string;
  name: string;
  role: string;
  partnerType: string;
  partner: string;
  description: string;
  currentActvity: string;
  lastUpdate: Date;
  created: Date;
  completed: Date;
  owner: string;
  priority: number;
  buid: string;
  currentActvityName: string;
  customProcessContentOne: string;
  customProcessContentTwo: string;
  customProcessContentThree: string;
  customProcessContentOneLabel: string;
  customProcessContentTwoLabel: string;
  customProcessContentThreeLabel: string;
  assignee: string;
  backendUrl: string;
  processDocumentationUrl: string;
  crmUrl: string;
  caseLogId: string;
  processInstanceId: string;
  openBpmnDiagram: boolean;
}

export class GematikCompleteTaskDto {
  taskId: string;
  outcome: string;
  reason: string;
  variables: Record<string, string>;
}

export class GematikTranslationDto {
  key: string;
  value: string;
  lang: string;
}

export class GematikProcessDiagram {
  bpmn: string;
  currentActivityList: GematikProcessDiagramActivityDto[];
  activityExecutionHistory: string[];
}

export class GematikProcessDiagramActivityDto {
  currentActivity: string;
  incident: boolean;
}

export class Lock {
  entityName: string;
  id: string;
  username: string;
  timeout: number;
  taskId: string;

  constructor() {
    this.entityName = null;
    this.id = "";
    this.username = "";
    this.timeout = 0;
    this.taskId = "";
  }
}

export interface HttpConfig {
  body?: any;
  headers?: HttpHeaders;
  observe?: any;
}

@Injectable({
  providedIn: "root",
})
export class GematikTaskApiService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };
  constructor(private http: HttpClient, private translationService: TranslateService) {}

  completeTask(backendUrl: string, dto: GematikCompleteTaskDto): Observable<HttpConfig> {
    const url = backendUrl + "/api/tasks/completeTask";
    return this.http.post(url, dto, this.config);
  }

  public getAllMyTasks(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl + "/api/tasks/getAllMyTasks?lang=" + this.translationService.currentLang,
      this.config,
    );
  }

  public getAllMyCompletedTasks(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl + "/api/tasks/getAllMyCompletedTasks?lang=" + this.translationService.currentLang,
      this.config,
    );
  }

  public getAllTasks(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl + "/api/tasks/getAllTasks?lang=" + this.translationService.currentLang,
      this.config,
    );
  }

  public getAllCompletedTasks(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl + "/api/tasks/getAllCompletedTasks?lang=" + this.translationService.currentLang,
      this.config,
    );
  }

  public getAllMyActiveProcesses(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl +
        "/api/process/getAllMyActiveProcesses?lang=" +
        this.translationService.currentLang,
      this.config,
    );
  }

  public getAllMyCompletedProcesses(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl +
        "/api/process/getAllMyCompletedProcesses?lang=" +
        this.translationService.currentLang,
      this.config,
    );
  }

  public getAllActiveSubscriberProcesses(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl +
        "/api/process/getAllActiveSubscriberProcesses?lang=" +
        this.translationService.currentLang,
      this.config,
    );
  }

  public getAllCompletedSubscriberProcesses(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl +
        "/api/process/getAllCompletedSubscriberProcesses?lang=" +
        this.translationService.currentLang,
      this.config,
    );
  }

  public getTranslations(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(backendUrl + "/api/process/getTranslations", this.config);
  }

  public getProcessDiagram(backendUrl: string, processInstanceId: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl + "/api/process/getProcessDiagram?processInstanceId=" + processInstanceId,
      this.config,
    );
  }
  public check(backendUrl: string, lock: Lock): Observable<HttpConfig> {
    return this.http.post(backendUrl + "/api/locking/check", lock, this.config);
  }

  public lock(backendUrl: string, lock: Lock): Observable<HttpConfig> {
    return this.http.post(backendUrl + "/api/locking/lock", lock, this.config);
  }

  public release(backendUrl: string, lock: Lock): Observable<HttpConfig> {
    return this.http.post(backendUrl + "/api/locking/release", lock, this.config);
  }

  public getTasksCount(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(
      backendUrl + "/api/tasks/getTasksCount?lang=" + this.translationService.currentLang,
      this.config,
    );
  }

  public getProcessIncidents(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(`${backendUrl}/api/process/getAllIncidents`, this.config);
  }

  public getIncidentStacktrace(backendUrl: string, incidentId: string): Observable<any> {
    return this.http.get(`${backendUrl}/api/process/getIncidentStacktrace/${incidentId}`, {
      responseType: "text",
    });
  }

  public retryFailedJob(
    backendUrl: string,
    incidentId: string,
    retries: number,
  ): Observable<HttpConfig> {
    return this.http.post(
      `${backendUrl}/api/process/retryFailedJob/${incidentId}/${retries}`,
      this.config,
    );
  }

  public getHeartbeat(backendUrl: string): Observable<HttpConfig> {
    return this.http.get(backendUrl + "/public/heartbeat", this.config);
  }
}
