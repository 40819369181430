import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromStore from "../store";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import { EntraIdGroup, SECURITY_GROUP_ADMIN } from "../services/entra-id.service";

@Injectable()
export class AuthzAdminGuard {
  constructor(private store: Store<fromStore.SharedState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkStore().pipe(
      switchMap(() => {
        return this.store.select(fromStore.getGroupsEntities).pipe(
          map((entities: { [key: string]: EntraIdGroup }) => !!entities[SECURITY_GROUP_ADMIN]),
          tap((access) => {
            if (!access) {
              this.router.navigate(["noaccess"]);
            }
          }),
        );
      }),
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getGroupsLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(fromStore.fetchEntraIdGroups());
        }
      }),
      filter((loaded) => !!loaded),
      take(1),
    );
  }
}
