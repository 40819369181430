import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Alert } from "../models/alert.model";
import { TranslateService } from "@ngx-translate/core";
import { TokenHelperService } from "gematik-form-library";
import { NavigationEnd, Router } from "@angular/router";
import { filter, Subscription } from "rxjs";

@Component({
  selector: "gematik-alerts",
  styleUrls: ["gematik-alerts.component.scss"],
  template: `
    <div *ngIf="!noaccess">
      <ngb-alert
        *ngFor="let alert of getAlerts()"
        [type]="getSeverity(alert)"
        [dismissible]="alert.dismissible"
        (closed)="close(alert)"
      >
        <div [innerHtml]="currentLang === 'en' ? alert.messageEn : alert.messageDe"></div>
      </ngb-alert>
    </div>
  `,
})
export class GematikAlertsComponent implements OnInit, OnDestroy {
  @Input() alerts: Alert[];

  noaccess: boolean = true;
  subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private tokenHelperService: TokenHelperService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const sub = this.router.events.subscribe((event) => {
      if (event["routerEvent"] instanceof NavigationEnd) {
        if (!event["routerEvent"].url.includes("noaccess")) {
          this.noaccess = false;
        } else {
          this.noaccess = true;
        }
      }
    });
    this.subscriptions.push(sub);

    // const sub = this.router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe((event: NavigationEnd) => {
    //     if (!event.url.includes("noaccess")) {
    //       this.noaccess = false;
    //     } else {
    //       this.noaccess = true;
    //     }
    //   });
    // this.subscriptions.push(sub);
  }

  getAlerts(): Alert[] {
    if (this.alerts) {
      const dismissedAlerts: number[] = this.getDismissedAlerts();
      const alerts: Alert[] = this.alerts.filter((alert) => {
        if (alert.forAuthenticatedUsers) {
          if (this.tokenHelperService.isLoggedIn()) {
            return true;
          } else {
            return false;
          }
        } else {
        }
        return true;
      });
      dismissedAlerts.forEach((da) => {
        const index = alerts.findIndex((a) => a.id === da);
        if (index !== -1) {
          alerts.splice(index, 1);
        }
      });
      return alerts;
    }
  }

  getIsAuthenticated() {
    return this.tokenHelperService.isLoggedIn();
  }

  getSeverity(alert: Alert): string {
    if (alert.severity === "High") {
      return "danger";
    } else if (alert.severity === "Medium") {
      return "warning";
    } else {
      return "info";
    }
  }

  close(alert: Alert) {
    const dismissedAlerts: number[] = this.getDismissedAlerts();
    dismissedAlerts.push(alert.id);
    localStorage.setItem("dismissedAlerts", JSON.stringify(dismissedAlerts));
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  private getDismissedAlerts(): number[] {
    let dismissedAlerts: number[] = JSON.parse(localStorage.getItem("dismissedAlerts"));
    if (!dismissedAlerts) {
      dismissedAlerts = [];
    }
    return dismissedAlerts;
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
