import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
  GematikTaskDto,
  HeaderInformation,
  ServiceLevelService,
  TidDetails,
} from "../../../services/service-level.service";
import { TranslateService } from "@ngx-translate/core";
import { SlrFormatService } from "../slr-format.service";

@Component({
  selector: "header-content",
  templateUrl: "./header-content.component.html",
  styleUrls: ["./header-content.component.scss"],
})
export class HeaderContentComponent implements OnInit {
  taskGroup: UntypedFormGroup;
  tidGroup: UntypedFormGroup;
  buidControl: UntypedFormControl = new UntypedFormControl();
  reportDateControl: UntypedFormControl = new UntypedFormControl();
  tidDetailsControl: UntypedFormControl = new UntypedFormControl();
  @Input() buid: string;
  @Input() taskId: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceLevelService: ServiceLevelService,
    private translationService: TranslateService,
    private slrFormatService: SlrFormatService,
  ) {}

  ngOnInit(): void {
    this.taskGroup = this.formBuilder.group(new GematikTaskDto());
    this.tidGroup = this.formBuilder.group(new TidDetails());
    this.serviceLevelService.getHeaderInformation(this.buid, this.taskId).subscribe((res) => {
      if (res.body) {
        let hi: HeaderInformation = res.body;
        this.taskGroup = this.formBuilder.group(hi.taskDto);
        this.tidGroup = this.formBuilder.group(hi.tidDetails);
        this.buidControl = new UntypedFormControl(hi.buid);
        this.reportDateControl = new UntypedFormControl(
          this.slrFormatService.formatReportDate(hi.reportDate.toString()),
        );
        this.tidDetailsControl = new UntypedFormControl(
          hi.tidDetails.alias1 +
            " " +
            this.translationService.instant("as") +
            ' "' +
            hi.tidDetails.betriebsTypAlias +
            '"',
        );
        this.taskGroup.disable();
        this.tidGroup.disable();
        this.buidControl.disable();
        this.reportDateControl.disable();
        this.tidDetailsControl.disable();
      }
    });
  }

  loadHeaderInformation() {}
}
