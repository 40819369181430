import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "help-content",
  templateUrl: "./help-content.component.html",
  styleUrls: ["./help-content.component.scss"],
})
export class HelpContentComponent implements OnInit {
  constructor() {}
  @Input() activity: string;
  ngOnInit(): void {}
}
