import { NgModule, OnInit } from "@angular/core";
import { Routes, RouterModule, ActivatedRoute } from "@angular/router";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { OauthRedirectComponent } from "./pages/oauth-redirect/oauth-redirect.component";
import { ServiceLevelReportPageComponent } from "./pages/service-level-report-page/service-level-report-page.component";

const routes: Routes = [
  {
    path: "sl-report/service_level/:activity/:buid/:taskid",
    component: ServiceLevelReportPageComponent,
  },
  { path: "oauth", component: OauthRedirectComponent },
  { path: "**", component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
