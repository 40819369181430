<div class="row sl-entry">
  <div class="col-sm-2">
    <div class="bold">{{ slDto.alias ? slDto.alias : slDto?.sl }}</div>
    <p>{{ slDto.zisName }}</p>
    <div>
      <hr class="ci-seperator" />
      <div class="anzeigename">{{ slDto.ciDisplayName }}</div>
    </div>
  </div>
  <div class="col-sm-1">
    <p>
      {{ getSetpointRange() }}
    </p>
  </div>
  <div class="col-sm-1">
    <div>
      <ng-template #valuelist>
        <div class="d-flex flex-column boxed-tooltip">
          <span
            >{{ "value_history_crrent_sl:" | translate
            }}{{ " " + getValueOrDisplayDash(getSlrFormat(slDto.istWert).displayValue) }}</span
          >
          <span
            >{{ "value_history_entered_sl:" | translate
            }}{{
              " " + getEnteredValueOrDisplayDash(slInput.get("sltIstMessWertEingabe")?.value)
            }}</span
          >
          <span
            >{{ "value_history_new_itsm_sl:" | translate
            }}{{
              " " + getValueOrDisplayDash(slInput.get("sltIstMessWertNeubewertung")?.value)
            }}</span
          >
          <span
            >{{ "value_history_new_sdm_one_sl:" | translate
            }}{{
              " " + getValueOrDisplayDash(slInput.get("sltIstMessWertNeubewertungSdmOne")?.value)
            }}</span
          >
        </div>
      </ng-template>
      <div [ngbTooltip]="valuelist" class="float-left" style="width: 200">
        {{ getValueOrDisplayDash(slInput.get("sltIstMessWertEingabe")?.value) }}
      </div>
      <div
        *ngIf="showRevaluation()"
        [ngbTooltip]="this.getReasonCategoryLabelForValue() | translate"
      >
        &nbsp;({{ this.slInput.get("sltVerfehltBegruendungsKategorie")?.value }})
      </div>
    </div>
  </div>
  <div class="col-sm-2">
    <div
      *ngIf="showObjectionNote()"
      [ngClass]="
        hasError(slInput.get('tiTeilnehmerWiderspruch')) ? 'measures measures-error' : 'measures'
      "
      (click)="openObjectionNote()"
    >
      {{
        slInput.get("tiTeilnehmerWiderspruch")?.value &&
        slInput.get("tiTeilnehmerWiderspruch")?.value.length > 45
          ? (slInput.get("tiTeilnehmerWiderspruch")?.value | slice : 0 : 45) + "..."
          : slInput.get("tiTeilnehmerWiderspruch")?.value
      }}
    </div>
  </div>
  <div class="col-sm-2">
    <!-- <div>{{ slInput.get("objectionState")?.value }}</div> -->
    <gem-select
      *ngIf="slInput.get('objectionState')?.value === 'REJECTED'"
      [placeholder]="'sl_objectionResponseState'"
      [control]="slInput.get('gemWiderspruchStatus')"
      [options]="getObjectionResponseOptions()"
    ></gem-select>
    <!-- <gem-select
      *ngIf="slInput.get('sltBestaetigungKulanz')?.value === 'abgelehntUndNeubewertung'"
      [placeholder]="'sl_objectionResponseState'"
      [control]="slInput.get('gemWiderspruchStatus')"
      [options]="getObjectionResponseOptions()"
    ></gem-select> -->
  </div>
  <div class="col-sm-1">
    <gem-input-decimal
      *ngIf="showFinalRevaluation() && isObjectionRejected()"
      [ngbTooltip]="'tooltip_nb_nv' | translate"
      [placeholder]="'sl_revaluation'"
      [control]="slInput.get('sltIstMessWertNeubewertungSdmTwo')"
      [suffix]="getSlrFormat(null).suffix"
      [digits]="3"
      [options]="{
        isNumber: isNumber(),
        isDecimal: isDecimal(),
        maxValue: slDto.sollMaxFactored > 0 ? this.slDto.sollMaxFactored : slDto.sollMin * 1000
      }"
    ></gem-input-decimal>
  </div>
  <div class="col-sm-1">
    <div
      *ngIf="slInput.get('sltAbschlussProtokoll')?.value && isObjectionRejected()"
      class="measures"
      (click)="openDecisionNote()"
    >
      {{
        slInput.get("sltAbschlussProtokoll")?.value &&
        slInput.get("sltAbschlussProtokoll")?.value.length > 45
          ? (slInput.get("sltAbschlussProtokoll")?.value | slice : 0 : 45) + "..."
          : slInput.get("sltAbschlussProtokoll")?.value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <div *ngIf="isObjectionRejected()" class="measures" (click)="openDecisionNote()">
      {{
        slInput.get("sltAbschlussInterneNotiz")?.value &&
        slInput.get("sltAbschlussInterneNotiz")?.value.length > 45
          ? (slInput.get("sltAbschlussInterneNotiz")?.value | slice : 0 : 45) + "..."
          : slInput.get("sltAbschlussInterneNotiz")?.value
      }}
      <!-- {{
        slInput.get("sltFinalInterneNotiz")?.value &&
        slInput.get("sltFinalInterneNotiz")?.value.length > 45
          ? (slInput.get("sltFinalInterneNotiz")?.value | slice : 0 : 45) + "..."
          : slInput.get("sltFinalInterneNotiz")?.value
      }} -->
    </div>
  </div>
  <div class="col-sm-1">
    <span class="material-icons icons" (click)="openDetailsDialog()">info</span>
  </div>
</div>
<hr />
