import { Subscription } from "rxjs";
import { FormHelperService } from "../../helper/form-helper.service";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
  selector: "gem-timepicker",
  templateUrl: "./gem-timepicker.component.html",
  styleUrls: ["./gem-timepicker.component.scss"],
})
export class GemTimepickerComponent implements OnInit, OnDestroy {
  inputControl: UntypedFormControl;
  isRequired: boolean;
  requiredErrorMessage: string;
  langSubscription: Subscription;
  disableClick: boolean;

  @Input() placeholder: string;
  @Input() min: string;

  minGapIntern = 5;
  minGap = 5;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);

    const placeholderInputValue = this.placeholder;

    if (this.placeholder && this.placeholder !== "") {
      this.placeholder = this.translateService.instant(this.placeholder);
    }

    this.langSubscription = this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if (this.placeholder && this.placeholder !== "") {
          this.placeholder = this.translateService.instant(placeholderInputValue);
        }
      },
    );
  }

  @Input() set minutesGap(value: number) {
    if (value) {
      this.minGapIntern = value;
      this.minGap = value;
    } else {
      this.minGapIntern = 5;
      this.minGap = 5;
    }
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}

  focusOut(value: number) {
    this.minGap = value;
    this.disableClick = true;
  }

  focusIn(value: number) {
    this.minGap = value;
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}
