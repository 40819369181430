<div>
  <ol>
    <li>
      Haben Sie Bedarf diesen Prozessdialog besser kennen zu lernen, dann nutzen Sie gern die Sektion "Hilfe&nbsp;", um n&uuml;tzliche Informationen und
      konkrete Handlungsempfehlungen zu erhalten. Ein Klick auf die graue Fl&auml;che/&Uuml;berschrift klappt eine Sektion auf bzw. zu.
    </li>
    <li>
      Identifizieren Sie in der Sektion "Kontextdaten" zun&auml;chst f&uuml;r welchen Berichtsmonat und f&uuml;r welchen TI-ITSM-Teilnehmer die Service Level zu
      erfassen sind. Dies ist insbesondere f&uuml;r die TI-ITSM-Teilnehmer wichtig, die in unterschiedlichen betrieblichen Rollen in der TI aktiv sind.
    </li>
    <li>Um das Formular&nbsp;<strong>absenden</strong>&nbsp;zu k&ouml;nnen, f&uuml;llen Sie bitte alle Pflichtfelder () aus.</li>
  </ol>
</div>
