import { Component, Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

@Injectable()
export class SnackBarService {
  private duration: number = 3 * 1000;
  private horizontalPosition: MatSnackBarHorizontalPosition = "right";
  private verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(private snackBar: MatSnackBar) {}

  openSuccess(message: string): void {
    this.open(message, "success-snackbar");
  }

  openWarning(message: string): void {
    this.open(message, "warning-snackbar");
  }

  openError(message: string): void {
    this.open(message, "error-snackbar");
  }

  private open(message: string, panelClass: string): void {
    this.snackBar.open(message, "", {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: panelClass,
    });
  }
}
