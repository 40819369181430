import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MaterialPaginatorI18n } from "../../utils/material/material-paginator-i18n";
import { ServiceLevelEntryComponent } from "./service-level-entry/service-level-entry.component";
import { GemComponentsModule } from "gematik-form-library";
import { MeasuresDialogComponent } from "./measures-dialog/measures-dialog.component";
import { HelpContentComponent } from "./help-content/help-content.component";
import { ActionContentComponent } from "./action-content/action-content.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DetailsDialogComponent } from "./details-dialog/details-dialog.component";
import { FieldDialogComponent } from "./field-dialog/field-dialog.component";
import { ServiceLevelFormalCheckComponent } from "./service-level-formal-check/service-level-formal-check.component";
import { ServiceLevelContentCheckComponent } from "./service-level-content-check/service-level-content-check.component";
import { ServiceLevelResultCheckComponent } from "./service-level-result-check/service-level-result-check.component";
import { ServiceLevelObjectionHandlingComponent } from "./service-level-objection-handling/service-level-objection-handling.component";
import { HeaderContentComponent } from "./header-content/header-content.component";
import { MatSortModule } from "@angular/material/sort";
import { ServiceLevelFrontEndService } from "./service-level-front-end.service";
import { NotesDialogComponent } from "./notes-dialog/notes-dialog.component";

@NgModule({
  declarations: [
    ServiceLevelEntryComponent,
    MeasuresDialogComponent,
    HelpContentComponent,
    ActionContentComponent,
    DetailsDialogComponent,
    FieldDialogComponent,
    ServiceLevelFormalCheckComponent,
    ServiceLevelContentCheckComponent,
    ServiceLevelResultCheckComponent,
    ServiceLevelObjectionHandlingComponent,
    HeaderContentComponent,
    NotesDialogComponent,
  ],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    TranslateModule,
    GemComponentsModule,
    NgbModule,
  ],
  exports: [
    MatBottomSheetModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    TranslateModule,
    ServiceLevelEntryComponent,

    HelpContentComponent,
    ActionContentComponent,
    ServiceLevelFormalCheckComponent,
    ServiceLevelContentCheckComponent,
    ServiceLevelResultCheckComponent,
    ServiceLevelObjectionHandlingComponent,
    HeaderContentComponent,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useClass: MaterialPaginatorI18n,
    },
    ServiceLevelFrontEndService,
  ],
})
export class PageComponentsModule {}
