import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";

import * as entraIdActions from "../actions/entra-id-groups.actions";
import { EntraIdService } from "../../services/entra-id.service";

@Injectable()
export class EntraIdGroupsEffects {
  constructor(private actions$: Actions, private entraIdService: EntraIdService) {}

  fetchEntraIdGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(entraIdActions.fetchEntraIdGroups),
      mergeMap(() =>
        this.entraIdService.getGroupNamesByUserId().pipe(
          map((groups) => entraIdActions.fetchEntraIdGroupsSuccess({ groups })),
          catchError((error) => of(entraIdActions.fetchEntraIdGroupsFailure(error))),
        ),
      ),
    ),
  );
}
