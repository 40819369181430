import { Component } from "@angular/core";

@Component({
  selector: "gem-loading-spinner-no-backdrop",
  styleUrls: ["gem-loading-spinner-no-backdrop.component.scss"],
  template: ` <div>
    <div class="lds-gem">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>`,
})
export class GemLoadingSpinnerNoBackdropComponent {
  constructor() {}
}
