import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import {
  ServiceLevelDto,
  ServiceLevelInputDto,
  ServiceLevelService,
} from "../../services/service-level.service";
import { TranslateService } from "@ngx-translate/core";
import { SlrFormatService } from "../page-components/slr-format.service";
import { SlrFormHelper } from "../../utils/slr.form.helper";
import { GematikCompleteTaskDto } from "gematik-task-api";
import { ValueLabel } from "projects/gematik-form-library/src/lib/gem-components/value-label";
import { ServiceLevelFrontEndService } from "../page-components/service-level-front-end.service";
import { Title } from "@angular/platform-browser";

export class ServiceLevelGroup {
  slInput: UntypedFormGroup;
  slDto: ServiceLevelDto;
}

@Component({
  selector: "service-level-report-page",
  templateUrl: "./service-level-report-page.component.html",
  styleUrls: ["./service-level-report-page.component.scss"],
  animations: [
    trigger("openClose", [
      state("true", style({ width: "*", opacity: 1 })),
      state("false", style({ width: "0px", opacity: 0 })),
      transition("false <=> true", animate(3000)),
    ]),
  ],
})
export class ServiceLevelReportPageComponent implements OnInit {
  buid: string = "";
  activity: string = "";
  taskid: string = "";

  serviceLevels: ServiceLevelGroup[] = [];
  _serviceLevels: ServiceLevelGroup[] = [];

  @Input() selectedIndex: number;

  form: UntypedFormGroup = this.formBuilder.group({
    filter: ["P"],
  });

  constructor(
    private route: ActivatedRoute,
    private serviceLevelService: ServiceLevelService,
    private formBuilder: UntypedFormBuilder,
    private slrFormatService: SlrFormatService,
    private translationService: TranslateService,
    private slrFormHelper: SlrFormHelper,
    private serviceLevelFrontEndService: ServiceLevelFrontEndService,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("buid")) {
        this.buid = paramMap.get("buid");
      }
      if (paramMap.has("activity")) {
        this.activity = paramMap.get("activity");
      }
      if (paramMap.has("taskid")) {
        this.taskid = paramMap.get("taskid");
      }
      this.title.setTitle(`SL Review (${this.buid})`);
    });
    this.loadServiceLevels();
    this.selectedIndex++;

    this.form.get("filter").valueChanges.subscribe((filter) => {
      this.handleFilter(filter);
    });
  }

  loadServiceLevels() {
    this.serviceLevelService.getReviewDataForBuid(this.buid).subscribe((res) => {
      if (res.body && res.body.length > 0) {
        for (const line of res.body) {
          if (
            (this.activity === "ut_report_service_level" &&
              line.slInput.akzeptanzKategorie !== "ACCEPTED") ||
            this.activity === "ut_formal_material_check" ||
            this.activity === "ut_content_check" ||
            (this.activity === "ut_result_check" &&
              line.slInput.sltBestaetigungKulanz === "abgelehntUndNeubewertung") ||
            this.activity === "ut_objection_handling"
            // (this.activity === "ut_objection_handling" &&
            //   line.slInput.sltBestaetigungKulanz === "abgelehntUndNeubewertung")
          ) {
            this.addServiceLevelFormGroup(line.slDto, line.slInput, false);
          }
        }
        if (this.showFilter()) {
          this.handleFilter(this.form.get("filter").value);
        }
      } else {
        this.initForm();
      }
    });
  }

  initForm() {
    this.serviceLevelService.getServiceLevelForBuid(this.buid).subscribe((res) => {
      let serviceLevelDtoList = res.body;
      for (const slDto of serviceLevelDtoList) {
        let slInput = new ServiceLevelInputDto();
        let slrFormat = this.slrFormatService.getFormat(slDto.istWert, slDto.unitId);
        slInput.sltIstMessWertEingabe = slrFormat.displayValue;
        slInput.buid = slDto.buid;
        slInput.bdvId = slDto.bdvId;
        this.addServiceLevelFormGroup(slDto, slInput, true);
      }
      this.setInitialValidator();
    });
  }

  private addServiceLevelFormGroup(
    slDto: ServiceLevelDto,
    slInput: ServiceLevelInputDto,
    isInit: boolean,
  ) {
    if (!isInit) {
      // Format display
      slInput.sltIstMessWertEingabe = isNaN(+slInput.sltIstMessWertEingabe)
        ? slInput.sltIstMessWertEingabe
        : this.slrFormatService.getFormat(+slInput.sltIstMessWertEingabe, slDto.unitId)
            .displayValue;

      if (slInput.sltIstMessWertNeubewertung) {
        slInput.sltIstMessWertNeubewertung = isNaN(+slInput.sltIstMessWertNeubewertung)
          ? slInput.sltIstMessWertNeubewertung
          : this.slrFormatService.getFormat(+slInput.sltIstMessWertNeubewertung, slDto.unitId)
              .displayValue;
      }

      if (slInput.sltIstMessWertNeubewertungSdmOne) {
        slInput.sltIstMessWertNeubewertungSdmOne = isNaN(+slInput.sltIstMessWertNeubewertungSdmOne)
          ? slInput.sltIstMessWertNeubewertungSdmOne
          : this.slrFormatService.getFormat(+slInput.sltIstMessWertNeubewertungSdmOne, slDto.unitId)
              .displayValue;
      }

      if (slInput.sltIstMessWertNeubewertungSdmTwo) {
        slInput.sltIstMessWertNeubewertungSdmTwo = isNaN(+slInput.sltIstMessWertNeubewertungSdmTwo)
          ? slInput.sltIstMessWertNeubewertungSdmTwo
          : this.slrFormatService.getFormat(+slInput.sltIstMessWertNeubewertungSdmTwo, slDto.unitId)
              .displayValue;
      }
    }

    let slInputFromGroup = this.formBuilder.group(slInput);
    let slGroup = new ServiceLevelGroup();
    slDto.sollMaxFactored = slDto.sollMax * 100;
    slGroup.slDto = slDto;
    slGroup.slInput = slInputFromGroup;
    this.serviceLevels.push(slGroup);
    this._serviceLevels.push(slGroup);
  }

  setInitialValidator() {
    for (const sl of this.serviceLevels) {
      //sl.get('slInput').get('sltIstMessWertEingabe').setValidators([Validators.required, this.formHelper.isValidNumber()]);
    }
  }

  mapFormToServiceLevelInputDtoArray(submit: boolean): ServiceLevelInputDto[] {
    let slInputList: ServiceLevelInputDto[] = [];
    for (const sl of this._serviceLevels) {
      let slInput = <ServiceLevelInputDto>(<unknown>(<UntypedFormGroup>sl.slInput).getRawValue());

      if (slInput.sltIstMessWertEingabe) {
        slInput.sltIstMessWertEingabe = this.slrFormatService
          .formatToDataWarehouseValue(slInput.sltIstMessWertEingabe, sl.slDto.unitId)
          .toString();
      }

      if (slInput.sltIstMessWertNeubewertung) {
        slInput.sltIstMessWertNeubewertung = this.slrFormatService
          .formatToDataWarehouseValue(slInput.sltIstMessWertNeubewertung, sl.slDto.unitId)
          .toString();
      }

      if (slInput.sltIstMessWertNeubewertungSdmOne) {
        slInput.sltIstMessWertNeubewertungSdmOne = this.slrFormatService
          .formatToDataWarehouseValue(slInput.sltIstMessWertNeubewertungSdmOne, sl.slDto.unitId)
          .toString();
      }

      if (slInput.sltIstMessWertNeubewertungSdmTwo) {
        slInput.sltIstMessWertNeubewertungSdmTwo = this.slrFormatService
          .formatToDataWarehouseValue(slInput.sltIstMessWertNeubewertungSdmTwo, sl.slDto.unitId)
          .toString();
      }

      if (
        this.activity === "ut_formal_material_check" &&
        submit &&
        slInput.akzeptanzKategorie === "ACCEPTED"
      ) {
        slInput.isFormalMaterialCheckComplete = true;
      } else if (
        this.activity === "ut_formal_material_check" &&
        submit &&
        slInput.akzeptanzKategorie !== "ACCEPTED"
      ) {
        slInput.isFormalMaterialCheckComplete = false;
      }

      // Reset
      this.resetValues(slInput);

      slInputList.push(slInput);
    }
    return slInputList;
  }

  reset() {}

  onSave(submit: boolean = false) {
    const incrementCounter: boolean =
      submit && this.activity === "ut_formal_material_check" ? true : false;

    let task = new GematikCompleteTaskDto();

    let title: string = "save_success_title";
    let message: string = "save_success_message";

    if (submit) {
      title = "save_submit_success_title";
      message = "save_submit_success_message";

      task.taskId = this.taskid;
      let outcome: string = "APPROVE";
      const data: ServiceLevelInputDto[] = this.mapFormToServiceLevelInputDtoArray(true);
      const rejected: boolean = this.serviceLevelFrontEndService.isRejected(data, this.activity);
      if (rejected) {
        outcome = "REJECT";
      }
      task.outcome = outcome;

      if (
        (this.activity === "ut_content_check" && outcome === "APPROVE") ||
        this.activity === "ut_objection_handling"
      ) {
        title = "save_submit_complete_success_title";
        message = "save_submit_complete_success_message";
      }

      this.slrFormHelper.isFormArrayValidElseShowErrors(
        this.serviceLevels,
        "form_could_not_be_submitted",
        () => {
          this.save(submit, task, incrementCounter, title, message);
        },
      );
    } else {
      this.save(submit, task, incrementCounter, title, message);
    }
  }

  private save(
    submit: boolean,
    task: GematikCompleteTaskDto,
    incrementCounter: boolean,
    title: string,
    message: string,
  ) {
    this.serviceLevelService
      .saveReviewDataForBuid(
        this.mapFormToServiceLevelInputDtoArray(submit),
        task,
        incrementCounter,
        submit,
      )
      .subscribe(
        (res) => {
          const dialogRef = this.serviceLevelFrontEndService.openDialog(
            this.translationService.instant(title),
            this.translationService.instant(message),
          );
          dialogRef.afterClosed().subscribe(() => {
            window.close();
          });
        },
        (err) => {
          this.serviceLevelFrontEndService.openErrorDialog(
            this.translationService.instant("save_submit_error"),
            err,
          );
        },
      );
  }

  private resetValues(slInput: ServiceLevelInputDto) {
    if (this.activity === "ut_report_service_level") {
      slInput.akzeptanzKategorie = "";
      slInput.ablehnungsHinweis = "";
    }
  }

  getHeaderTitle() {
    let activityName = this.translationService.instant(this.activity);
    return "Service Level Review - " + activityName + " (" + this.buid + ")";
  }

  showFilter(): boolean {
    if (this.activity === "ut_formal_material_check" || this.activity === "ut_objection_handling") {
      return true;
    }
    return false;
  }

  getFilterOptions(): ValueLabel[] {
    return this.serviceLevelFrontEndService.filterOptions;
  }

  private handleFilter(action: string): void {
    if (action === "*") {
      this.serviceLevels = this._serviceLevels;
    } else {
      if (this.activity === "ut_formal_material_check") {
        this.serviceLevels = this._serviceLevels.filter(
          (sl) =>
            !sl.slInput.get("isFormalMaterialCheckComplete").value ||
            sl.slInput.get("isFormalMaterialCheckComplete").value === false,
        );
      } else if (this.activity === "ut_objection_handling") {
        this.serviceLevels = this._serviceLevels.filter(
          (sl) => sl.slInput.get("objectionState").value === "REJECTED",
        );
      }
    }
  }
}
