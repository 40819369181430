import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "gematik-form-library";
import { GematikCompleteTaskDto, HttpConfig, Lock } from "gematik-task-api";
import { Observable } from "rxjs";

export class ServiceLevelDto {
  bdvId: number;
  zwg: string;
  companyShortName: string;
  companyDisplayName: string;
  mail: string;
  sdmMail: string;
  ciId: string;
  ciDisplayName: string;
  sl: string;
  alias: string;
  istWert: number;
  sollMin: number;
  sollMax: number;
  sollMaxFactored: number;
  szenario: number;
  reportDate: number;
  buid: string;
  ruleName: string;
  ruleDescription: string;
  unitId: number;
  zisName: string;
  zisBeschreibung: string;
  tidDetails: TidDetails;
}

export class ServiceLevelInputDto {
  bdvId: number;
  buid: string;
  resetCounter = 0;
  sltIstMessWertEingabe: any = "";
  sltVerfehltBegruendungsKategorie = "";
  sltIstMessWertNeubewertung = "";
  sltVerfehltBegruendung = "";
  sltVerfehltMassnahmeGeplant = "";
  sltVerfehltMassnahmeDurchgefuehrt = "";
  sltBestaetigungKulanz = "";
  sltAbschlussProtokoll = "";
  sltAbschlussInterneNotiz = "";
  sltFinalInterneNotiz = "";
  wiederholungsGrund = "";
  ablehnungsHinweis = "";
  akzeptanzKategorie = "";
  objectionState = "";
  sltIstMessWertNeubewertungSdmOne = "";
  sltIstMessWertNeubewertungSdmTwo = "";
  tiTeilnehmerWiderspruch = "";
  gemWiderspruchStatus = "";
  isFormalMaterialCheckComplete: boolean = undefined;
}

export class TidDetails {
  tid: string = "";
  alias1: string = "";
  kurzname: string = "";
  betriebsTypAlias: string = "";
  tidMail: string = "";
  zwgSdm: ZwgSdm[];
  partnerName: string = "";
}

export class ZwgSdm {
  zwg: string;
  mail: string;
  role: string;
}

declare type TaskType = "SIMPLE" | "FORM" | "EXTERNAL";

export class GematikTaskDto {
  id: string = "";
  name: string = "";
  processName: string = "";
  assignee: string = "";
  completedBy: string = "";
  role: string = "";
  partnerType: string = "";
  partner: string = "";
  created: Date = new Date();
  due: Date = new Date();
  followUp: Date = new Date();
  owner: string = "";
  priority: number;
  formKey: string = "";
  buid: string = "";
  customTaskContentOne: string = "";
  customTaskContentTwo: string = "";
  customTaskContentThree: string = "";
  variables: Record<string, string>;
  possibleOutcomes: String[];
  type: TaskType;
  url: string = "";
  reason: string = "";
  outcome: string = "";
}

export class HeaderInformation {
  buid: string;
  reportDate: number;
  taskDto: GematikTaskDto;
  tidDetails: TidDetails;
}

@Injectable({
  providedIn: "root",
})
export class ServiceLevelService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private env: EnvService) {}

  public getServiceLevelForBuid(buid: string): Observable<HttpConfig> {
    return this.http.get(this.env.backendUrl + "/slr/service-level/buid/" + buid, this.config);
  }

  public getReviewDataForBuid(buid: string): Observable<HttpConfig> {
    return this.http.get(this.env.backendUrl + "/slr/review-data/buid/" + buid, this.config);
  }

  public saveReviewDataForBuid(
    reviewData: ServiceLevelInputDto[],
    task: GematikCompleteTaskDto,
    incrementCounter: boolean = false,
    submit: boolean = false,
  ): Observable<HttpConfig> {
    const payload = {
      data: reviewData,
      task,
    };

    return this.http.post(
      `${this.env.backendUrl}/slr/review-data/list?incrementCounter=${incrementCounter}&submit=${submit}`,
      payload,
      this.config,
    );
  }

  public getFormState(buid: string, activity: string): Observable<HttpConfig> {
    let id = buid + "-" + activity;
    return this.http.get(this.env.backendUrl + "/public/slr/form-state/" + id, this.config);
  }

  public saveFormState(buid: string, activity: string, formStep: string): Observable<HttpConfig> {
    let id = buid + "-" + activity;
    return this.http.post(
      this.env.backendUrl + "/public/slr/form-state?id=" + id + "&formStep=" + formStep,
      this.config,
    );
  }

  public getHeaderInformation(buid: string, taskId: string): Observable<HttpConfig> {
    return this.http.get(
      this.env.backendUrl + "/public/slr/header/buid/" + buid + "/" + taskId,
      this.config,
    );
  }

  public releaseLock(id: string, taskId: string): Observable<HttpConfig> {
    const lock: Lock = {
      entityName: "ops.SLReview",
      id: id,
      username: null,
      timeout: null,
      taskId: taskId,
    };
    return this.http.post(this.env.backendUrl + "/api/locking/release", lock, this.config);
  }
}
