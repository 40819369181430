<div class="row sl-entry">
  <div class="col-sm-2">
    <div class="bold">{{ slDto.alias ? slDto.alias : slDto?.sl }}</div>
    <p>{{ slDto?.zisName }}</p>
    <div>
      <hr class="ci-seperator" />
      <div class="anzeigename">{{ slDto?.ciDisplayName }}</div>
    </div>
  </div>
  <div class="col-sm-1">
    <p>
      {{ getSetpointRange() }}
    </p>
  </div>
  <div class="col-sm-1">
    <gem-input-decimal
      [ngbTooltip]="'tooltip_nb_nv' | translate"
      [placeholder]="'sl_current_value'"
      [control]="slInput.get('sltIstMessWertEingabe')"
      [suffix]="getSlrFormat(null).suffix"
      [digits]="3"
      [options]="{
        isNumber: isNumber(),
        isDecimal: isDecimal(),
        maxValue: slDto.sollMaxFactored > 0 ? this.slDto.sollMaxFactored : slDto.sollMin * 1000
      }"
    ></gem-input-decimal>
    <i
      *ngIf="this.slDto.szenario === 2"
      class="material-icons sl-no-data float-right"
      [ngbTooltip]="'scenario2WarningTriangleMouseOver' | translate"
      >warning_amber</i
    >
  </div>
  <div class="col-sm-2">
    <gem-select
      *ngIf="showReasonCategory()"
      [placeholder]="'sl_reason'"
      [control]="slInput.get('sltVerfehltBegruendungsKategorie')"
      [options]="getReasonOptions()"
    ></gem-select>
  </div>
  <div class="col-sm-1">
    <gem-input-decimal
      *ngIf="showRevaluation()"
      [ngbTooltip]="'tooltip_nb_nv' | translate"
      [placeholder]="'sl_revaluation'"
      [control]="slInput.get('sltIstMessWertNeubewertung')"
      [suffix]="getSlrFormat(null).suffix"
      [digits]="3"
      [options]="{
        isNumber: isNumber(),
        isDecimal: isDecimal(),
        maxValue: slDto.sollMaxFactored > 0 ? this.slDto.sollMaxFactored : slDto.sollMin * 1000
      }"
    ></gem-input-decimal>
    <!-- <gem-input
      *ngIf="isNumber() && showRevaluation()"
      [ngbTooltip]="'tooltip_nb_nv' | translate"
      [placeholder]="'sl_revaluation'"
      [control]="slInput.get('sltIstMessWertNeubewertung')"
      [suffix]="getSlrFormat(null).suffix"
    ></gem-input>
    <gem-input-decimal
      *ngIf="isDecimal() && showRevaluation()"
      [ngbTooltip]="'tooltip_nb_nv' | translate"
      [placeholder]="'sl_revaluation'"
      [control]="slInput.get('sltIstMessWertNeubewertung')"
      [suffix]="getSlrFormat(null).suffix"
      [digits]="3"
    ></gem-input-decimal> -->
  </div>
  <div class="col-sm-1">
    <div
      *ngIf="showReason()"
      [ngClass]="
        hasError(slInput.get('sltVerfehltBegruendung')) ? 'measures measures-error' : 'measures'
      "
      (click)="openMeasures()"
    >
      {{
        slInput.get("sltVerfehltBegruendung").value &&
        slInput.get("sltVerfehltBegruendung").value.length > 45
          ? (slInput.get("sltVerfehltBegruendung").value | slice : 0 : 45) + "..."
          : slInput.get("sltVerfehltBegruendung").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <div
      *ngIf="showMeasures()"
      [ngClass]="
        hasError(slInput.get('sltVerfehltMassnahmeGeplant'))
          ? 'measures measures-error'
          : 'measures'
      "
      (click)="openMeasures()"
    >
      {{
        slInput.get("sltVerfehltMassnahmeGeplant").value &&
        slInput.get("sltVerfehltMassnahmeGeplant").value.length > 45
          ? (slInput.get("sltVerfehltMassnahmeGeplant").value | slice : 0 : 45) + "..."
          : slInput.get("sltVerfehltMassnahmeGeplant").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <div
      *ngIf="showMeasures()"
      [ngClass]="
        hasError(slInput.get('sltVerfehltMassnahmeDurchgefuehrt'))
          ? 'measures measures-error'
          : 'measures'
      "
      (click)="openMeasures()"
    >
      {{
        slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value &&
        slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value.length > 45
          ? (slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value | slice : 0 : 45) + "..."
          : slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <span class="material-icons icons" (click)="openDetailsDialog()">info</span>
    <span *ngIf="hasRejection()" class="material-icons icons red" [ngbTooltip]="rejectionTooltip"
      >warning</span
    >
  </div>
</div>
<hr class="sl-entry-row" />

<ng-template #rejectionTooltip>
  <div class="d-flex flex-column">
    <span>{{ getRejectionDecisionToolTip() }}</span>
    <span>{{ getRejectionRemarksToolTip() }}</span>
  </div>
</ng-template>
