import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, take } from "rxjs";
import { QuickAction } from "../models/quick-action.model";
import { SnackBarService } from "./snack-bar.service";
import { TranslateService } from "@ngx-translate/core";
import { Alert } from "../models/alert.model";

@Injectable()
export class PortalService {
  constructor(
    private http: HttpClient,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {}

  public getAlerts(endpoint: string, endpointName: string): Observable<Alert[]> {
    return this.http.get<Alert[]>(`${endpoint}/public/api/portal/alerts/${endpointName}`);
  }

  public addQuickAction(endpoint: string, payload: QuickAction): void {
    this.http
      .post<QuickAction>(`${endpoint}/api/portal/quick-actions`, payload)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.snackBarService.openSuccess(
            this.translateService.instant("quickActions.successMessage"),
          );
        },
        error: (e) => {
          this.snackBarService.openError(
            this.translateService.instant("quickActions.errorMessage"),
          );
        },
      });
  }
}
