import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Feedback } from "../models/feedback.model";
import { GemDialogComponent, GemErrorDialogComponent } from "gematik-form-library";

export interface GemFeedbackData {
  backendUrl: string;
  application: string;
  component: string;
}

type Step = "category" | "general" | "feature" | "bug";

@Component({
  selector: "gem-feedback",
  styleUrls: ["gem-feedback.component.scss"],
  template: `
    <div mat-dialog-title class="mat-dialog-title">
      <div class="d-flex justify-content-end">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="row text-center">
        <div class="col-sm-12 align-items-center">
          <div *ngIf="(currentStep$ | async) === 'category'">
            <h3>{{ "feedback.title" | translate }}</h3>
            <p class="text-muted">{{ "feedback.subTitle" | translate }}</p>
          </div>
          <div *ngIf="(currentStep$ | async) === 'general'">
            <h3>{{ "feedback.generalTitle" | translate }}</h3>
            <p class="text-muted">{{ "feedback.generalText" | translate }}</p>
          </div>
          <div *ngIf="(currentStep$ | async) === 'feature'">
            <h3>{{ "feedback.featureTitle" | translate }}</h3>
            <p class="text-muted">{{ "feedback.featureText" | translate }}</p>
          </div>
          <div *ngIf="(currentStep$ | async) === 'bug'">
            <h3>{{ "feedback.bugTitle" | translate }}</h3>
            <p class="text-muted">{{ "feedback.bugText" | translate }}</p>
          </div>
        </div>
      </div>
    </div>

    <div mat-dialog-content class="mat-dialog-content" style="color: initial">
      <ng-container [ngSwitch]="currentStep$ | async">
        <gem-feedback-category-form
          *ngSwitchCase="'category'"
          (changeStep)="changeStep('category', $event)"
        ></gem-feedback-category-form>
        <gem-feedback-general-form
          *ngSwitchCase="'general'"
          (back)="onBack()"
          (feedback)="submit($event)"
        ></gem-feedback-general-form>
        <gem-feedback-feature-form
          *ngSwitchCase="'feature'"
          (back)="onBack()"
          (feedback)="submit($event)"
        ></gem-feedback-feature-form>
        <gem-feedback-bug-form
          *ngSwitchCase="'bug'"
          (back)="onBack()"
          (feedback)="submit($event)"
        ></gem-feedback-bug-form>
      </ng-container>
    </div>
  `,
})
export class GemFeedbackComponent implements OnInit, OnDestroy {
  private currentStepSubject: BehaviorSubject<Step> = new BehaviorSubject<Step>("category");
  public currentStep$: Observable<Step> = this.currentStepSubject.asObservable();

  subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<GemFeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GemFeedbackData,
    public translateService: TranslateService,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  changeStep(currentStep: string, category: "HOME" | "GENERAL" | "FEATURE" | "BUG") {
    switch (currentStep) {
      case "category":
        if (category === "GENERAL") {
          this.currentStepSubject.next("general");
        } else if (category === "FEATURE") {
          this.currentStepSubject.next("feature");
        } else if (category === "BUG") {
          this.currentStepSubject.next("bug");
        } else if (category === "HOME") {
          this.currentStepSubject.next("category");
        }
        break;
    }
  }

  onBack(): void {
    this.currentStepSubject.next("category");
  }

  submit(feedback: Feedback) {
    const metadata = {
      ...this.deviceService.getDeviceInfo(),
      isMobile: this.deviceService.isMobile(),
      isTablet: this.deviceService.isTablet(),
      isDesktop: this.deviceService.isDesktop(),
    };

    const payload = {
      rating: feedback.rating,
      email: feedback.contact === "WITH_EMAIL" ? feedback.email : "",
      comment: feedback.comments,
      application: this.data.application,
      component: this.data.component,
      metadata: JSON.stringify(metadata),
      categoryName: feedback.category,
    };

    let successTitle: string = "";
    let successText: string = "";

    if (feedback.category === "GENERAL") {
      successTitle = this.translateService.instant("feedback.generalSuccessTitle");
      successText = this.translateService.instant("feedback.generalSuccessText");
    } else if (feedback.category === "FEATURE") {
      successTitle = this.translateService.instant("feedback.featureSuccessTitle");
      successText = this.translateService.instant("feedback.featureSuccessText");
    } else if (feedback.category === "BUG") {
      successTitle = this.translateService.instant("feedback.bugSuccessTitle");
      successText = this.translateService.instant("feedback.bugSuccessText");
    }

    this.subscription = this.http
      .post(`${this.data.backendUrl}/public/feedback`, payload)
      .subscribe(
        (res) => {
          this.dialogRef.close();
          this.dialog.open(GemDialogComponent, {
            data: {
              title: successTitle,
              text: successText,
              style: "text-align: center",
            },
          });
        },

        (error) => {
          this.dialogRef.close();
          this.dialog.open(GemErrorDialogComponent, {
            data: {
              title: this.translateService.instant("feedback.errorTitle"),
              errors: [this.translateService.instant("feedback.errorText")],
            },
          });
        },
      );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
