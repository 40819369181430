import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthConfig, OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { EnvService } from "gematik-form-library";
import { TokenHelperService } from "gematik-form-library";
import { Observable } from "rxjs";
import { delay } from "rxjs/operators";
import { Alert, PortalService, LoadingService } from "gematik-shared";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  isLoading$: Observable<boolean>;
  alerts$: Observable<Alert[]>;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private env: EnvService,
    private tokenHelperService: TokenHelperService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private portalService: PortalService,
  ) {
    if (this.tokenHelperService.isExpired()) {
      this.tokenHelperService.logout();
    }

    const authConfig: AuthConfig = {
      issuer: "https://login.microsoftonline.com/" + env.oauthTennantId + "/v2.0",
      redirectUri: window.location.origin + "/oauth",
      clientId: env.oauthClientId,
      responseType: "code",
      clearHashAfterLogin: false,
      strictDiscoveryDocumentValidation: false,
      scope: "openid " + env.oauthApplicationIdUri + "/user_impersonation",
    };
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocumentAndLogin({ state: window.location.pathname });
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case "token_received":
          this.tokenHelperService.saveToken(this.oauthService.getAccessToken());
          this.router.navigate([decodeURIComponent(this.oauthService.state)]);
          break;
      }
    });

    this.matIconRegistry.addSvgIcon(
      "gem-users",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/gem-users.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "gem-approval-blue",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/Zustimmung_Blau_gematik.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-feedback",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/gem-feedback.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-help",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/Wegweiser_Blau_gematik.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-folder",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/Akte_Blau_gematik.svg"),
    );
  }

  ngOnInit(): void {
    this.alerts$ = this.portalService.getAlerts(this.env.backendUrl, "SLR");
  }

  ngAfterViewInit() {
    this.isLoading$ = this.loadingService.isLoading.pipe(delay(0));
  }
}
