import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ServiceLevelDto } from "../../../services/service-level.service";
import { TranslateService } from "@ngx-translate/core";
import { SlrFormatService } from "../slr-format.service";
import { ServiceLevelFrontEndService } from "../service-level-front-end.service";
import { ValueLabel } from "gematik-form-library/lib/gem-components/value-label";
import { Subscription } from "rxjs";
import { TokenHelperService } from "gematik-form-library";

export interface DialogData {
  title: string;
  slInput: UntypedFormGroup;
  slDto: ServiceLevelDto;
}

@Component({
  selector: "details-dialog",
  templateUrl: "./details-dialog.component.html",
  styleUrls: ["./details-dialog.component.scss"],
})
export class DetailsDialogComponent implements OnInit, OnDestroy {
  displayData: Map<string, any> = new Map();
  _displayData: Map<string, any> = new Map();
  displayedColumns: string[] = ["key", "value"];

  form: UntypedFormGroup = this.formBuilder.group({
    filter: ["F"],
  });

  filterOptions: ValueLabel[] = [
    { value: "A", label: "filter_details_dialog_option_all" },
    { value: "F", label: "filter_details_dialog_option_filled" },
  ];

  filterSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translationService: TranslateService,
    private slrFormatService: SlrFormatService,
    private slrFrontEndService: ServiceLevelFrontEndService,
    private formBuilder: UntypedFormBuilder,
    private tokenHelperService: TokenHelperService,
  ) {
    if (data.slInput && data.slDto) {
      let slDto = data.slDto;
      let slInput = data.slInput;
      this.displayData.set("data_01_tid", slDto.zwg);
      this.displayData.set(
        "data_02_tid_details",
        "<b>" +
          slDto.tidDetails.partnerName +
          " " +
          this.translationService.instant("as") +
          " " +
          slDto.tidDetails.betriebsTypAlias +
          "</b>",
      );
      this.displayData.set(
        "data_03_reportDate",
        this.slrFormatService.formatReportDate(slDto.reportDate.toString()),
      );
      this.displayData.set("data_04_bdvId", slDto.bdvId);
      this.displayData.set("data_05_kennGr", slDto.alias ? slDto.alias : slDto.sl);
      this.displayData.set("data_06_zisName", `<b>${slDto.zisName}</b>`);
      this.displayData.set("data_07_zisBeschreibung", slDto.zisBeschreibung);
      this.displayData.set(
        "data_08_product_info",
        `${slDto?.ciDisplayName ? "<b>" + slDto?.ciDisplayName + "</b>" : ""}`,
      );
      let slrFormatIstWert = this.slrFormatService.getFormat(slDto.istWert, slDto.unitId);
      this.displayData.set("data_09_unit", slrFormatIstWert.suffix);
      this.displayData.set(
        "data_10_sollWerteBereich",
        this.slrFormatService.getSetpointRange(slDto),
      );
      this.displayData.set(
        "data_11_erlaubterWerteBereich",
        this.slrFormatService.getSetpointRange(slDto, true),
      );
      this.displayData.set(
        "data_12_istWert",
        slrFormatIstWert.displayValue
          ? `${slrFormatIstWert.displayValue} ${slrFormatIstWert.suffix}`
          : "",
      );
      this.displayData.set(
        "data_13_sltIstMessWertEingabe",
        !slDto.istWert && slInput.get("sltIstMessWertEingabe").value
          ? `${this.slrFrontEndService.getValueOrDisplayDash(
              slInput.get("sltIstMessWertEingabe").value,
              slDto,
            )}`
          : "",
      );

      this.displayData.set(
        "data_14_begrundungskategorie",
        slInput.get("sltVerfehltBegruendungsKategorie").value
          ? this.translationService.instant(
              this.slrFrontEndService.getReasonCategoryLabelForValue(slInput),
            )
          : "",
      );

      this.displayData.set(
        "data_15_sltIstMessWertNeubewertung",
        slInput.get("sltIstMessWertNeubewertung").value
          ? `${this.slrFrontEndService.getValueOrDisplayDash(
              slInput.get("sltIstMessWertNeubewertung").value,
              slDto,
            )}`
          : "",
      );

      this.displayData.set(
        "data_16_sltVerfehltBegruendung",
        slInput.get("sltVerfehltBegruendung").value,
      );
      this.displayData.set(
        "data_17_sltVerfehltMassnahmeGeplant",
        slInput.get("sltVerfehltMassnahmeGeplant").value,
      );
      this.displayData.set(
        "data_18_sltVerfehltMassnahmeDurchgefuehrt",
        slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value,
      );
      this.displayData.set(
        "data_19_sltBestaetigungKulanz",
        slInput.get("sltBestaetigungKulanz").value
          ? this.translationService.instant(
              this.slrFrontEndService.getDecisionLabelForValue(slInput),
            )
          : "",
      );
      this.displayData.set(
        "data_20_sltIstMessWertNeubewertungSdmOne",
        slInput.get("sltIstMessWertNeubewertungSdmOne").value
          ? `${this.slrFrontEndService.getValueOrDisplayDash(
              slInput.get("sltIstMessWertNeubewertungSdmOne").value,
              slDto,
            )}`
          : "",
      );
      this.displayData.set(
        "data_21_sltAbschlussProtokoll",
        slInput.get("sltAbschlussProtokoll").value,
      );
      this.displayData.set(
        "data_22_objectionState",
        slInput.get("objectionState").value
          ? this.translationService.instant(
              this.slrFrontEndService.getObjectionLabelForValue(slInput),
            )
          : "",
      );
      this.displayData.set(
        "data_23_tiTeilnehmerWiderspruch",
        slInput.get("tiTeilnehmerWiderspruch").value,
      );

      if (this.tokenHelperService.isUserInternal()) {
        this.displayData.set(
          "data_24_sltAbschlussInterneNotiz",
          slInput.get("sltAbschlussInterneNotiz").value,
        );
      }
    }
    this._displayData = this.displayData;
    this.onFilter("F");
  }

  ngOnInit(): void {
    this.filterSub = this.form.get("filter").valueChanges.subscribe((filter) => {
      this.onFilter(filter);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  public orderByKey(a: any, b: any) {
    return a.key;
  }

  private onFilter(action: string) {
    if (action === "A") {
      this.displayData = this._displayData;
    } else {
      const filteredMap = new Map([...this._displayData].filter(([k, v]) => v));
      this.displayData = filteredMap;
    }
  }

  ngOnDestroy(): void {
    if (this.filterSub) {
      this.filterSub.unsubscribe();
    }
  }
}
