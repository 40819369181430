<div class="jumbotron">
  <div class="container">
    <h1 class="display-4">{{ "pageNotFoundHeader" | translate }}</h1>
    <p>{{ "pageNotFoundContent" | translate }}</p>
    <p class="mt-3">
      <a class="btn btn-primary btn-lg" href="#" role="button" (click)="onClose()">{{
        "pageNotFoundCloseBtn" | translate
      }}</a>
    </p>
  </div>
</div>
