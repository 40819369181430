import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ServiceLevelDto } from "../../../services/service-level.service";
import { ValueLabel } from "gematik-form-library/lib/gem-components/value-label";
import { ServiceLevelFrontEndService } from "../service-level-front-end.service";
import { SlrFormat, SlrFormatService } from "../slr-format.service";

@Component({
  selector: "service-level-content-check",
  templateUrl: "./service-level-content-check.component.html",
  styleUrls: ["./service-level-content-check.component.scss"],
})
export class ServiceLevelContentCheckComponent implements OnInit {
  @Input() slDto: ServiceLevelDto;
  @Input() slInput: UntypedFormGroup;
  @Input() activity: string;
  constructor(
    private serviceLevelFrontEndService: ServiceLevelFrontEndService,
    private slrFormatService: SlrFormatService,
  ) {}

  ngOnInit(): void {
    if (!this.slInput.get("sltBestaetigungKulanz").value) {
      this.slInput.get("sltBestaetigungKulanz").setValue("akzeptiert");
    }
    this.slInput.get("sltBestaetigungKulanz").setValidators([Validators.required]);
    this.slInput.get("sltIstMessWertNeubewertungSdmOne").setValidators([Validators.required]);
    this.slInput.get("sltAbschlussProtokoll").setValidators([Validators.required]);
  }
  showReason() {
    return this.serviceLevelFrontEndService.showReason(this.slInput, this.slDto);
  }

  showReasonCategory() {
    return this.serviceLevelFrontEndService.showReasonCategory(this.slInput, this.slDto);
  }

  getReasonCategoryLabelForValue() {
    return this.serviceLevelFrontEndService.getReasonCategoryLabelForValue(this.slInput);
  }

  showMeasures() {
    return this.serviceLevelFrontEndService.showMeasures(this.slInput, this.slDto);
  }

  openMeasures() {
    this.serviceLevelFrontEndService.openMeasures(this.slInput, this.activity);
  }

  showRevaluation() {
    return this.serviceLevelFrontEndService.showRevaluation(this.slInput);
  }

  openDetailsDialog() {
    this.serviceLevelFrontEndService.openDetailsDialog(this.slInput, this.slDto);
  }

  getAcceptanceOptions(): ValueLabel[] {
    return this.serviceLevelFrontEndService.acceptanceOptions;
  }

  getDecisionOptions() {
    return this.serviceLevelFrontEndService.getDecisionOptions(this.slInput, this.slDto);
  }

  openDecisionNote() {
    this.serviceLevelFrontEndService.openDecisionNote(this.slInput, this.activity);
  }

  openInternalNote() {
    this.serviceLevelFrontEndService.openInternalNote(this.slInput, this.activity);
  }

  showDecisionNote() {
    return this.serviceLevelFrontEndService.showDecisionNote(this.slInput);
  }

  showSdmRevaluation() {
    return this.serviceLevelFrontEndService.showSdmRevaluation(this.slInput);
  }

  getSetpointRange() {
    return this.slrFormatService.getSetpointRange(this.slDto);
  }

  getSlrFormat(value: any): SlrFormat {
    return this.slrFormatService.getFormat(value, this.slDto.unitId);
  }

  isNumber() {
    return this.slrFormatService.isNumber(this.slDto);
  }

  isDecimal() {
    return this.slrFormatService.isDecimal(this.slDto);
  }

  hasError(control: AbstractControl) {
    return this.serviceLevelFrontEndService.hasError(control);
  }

  getValueOrDisplayDash(value: string) {
    return this.serviceLevelFrontEndService.getValueOrDisplayDash(value, this.slDto);
  }

  getEnteredValueOrDisplayDash(value: string) {
    return this.serviceLevelFrontEndService.getEnteredValueOrDisplayDash(value, this.slDto);
  }
}
