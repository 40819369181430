import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'action-content',
  templateUrl: './action-content.component.html',
  styleUrls: ['./action-content.component.scss'],
})
export class ActionContentComponent implements OnInit {
  constructor() {}
  @Input() formStep: string;
  ngOnInit(): void {}
}
