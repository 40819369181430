import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ValueLabel } from '../value-label';
import { FormHelperService } from '../../helper/form-helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gem-multiselect',
  templateUrl: './gem-multiselect.component.html',
  styleUrls: ['./gem-multiselect.component.scss']
})
export class GemMultiselectComponent implements OnInit {

  inputControl: UntypedFormControl;
  requiredErrorMessage: string;

  @Input() placeholder: string;
  @Input() hint = "";
  @Input() options: ValueLabel[];
  @Output() selectionChanged = new EventEmitter();

  isRequired: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);
    this.placeholder = this.translateService.instant(this.placeholder);
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }
    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}

  emitChangedSelection(changedValues: any) {
    if (changedValues) {
      this.selectionChanged.emit(changedValues.value);
    }
  }

}
