<mat-form-field>
  <input
    matInput
    placeholder="{{ placeholder + (isRequired ? ' *' : '') }}"
    [formControl]="inputControl"
    [ngxTimepicker]="picker"
    [min]="min"
    [format]="24"
    [disableClick]="disableClick"
    (focusout)="focusOut(minGapIntern)"
    (focus)="focusIn(1)"
  />
  <ngx-material-timepicker-toggle [for]="picker"></ngx-material-timepicker-toggle>
  <mat-error *ngIf="inputControl.hasError('required') && inputControl.touched">{{
    requiredErrorMessage
  }}</mat-error>
</mat-form-field>
<ngx-material-timepicker #picker [minutesGap]="minGap"></ngx-material-timepicker>
