import { Injectable } from "@angular/core";
import { GemDialogService } from "gematik-form-library";
import { FormHelperService } from "gematik-form-library";
import { ServiceLevelGroup } from "../pages/service-level-report-page/service-level-report-page.component";

@Injectable()
export class SlrFormHelper {
  constructor(private formHelper: FormHelperService, private dialogService: GemDialogService) {}

  isFormArrayValidElseShowErrors(
    slGroupArray: ServiceLevelGroup[],
    title: string,
    confirmAction: any,
  ) {
    let hasErrors = false;
    let hasOnlyOverwritableErrors = true;
    let errorMessages = [];
    for (const slGroup of slGroupArray) {
      let form = slGroup.slInput;
      if (!form.valid) {
        hasErrors = true;
        this.formHelper.markControlsAsTouched(form);
        errorMessages.push(this.formHelper.createErrorMessage(form));
        if (!this.formHelper.hasOnlyOverwritableErrors(form)) {
          hasOnlyOverwritableErrors = false;
        }
      }
    }
    if (hasErrors) {
      if (hasOnlyOverwritableErrors) {
        this.dialogService.openConfirmationErrorDialog(title, errorMessages, confirmAction);
      } else {
        this.dialogService.openErrorDialog(title, errorMessages);
      }
    } else {
      confirmAction();
    }
  }
}
