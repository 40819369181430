<div class="row sl-entry">
  <div class="col-sm-2">
    <div class="bold">{{ slDto.alias ? slDto.alias : slDto?.sl }}</div>
    <p>{{ slDto.zisName }}</p>
    <div>
      <hr class="ci-seperator" />
      <div class="anzeigename">{{ slDto.ciDisplayName }}</div>
    </div>
  </div>
  <div class="col-sm-1">
    <p>
      {{ getSetpointRange() }}
    </p>
  </div>
  <div class="col-sm-1">
    <div>
      <div class="float-left">
        {{ getValueOrDisplayDash(slInput.get("sltIstMessWertEingabe").value) }}
      </div>
      <div
        *ngIf="showRevaluation()"
        [ngbTooltip]="this.getReasonCategoryLabelForValue() | translate"
      >
        &nbsp;({{ this.slInput.get("sltVerfehltBegruendungsKategorie").value }})
      </div>
    </div>
  </div>
  <div class="col-sm-1">
    <div *ngIf="slInput.get('sltIstMessWertNeubewertung').value" class="float-left">
      {{ getValueOrDisplayDash(slInput.get("sltIstMessWertNeubewertung").value) }}
    </div>
  </div>
  <div class="col-sm-1">
    <div *ngIf="showReason()" class="measures" (click)="openMeasures()">
      {{
        slInput.get("sltVerfehltBegruendung").value &&
        slInput.get("sltVerfehltBegruendung").value.length > 45
          ? (slInput.get("sltVerfehltBegruendung").value | slice : 0 : 45) + "..."
          : slInput.get("sltVerfehltBegruendung").value
      }}{{
        slInput.get("sltVerfehltBegruendung").value.length <= 45 &&
        (slInput.get("sltVerfehltMassnahmeGeplant").value ||
          slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value)
          ? "..."
          : ""
      }}
    </div>
  </div>
  <div class="col-sm-2">
    <gem-select
      [placeholder]="'sl_sltBestaetigungKulanz'"
      [control]="slInput.get('sltBestaetigungKulanz')"
      [options]="getDecisionOptions()"
    ></gem-select>
  </div>
  <div class="col-sm-1">
    <gem-input-decimal
      *ngIf="showSdmRevaluation()"
      [placeholder]="'sl_revaluation'"
      [control]="slInput.get('sltIstMessWertNeubewertungSdmOne')"
      [suffix]="getSlrFormat(null).suffix"
      [digits]="3"
      [options]="{
        isNumber: isNumber(),
        isDecimal: isDecimal(),
        maxValue: slDto.sollMaxFactored > 0 ? this.slDto.sollMaxFactored : slDto.sollMin * 1000
      }"
    ></gem-input-decimal>
    <!-- <gem-input
      *ngIf="isNumber() && showSdmRevaluation()"
      [placeholder]="'sl_revaluation'"
      [control]="slInput.get('sltIstMessWertNeubewertungSdmOne')"
      [suffix]="getSlrFormat(null).suffix"
    ></gem-input>
    <gem-input-decimal
      *ngIf="isDecimal() && showSdmRevaluation()"
      [placeholder]="'sl_revaluation'"
      [control]="slInput.get('sltIstMessWertNeubewertungSdmOne')"
      [suffix]="getSlrFormat(null).suffix"
      [digits]="3"
    ></gem-input-decimal> -->
  </div>
  <div class="col-sm-1">
    <div
      *ngIf="showDecisionNote()"
      [ngClass]="
        hasError(slInput.get('sltAbschlussProtokoll')) ? 'measures measures-error' : 'measures'
      "
      (click)="openDecisionNote()"
    >
      {{
        slInput.get("sltAbschlussProtokoll").value &&
        slInput.get("sltAbschlussProtokoll").value.length > 45
          ? (slInput.get("sltAbschlussProtokoll").value | slice : 0 : 45) + "..."
          : slInput.get("sltAbschlussProtokoll").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <div class="measures" (click)="openInternalNote()">
      {{
        slInput.get("sltAbschlussInterneNotiz").value &&
        slInput.get("sltAbschlussInterneNotiz").value.length > 45
          ? (slInput.get("sltAbschlussInterneNotiz").value | slice : 0 : 45) + "..."
          : slInput.get("sltAbschlussInterneNotiz").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <span class="material-icons icons" (click)="openDetailsDialog()">info</span>
  </div>
</div>
<hr />
