import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "gem-feedback-category-form",
  styleUrls: ["gem-feedback-category-form.component.scss"],
  template: `
    <div class="row">
      <div class="col-sm-12 col-md-4 mb-2">
        <mat-card
          appearance="outlined"
          class="category-card"
          style="cursor: pointer"
          (click)="onSelectCategory('GENERAL')"
        >
          <mat-card-content class="p-0">
            <div class="text-center">
              <img
                mat-card-image
                src="/gematik-shared/assets/icons/star.svg"
                class="align-self-center"
                style="width: 20%"
              />
              <p class="text-center">{{ "feedback.categoryGeneral" | translate }}</p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-sm-12 col-md-4 mb-2">
        <mat-card
          appearance="outlined"
          class="category-card"
          style="cursor: pointer"
          (click)="onSelectCategory('FEATURE')"
        >
          <mat-card-content class="p-0">
            <div class="text-center">
              <img
                mat-card-image
                src="/gematik-shared/assets/icons/lightbulb.svg"
                class="align-self-center"
                style="width: 20%"
              />
              <p class="text-center">{{ "feedback.categoryFeature" | translate }}</p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-sm-12 col-md-4">
        <mat-card
          appearance="outlined"
          class="category-card"
          style="cursor: pointer"
          (click)="onSelectCategory('BUG')"
        >
          <mat-card-content class="p-0">
            <div class="text-center">
              <img
                mat-card-image
                src="/gematik-shared/assets/icons/error_outline.svg"
                class="align-self-center"
                style="width: 20%"
              />
              <p class="text-center">{{ "feedback.categoryBug" | translate }}</p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  `,
})
export class GemFeedbackCategoryFormComponent {
  @Output() changeStep: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  onSelectCategory(category: string): void {
    this.changeStep.emit(category);
  }
}
