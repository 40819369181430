import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

import { Subscription } from "rxjs";
import { FormHelperService } from "../../helper/form-helper.service";

@Component({
  selector: "gem-datetime",
  styleUrls: ["gem-datetime.component.scss"],
  template: `
    <mat-form-field>
      <mat-label>{{ placeholder }}</mat-label>
      <input matInput type="datetime-local" [formControl]="inputControl" />
      <mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>
    </mat-form-field>
  `,
})
export class GemDateTimeComponent implements OnInit, OnDestroy {
  isRequired: boolean;
  subscriptions: Subscription[] = [];

  @Input() placeholder: string;
  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    const placeholderInputValue = this.placeholder;

    if (this.placeholder && this.placeholder !== "") {
      this.placeholder = this.translateService.instant(this.placeholder);
    }

    const sub = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.placeholder && this.placeholder !== "") {
        this.placeholder = this.translateService.instant(placeholderInputValue);
      }
      this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
    });
    this.subscriptions.push(sub);

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
  }

  inputControl: UntypedFormControl;
  requiredErrorMessage: string;

  constructor(private formHelper: FormHelperService, private translateService: TranslateService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
