import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EnvService {
  public envFileLoaded = false;
  public backendUrl: string = "";
  public production: boolean = false;
  public oauthClientId: string = "";
  public oauthTennantId: string = "";
  public oauthApplicationIdUri: string = "";
  public uwlUrl: string = "";
  public registrationUrl: string = "";
  public boardingUrl: string = "";
  public notificationsBackendUrl: string = "";
  public feedbackBackendUrl: string = "";
  public dsdUrl: string = "";
  public messageBrokerUrl: string = "";
  public crmUrl: string = "";
  constructor() {}
}
