import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { TokenHelperService } from "gematik-form-library";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private tokenHelperService: TokenHelperService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLoggedIn = this.tokenHelperService.isLoggedIn();
    const token = this.tokenHelperService.getToken();
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }

    return next.handle(request);
  }
}
