import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
  title: string;
  text: string;
  fieldname: string;
  control: UntypedFormControl;
  confirmAction: any;
}

@Component({
  selector: "app-field-dialog",
  templateUrl: "./field-dialog.component.html",
  styleUrls: ["./field-dialog.component.scss"],
})
export class FieldDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<FieldDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.control = data.control;
  }
  control: UntypedFormControl;
  ngOnInit(): void {}

  cancelAction(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    if (this.control) {
      this.data.confirmAction(this.control);
    }
    this.dialogRef.close();
  }
}
