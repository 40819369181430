<div class="wrapper mt-3">
  <div class="row">
    <div class="col-sm-12">
      <h1 mat-dialog-title>{{ data.title }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div>
        <gem-radio-button-group
          [control]="form.get('filter')"
          [label]="'Filter'"
          [valueLabels]="filterOptions"
        ></gem-radio-button-group>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <table mat-table [dataSource]="displayData | keyvalue : orderByKey" class="mat-elevation-z8">
      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef>{{ "details_key" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.key | translate }}</td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>{{ "details_value" | translate }}</th>
        <td mat-cell *matCellDef="let element" [innerHTML]="element.value"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <BR />
  <div mat-dialog-actions class="center">
    <button class="btn btn-primary" (click)="close()" tabindex="-1">
      {{ "close_dialog" | translate }}
    </button>
  </div>
</div>
