<mat-toolbar>
  <mat-toolbar-row>
    <div id="divLogo" class="logo-wrapper" routerLink="/home">
      <img src="/gematik-shared/assets/images/logo/gematik.png" class="custom-logo" alt="gematik" />
      {{ appName | translate }}
    </div>
    <span class="toolbar-spacer"></span>

    <div *ngIf="username" class="d-flex align-items-center" style="cursor: default">
      <mat-icon class="nav-icons" svgIcon="gem-users"></mat-icon>
      <span class="username" style="cursor: pointer" [matMenuTriggerFor]="profileMenu"
        >&nbsp;&nbsp;{{ this.tokenHelperService.getFullname() }} <span class="header-pipe">|</span>
      </span>
    </div>
    <div class="d-flex align-items-center">
      <mat-icon
        id="btnFeedback"
        [title]="'feedbackTitle' | translate"
        role="img"
        class="nav-icons ml-2 mr-2"
        svgIcon="gem-feedback"
        style="cursor: pointer; width: 30px; height: 30px"
        (click)="onFeedback()"
      ></mat-icon>
      <span *ngIf="false" class="header-pipe">|</span>
    </div>

    <!-- <div *ngIf="username" class="profile-div" style="cursor: default">
      <mat-icon class="nav-icons" svgIcon="gem-users"></mat-icon>
      <span class="username" style="cursor: pointer" [matMenuTriggerFor]="profileMenu"
        >&nbsp;&nbsp;{{ this.tokenHelperService.getFullname() }} <span class="header-pipe">|</span>
      </span>
    </div>
    <div class="profile-div">
      <mat-icon
        id="btnFeedback"
        [title]="'feedbackTitle' | translate"
        role="img"
        class="nav-icons ml-2 mr-2"
        svgIcon="gem-approval-blue"
        style="cursor: pointer; width: 32px; height: 32px"
        (click)="onFeedback()"
      ></mat-icon>
      <span *ngIf="false" class="header-pipe">|</span>
    </div> -->

    <div *ngIf="false">
      <a class="language-toggle" (click)="onLangClick()">{{ lang }}</a>
    </div>
    <mat-menu #profileMenu="matMenu">
      <button id="logout-button" mat-menu-item (click)="onSignOutClick()">
        {{ "logout" | translate }}
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
