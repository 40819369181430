<div class="row mt-3">
  <div class="col-sm-12">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>
</div>

<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-12"><p [innerHTML]="data.text"></p></div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <gem-textarea [placeholder]="data.fieldname | translate" [control]="control"></gem-textarea>
    </div>
  </div>
</div>
<div mat-dialog-actions class="text-right mb-3">
  <button class="btn btn-secondary resetFilter" (click)="cancelAction()">
    {{ "CANCEL" | translate }}
  </button>
  <button class="btn btn-primary" (click)="confirmAction()">Ok</button>
</div>
