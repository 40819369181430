import { createFeatureSelector, createSelector } from "@ngrx/store";
import { GROUPS_FEATURE_KEY, EntraIdGroupsState } from "../reducers/entra-id-groups.reducer";
import { SECURITY_GROUP_ADMIN, SECURITY_GROUP_BASIC } from "../../services/entra-id.service";

export const getEntraIdGroupsState = createFeatureSelector<EntraIdGroupsState>(GROUPS_FEATURE_KEY);

export const getGroupsEntities = createSelector(getEntraIdGroupsState, (state) => state.entities);

export const isUserAuthorized = createSelector(getGroupsEntities, (entities) => {
  return !!entities[SECURITY_GROUP_BASIC];
});

export const isUserAdmin = createSelector(getGroupsEntities, (entities) => {
  return !!entities[SECURITY_GROUP_ADMIN];
});

export const getGroupsLoaded = createSelector(getEntraIdGroupsState, (state) => state.loaded);

export const getGroupsLoading = createSelector(getEntraIdGroupsState, (state) => state.loading);
