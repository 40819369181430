import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, ActionCreator, createReducer, on, ReducerTypes } from "@ngrx/store";

import {
  fetchEntraIdGroups,
  fetchEntraIdGroupsFailure,
  fetchEntraIdGroupsSuccess,
} from "../actions/entra-id-groups.actions";
import { EntraIdGroup } from "../../services/entra-id.service";

export const GROUPS_FEATURE_KEY = "groups";

export interface EntraIdGroupsState extends EntityState<EntraIdGroup> {
  loading: boolean;
  loaded: boolean;
}

export const entraIdGroupsAdapter: EntityAdapter<EntraIdGroup> =
  createEntityAdapter<EntraIdGroup>();

export const entraIdGroupReducers: ReducerTypes<EntraIdGroupsState, ActionCreator[]>[] = [
  on(fetchEntraIdGroups, (state) => ({ ...state, loading: true, loaded: false })),
  on(fetchEntraIdGroupsSuccess, (state, { groups }) =>
    entraIdGroupsAdapter.setAll(groups, { ...state, loading: false, loaded: true }),
  ),
  on(fetchEntraIdGroupsFailure, (state) => ({ ...state, loading: false, loaded: false })),
];

export const initialEntraIdGroupState: EntraIdGroupsState = entraIdGroupsAdapter.getInitialState({
  loading: false,
  loaded: false,
});

const reducer = createReducer<EntraIdGroupsState | EntraIdGroupsState>(
  initialEntraIdGroupState,
  ...entraIdGroupReducers,
);

export function entraIdGroupReducer(state: EntraIdGroupsState, action: Action) {
  return reducer(state, action);
}
