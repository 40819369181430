import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UtilsModule } from "../utils/utils.module";
import { GemConfirmationDialogComponent } from "./dialogs/gem-confirmation-dialog/gem-confirmation-dialog.component";
import { GemConfirmationErrorDialogComponent } from "./dialogs/gem-confirmation-error-dialog/gem-confirmation-error-dialog.component";
import { GemDialogComponent } from "./dialogs/gem-dialog/gem-dialog.component";
import { GemErrorDialogComponent } from "./dialogs/gem-error-dialog/gem-error-dialog.component";
import { GemFeedbackDialogComponent } from "./dialogs/gem-feedback-dialog/gem-feedback-dialog.component";
import { GemServerErrorDialogComponent } from "./dialogs/gem-server-error-dialog/gem-server-error-dialog.component";
import { GemTextConfirmationDialogComponent } from "./dialogs/gem-text-confirmation-dialog/gem-text-confirmation-dialog.component";
import { MultiDigitDecimalNumberDirective } from "./directives/multi-digit-decimal-number.directive";
import { MultiNumberDirective } from "./directives/multi-number.directive";
import { TabDirective } from "./directives/tab-directive";
import { GemAutocompleteComponent } from "./gem-autocomplete/gem-autocomplete.component";
import { GemCheckboxComponent } from "./gem-checkbox/gem-checkbox.component";
import { GemDatepickerComponent } from "./gem-datepicker/gem-datepicker.component";
import { GemExpansionPanelComponent } from "./gem-expansion-panel/gem-expansion-panel.component";
import { GemFileUploadComponent } from "./gem-file-upload/gem-file-upload.component";
import { GemGoogleMapComponent } from "./gem-google-map/gem-google-map.component";
import { GemInputDecimalComponent } from "./gem-input-decimal/gem-input-decimal.component";
import { GemInputGoogleAddressSelect } from "./gem-input-google-address-select/gem-input-google-address-select.component";
import { GemInputGoogleAddressComponent } from "./gem-input-google-address/gem-input-google-address.component";
import { GemInputComponent } from "./gem-input/gem-input.component";
import { GemLogoComponent } from "./gem-logo/gem-logo.component";
import { GemRadioButtonGroupComponent } from "./gem-radio-button-group/gem-radio-button-group.component";
import { GemRadioButtonGroupVerticalComponent } from "./gem-radio-button-group-vertical/gem-radio-button-group-vertical.component";
import { GemSelectComponent } from "./gem-select/gem-select.component";
import { GemSuggestSelectComponent } from "./gem-suggest-select/gem-suggest-select.component";
import { GemTextareaComponent } from "./gem-textarea/gem-textarea.component";
import { GemTimelineComponent } from "./gem-timeline/gem-timeline.component";
import { GemDocumentsComponent } from "./gem-documents/gem-documents.component";
import { GemRadioButtonGroupOrangeComponent } from "./gem-radio-button-group-orange/gem-radio-button-group-orange.component";
import { GemRadioButtonGroupVerticalOrangeComponent } from "./gem-radio-button-group-vertical-orange/gem-radio-button-group-vertical-orange.component";
import { GemAutocompleteVlComponent } from "./gem-autocomplete-vl/gem-autocomplete-vl.component";
import { GemFilterComponent } from "./gem-filter/gem-filter.component";
import { GemTimelineDialogComponent } from "./dialogs/gem-timeline-dialog/gem-timeline-dialog.component";
import { MatTableResponsiveDirective } from "./directives/mat-table-responsive.directive";
import { GemFileDropZoneComponent } from "./gem-file-drop-zone/gem-file-drop-zone.component";
import { GemDateTimeComponent } from "./gem-datetime/gem-datetime.component";
import { GemMultiselectComponent } from "./gem-multiselect/gem-multiselect.component";
import { GemTimepickerComponent } from "./gem-timepicker/gem-timepicker.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";

@NgModule({
  declarations: [
    GemInputComponent,
    GemInputDecimalComponent,
    GemConfirmationDialogComponent,
    GemConfirmationErrorDialogComponent,
    GemDialogComponent,
    GemErrorDialogComponent,
    GemServerErrorDialogComponent,
    GemTextConfirmationDialogComponent,
    GemExpansionPanelComponent,
    GemSelectComponent,
    GemTextareaComponent,
    GemCheckboxComponent,
    MultiDigitDecimalNumberDirective,
    MultiNumberDirective,
    TabDirective,
    MatTableResponsiveDirective,
    GemDatepickerComponent,
    GemLogoComponent,
    GemInputGoogleAddressComponent,
    GemRadioButtonGroupComponent,
    GemRadioButtonGroupVerticalComponent,
    GemFileUploadComponent,
    GemGoogleMapComponent,
    GemInputGoogleAddressSelect,
    GemSuggestSelectComponent,
    GemAutocompleteComponent,
    GemTimelineComponent,
    GemFeedbackDialogComponent,
    GemDocumentsComponent,
    GemRadioButtonGroupOrangeComponent,
    GemRadioButtonGroupVerticalOrangeComponent,
    GemAutocompleteVlComponent,
    GemFilterComponent,
    GemTimelineDialogComponent,
    GemFileDropZoneComponent,
    GemDateTimeComponent,
    GemMultiselectComponent,
    GemTimepickerComponent,
  ],
  imports: [
    CommonModule,
    UtilsModule,
    FormsModule,
    GoogleMapsModule,
    ClipboardModule,
    NgbModule,
    NgxMaterialTimepickerModule,
  ],
  exports: [
    GemInputComponent,
    GemExpansionPanelComponent,
    GemSelectComponent,
    GemTextareaComponent,
    GemCheckboxComponent,
    MultiDigitDecimalNumberDirective,
    MultiNumberDirective,
    MatTableResponsiveDirective,
    GemInputDecimalComponent,
    GemDatepickerComponent,
    GemConfirmationDialogComponent,
    GemLogoComponent,
    GemInputGoogleAddressComponent,
    GemRadioButtonGroupComponent,
    GemRadioButtonGroupVerticalComponent,
    GemFileUploadComponent,
    GemGoogleMapComponent,
    GemDialogComponent,
    GemErrorDialogComponent,
    GemSuggestSelectComponent,
    GemAutocompleteComponent,
    GemTimelineComponent,
    GemFeedbackDialogComponent,
    GemDocumentsComponent,
    GemRadioButtonGroupOrangeComponent,
    GemRadioButtonGroupVerticalOrangeComponent,
    GemAutocompleteVlComponent,
    GemFilterComponent,
    GemTimelineDialogComponent,
    GemFileDropZoneComponent,
    GemDateTimeComponent,
    GemMultiselectComponent,
    GemTimepickerComponent,
  ],
})
export class GemComponentsModule {}
