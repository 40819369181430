import { Injectable } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { QuickAction } from "../models/quick-action.model";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class UtilService {
  constructor(private translateService: TranslateService) {}

  public getQuickAction(
    applicationId: string,
    displayKey: string,
    icon: string,
    displayPrefixKey: string = undefined,
  ): Observable<QuickAction> {
    const displayNameDe$ = this.translateService.currentLoader.getTranslation("de");
    const displayNameEn$ = this.translateService.currentLoader.getTranslation("en");

    return combineLatest([displayNameDe$, displayNameEn$]).pipe(
      map(([de, en]) => {
        const quickAction: QuickAction = {
          applicationId: applicationId,
          userId: undefined,
          displayNameDe: displayPrefixKey
            ? this.resolve(displayPrefixKey, de) + " " + this.resolve(displayKey, de)
            : this.resolve(displayKey, de),
          displayNameEn: displayPrefixKey
            ? this.resolve(displayPrefixKey, en) + " " + this.resolve(displayKey, en)
            : this.resolve(displayKey, en),
          icon,
          link: window.location.href,
          index: undefined,
          disabled: false,
        };
        return quickAction;
      }),
    );
  }

  private resolve(stringPath, baseObject): any {
    return stringPath.split(".").reduce((p, q) => {
      return p ? p[q] : null;
    }, baseObject || self);
  }
}
