<div class="row sl-entry">
  <div class="col-sm-2">
    <div class="bold">{{ slDto.alias ? slDto.alias : slDto?.sl }}</div>
    <p>{{ slDto.zisName }}</p>
    <div>
      <hr class="ci-seperator" />
      <div class="anzeigename">{{ slDto.ciDisplayName }}</div>
    </div>
  </div>
  <div class="col-sm-1">
    <p>
      {{ getSetpointRange() }}
    </p>
  </div>
  <div class="col-sm-1">
    <div>
      <ng-template #valuelist>
        <div class="d-flex flex-column boxed-tooltip">
          <span
            >{{ "value_history_crrent_sl:" | translate
            }}{{ " " + getValueOrDisplayDash(getSlrFormat(slDto.istWert).displayValue) }}</span
          >
          <span
            >{{ "value_history_entered_sl:" | translate
            }}{{
              " " + getEnteredValueOrDisplayDash(slInput.get("sltIstMessWertEingabe").value)
            }}</span
          >
          <span
            >{{ "value_history_new_itsm_sl:" | translate
            }}{{
              " " + getValueOrDisplayDash(slInput.get("sltIstMessWertNeubewertung").value)
            }}</span
          >
          <span
            >{{ "value_history_new_sdm_one_sl:" | translate
            }}{{
              " " + getValueOrDisplayDash(slInput.get("sltIstMessWertNeubewertungSdmOne").value)
            }}</span
          >
        </div>
      </ng-template>
      <div [ngbTooltip]="valuelist" class="float-left">
        {{ getValueOrDisplayDash(slInput.get("sltIstMessWertEingabe").value) }}
      </div>
      <div
        *ngIf="showRevaluation()"
        [ngbTooltip]="this.getReasonCategoryLabelForValue() | translate"
      >
        &nbsp;({{ this.slInput.get("sltVerfehltBegruendungsKategorie").value }})
      </div>
    </div>
  </div>
  <div class="col-sm-1">
    <div>{{ getDecisionLabelForValue() | translate }}</div>
  </div>
  <div class="col-sm-1">
    <div *ngIf="slInput.get('sltIstMessWertNeubewertungSdmOne').value">
      {{ getValueOrDisplayDash(slInput.get("sltIstMessWertNeubewertungSdmOne").value) }}
    </div>
  </div>
  <div class="col-sm-2">
    <div
      *ngIf="slInput.get('sltBestaetigungKulanz').value === 'abgelehntUndNeubewertung'"
      class="measures"
      (click)="openDecisionNote()"
    >
      {{
        slInput.get("sltAbschlussProtokoll").value &&
        slInput.get("sltAbschlussProtokoll").value.length > 45
          ? (slInput.get("sltAbschlussProtokoll").value | slice : 0 : 45) + "..."
          : slInput.get("sltAbschlussProtokoll").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <gem-select
      *ngIf="slInput.get('sltBestaetigungKulanz').value === 'abgelehntUndNeubewertung'"
      [placeholder]="'sl_objectionState'"
      [control]="slInput.get('objectionState')"
      [options]="getObjectionOptions()"
    ></gem-select>
  </div>
  <div class="col-sm-2">
    <div
      *ngIf="showObjectionNote()"
      [ngClass]="
        hasError(slInput.get('tiTeilnehmerWiderspruch')) ? 'measures measures-error' : 'measures'
      "
      (click)="openObjectionNote()"
    >
      {{
        slInput.get("tiTeilnehmerWiderspruch").value &&
        slInput.get("tiTeilnehmerWiderspruch").value.length > 45
          ? (slInput.get("tiTeilnehmerWiderspruch").value | slice : 0 : 45) + "..."
          : slInput.get("tiTeilnehmerWiderspruch").value
      }}
    </div>
  </div>
  <div class="col-sm-1">
    <span class="material-icons icons" (click)="openDetailsDialog()">info</span>
  </div>
</div>
<hr />
