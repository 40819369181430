import { Component, OnInit } from "@angular/core";
import { EnvService } from "gematik-form-library";

@Component({
  selector: "gem-footer",
  styleUrls: ["gem-footer.component.scss"],
  template: `
    <footer style="background-color: var(--gematik-blue)">
      <div class="container d-flex flex-wrap justify-content-center pt-0">
        <div class="nav pt-3 pb-3">
          <div>
            <a class="nav-link text-white" style="font-size: small">
              © gematik GmbH {{ currentYear }}
            </a>
          </div>
          <div>
            <a class="nav-link text-white" style="font-size: small" (click)="downloadTerms()">{{
              "footer.general-terms" | translate
            }}</a>
          </div>
          <div>
            <a
              class="nav-link text-white"
              style="font-size: small"
              href="https://www.gematik.de/datenschutz"
              target="_blank"
              >{{ "footer.privacy" | translate }}</a
            >
          </div>
          <div>
            <a
              class="nav-link text-white"
              style="font-size: small"
              (click)="navigateToLegalNotice()"
              >{{ "footer.legal-notice" | translate }}</a
            >
          </div>
        </div>
      </div>
    </footer>
  `,
})
export class GemFooterComponent implements OnInit {
  currentYear = new Date().getFullYear();

  constructor(private envService: EnvService) {}

  ngOnInit(): void {}

  navigateToLegalNotice() {
    const url: string = `${this.envService.uwlUrl}/legal-notice`;
    window.open(url);
  }

  downloadTerms() {
    const url: string = `${this.envService.uwlUrl}/assets/docs/gematik-general-terms-of-use.pdf`;
    window.open(url);
  }
}
