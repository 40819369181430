import { Injectable } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as DateFns from "date-fns";
import { GemDialogService } from "../gem-components/dialogs/gem-dialog.service";
import { ValueLabel } from "../gem-components/value-label";

@Injectable({
  providedIn: "root",
})
export class FormHelperService {
  scrollPositions = {};

  constructor(
    private translateService: TranslateService,
    private dialogService: GemDialogService,
  ) {}

  checkRequiredValidator(control: UntypedFormControl) {
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  createRequiredErrorMessage(fieldName: string) {
    return this.translateService.instant("IS_REQUIRED_FIELD", { fieldName });
  }

  createRequiredErrorMessageFromTranslationKey(translationKey: string) {
    return this.translateService.instant("IS_REQUIRED_FIELD", {
      fieldName: this.translateService.instant(translationKey),
    });
  }

  createNoValidDateFormatTranslation(translationKey: string) {
    return this.translateService.instant("noValidDate", {
      dateField: this.translateService.instant(translationKey),
    });
  }

  createNoValidNumberErrorMessage(fieldName: string) {
    return this.translateService.instant("noValidNumber", { fieldName });
  }

  createNoValidNumberErrorMessageFromTranslationKey(translationKey: string) {
    return this.translateService.instant("noValidNumber", {
      fieldName: this.translateService.instant(translationKey),
    });
  }

  createPatternErrorMessageFromTranslationKey(translationKey: string) {
    return this.translateService.instant("patternError", {
      fieldName: this.translateService.instant(translationKey),
    });
  }

  createMinLengthErrorMessageFromTranslationKey(translationKey: string) {
    return this.translateService.instant("minLengthError", {
      fieldName: this.translateService.instant(translationKey),
    });
  }

  createRequireAtLeastOneCheckboxedToBeCheckedErrorMessageFromTranslationKey(
    translationKey: string,
  ) {
    return this.translateService.instant("requireAtLeastOneCheckboxeToBeChecked", {
      fields: this.translateService.instant(translationKey),
    });
  }

  createUniqueErrorMessageFromTranslationKey(translationKey: string) {
    return this.translateService.instant("IS_UNIQUE_FIELD", {
      fieldName: this.translateService.instant(translationKey),
    });
  }

  createCustomErrorMessageFromTranslationKey(translationKey: string, message: string) {
    return this.translateService.instant(message, {
      fieldName: this.translateService.instant(translationKey),
    });
  }

  createErrorMessage(formGroup, usePath: boolean = false, prefix = null): Array<string> {
    let errorMessages = [];
    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      // if controls exists, it's a formGroup with nested form controls in it --> so start recursion
      if (formGroup.get(key).controls) {
        errorMessages = errorMessages.concat(this.createErrorMessage(formGroup.get(key), usePath));
      }
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          const control = formGroup.get(key);
          const path = this.getControlPath(control, ".label");
          let pathWithPrefix = prefix ? prefix + path : path;
          switch (keyError) {
            case "required":
              errorMessages.push(
                this.createRequiredErrorMessageFromTranslationKey(usePath ? pathWithPrefix : key),
              );
              break;
            case "email":
              errorMessages.push(this.translateService.instant("email_invalid"));
              break;
            case "noMobileNumber":
              errorMessages.push(this.translateService.instant("no_mobile_number"));
              break;
            case "pwNotSame":
              errorMessages.push(this.translateService.instant("pwsNotSame"));
              break;
            case "noValidDate":
              errorMessages.push(this.createNoValidDateFormatTranslation(key));
              break;
            case "noValidNumber":
              errorMessages.push(this.createNoValidNumberErrorMessageFromTranslationKey(key));
              break;
            case "pattern":
              errorMessages.push(
                this.createPatternErrorMessageFromTranslationKey(usePath ? pathWithPrefix : key),
              );
              break;
            case "termsCheckboxRequired":
              errorMessages.push(
                this.createRequiredErrorMessageFromTranslationKey("termsCheckboxRequiredMsg"),
              );
              break;
            case "minlength":
              errorMessages.push(this.createMinLengthErrorMessageFromTranslationKey(key));
              break;
            case "requireAtLeastOneCheckboxeToBeChecked": {
              const fields: string[] =
                controlErrors["requireAtLeastOneCheckboxeToBeChecked"].fields;
              const translatedFields: string[] = [];
              for (const field of fields) {
                translatedFields.push(this.translateService.instant(field));
              }
              const key: string = translatedFields.join(", ");
              errorMessages.push(
                this.createRequireAtLeastOneCheckboxedToBeCheckedErrorMessageFromTranslationKey(
                  key,
                ),
              );
              break;
            }
            case "unique": {
              errorMessages.push(this.createUniqueErrorMessageFromTranslationKey(key));
              break;
            }
            case "customError": {
              const { message } = controlErrors["customError"];
              errorMessages.push(this.createCustomErrorMessageFromTranslationKey(key, message));
              break;
            }
          }
        });
      }
    });

    return errorMessages;
  }

  isDateValid(): ValidatorFn {
    return (
      control: AbstractControl,
    ): {
      [key: string]: any;
    } => {
      let validDate = true;
      if (control.value && control.value !== "") {
        validDate = false; //moment(control.value, SelectOptions.dateFormats.display.dateInput, true).isValid();
      }
      return validDate ? null : { noValidDate: { value: control.value } };
    };
  }

  isValidNumber(): ValidatorFn {
    return (
      control: AbstractControl,
    ): {
      [key: string]: any;
    } => {
      let validNumber = true;
      if (control.value) {
        let cleanValue = control.value.replace(",", ".");
        validNumber = !isNaN(cleanValue);
      }
      return validNumber ? null : { noValidNumber: { value: control.value } };
    };
  }

  trimWhitespace(formControl: UntypedFormControl) {
    if (formControl.value) {
      formControl.setValue(formControl.value.trim());
    }
  }

  checkPasswords(formGroup: UntypedFormGroup) {
    const pass = formGroup.controls.password.value;
    const passwordConfirmation = formGroup.controls.passwordConfirmation.value;

    if (pass && pass !== "" && pass !== passwordConfirmation) {
      formGroup.controls.passwordConfirmation.setErrors({ pwNotSame: true });
      formGroup.controls.passwordConfirmation.markAsTouched();
    } else {
      formGroup.controls.passwordConfirmation.setErrors(null);
      formGroup.controls.passwordConfirmation.markAsUntouched();
    }
    return null;
  }

  disableControls(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).disable({ emitEvent: false });
    });
  }

  disableControlsByName(formGroup: AbstractControl, controlNames: string[]) {
    for (const controlName of controlNames) {
      formGroup.get(controlName).disable({ emitEvent: false });
    }
  }

  enableControls(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).enable({ emitEvent: false });
    });
  }

  enableControlsByName(formGroup: AbstractControl, controlNames: string[]) {
    for (const controlName of controlNames) {
      formGroup.get(controlName).enable({ emitEvent: false });
    }
  }

  isFormValidElseShowErrors(
    form: UntypedFormGroup,
    title: string,
    confirmAction: any,
    uniqueErrorMessages: boolean = false,
    usePath: boolean = false,
    prefix: string = null,
  ) {
    if (!form.valid) {
      this.markControlsAsTouched(form);
      let errorMessages = this.createErrorMessage(form, usePath, prefix);
      if (uniqueErrorMessages) {
        errorMessages = Array.from(new Set(errorMessages));
      }
      if (this.hasOnlyOverwritableErrors(form)) {
        if (errorMessages.length > 0) {
          this.dialogService.openConfirmationErrorDialog(title, errorMessages, confirmAction);
        } else {
          confirmAction();
        }
      } else {
        this.dialogService.openErrorDialog(title, errorMessages);
      }
    } else {
      confirmAction();
    }
  }

  private getControlName(control: AbstractControl): string | null {
    if (!control.parent) return null;
    const formGroup = control.parent.controls;
    return Object.keys(formGroup).find((name) => control === formGroup[name]) || null;
  }

  private getControlPath(control: AbstractControl, path: string): string | null {
    const controlName = this.getControlName(control);
    if (!isNaN(Number(controlName))) {
      return this.getControlPath(control.parent, path);
    } else {
      path = this.getControlName(control) + path;
    }
    if (control.parent && this.getControlName(control.parent)) {
      path = "." + path;
      return this.getControlPath(control.parent, path);
    } else {
      if (path.charAt(path.length - 1) === ".") {
        path = path.substring(0, path.length - 1);
      }
      return path;
    }
  }

  hasOnlyOverwritableErrors(formGroup, statusBefore?: boolean) {
    let onlyOverwritableErrors = typeof statusBefore !== "undefined" ? statusBefore : true;

    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;

      // if controls exists, it's a formGroup with nested form controls in it --> so start recursion
      if (formGroup.get(key).controls) {
        onlyOverwritableErrors = this.hasOnlyOverwritableErrors(
          formGroup.get(key),
          onlyOverwritableErrors,
        );
      }

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          switch (keyError) {
            case "required":
              onlyOverwritableErrors = false;
              break;
            case "email":
              onlyOverwritableErrors = false;
              break;
            case "pwNotSame":
              onlyOverwritableErrors = false;
              break;
            case "noDrivingLicenseImg":
              onlyOverwritableErrors = false;
              break;
            case "pattern":
              onlyOverwritableErrors = false;
              break;
            case "minlength":
              onlyOverwritableErrors = false;
              break;
          }
        });
      }
    });
    return onlyOverwritableErrors;
  }

  markControlsAsTouched(formGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).markAsTouched();
      // if controls exists, it's a formGroup with nested form controls in it --> so start recursion
      if (formGroup.get(key).controls) {
        this.markControlsAsTouched(formGroup.get(key));
      }
    });
  }

  sortByLastname(a, b) {
    if (a.lastname < b.lastname) {
      return -1;
    }
    if (a.lastname > b.lastname) {
      return 1;
    }
    return 0;
  }

  sortByLabel(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  sortByModuleName(a, b) {
    if (a.moduleName < b.moduleName) {
      return -1;
    }
    if (a.moduleName > b.moduleName) {
      return 1;
    }
    return 0;
  }

  sortByCompanyName(a, b) {
    if (a.companyname < b.companyname) {
      return -1;
    }
    if (a.companyname > b.companyname) {
      return 1;
    }
    return 0;
  }

  sortTasksByCreatedDate(a: any, b: any) {
    const dateA = DateFns.parseISO(a.created).getTime();
    const dateB = DateFns.parseISO(b.created).getTime();
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  sortTasksByCreatedOn(a: any, b: any) {
    const dateA = DateFns.parseISO(a.createdOn).getTime();
    const dateB = DateFns.parseISO(b.createdOn).getTime();
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  sortByProperty(propertyName: string) {
    return (a, b) => {
      if (
        a[propertyName] &&
        typeof a[propertyName] === "string" &&
        b[propertyName] &&
        typeof b[propertyName] === "string"
      ) {
        return a[propertyName].toLowerCase().localeCompare(b[propertyName].toLowerCase());
      }

      if (a[propertyName] < b[propertyName]) {
        return -1;
      }
      if (a[propertyName] > b[propertyName]) {
        return 1;
      }
      return 0;
    };
  }

  sortByDateProperty(propertyName) {
    return (a, b) => {
      const dateA = this.getTime(DateFns.parseISO(a[propertyName]).getTime());
      const dateB = this.getTime(DateFns.parseISO(b[propertyName]).getTime());
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    };
  }

  getTime(time: number) {
    return time ? time : 0;
  }

  sortTasksByProperty(propertyName: string) {
    return (a, b) => {
      if (
        a.task[propertyName] &&
        typeof a.task[propertyName] === "string" &&
        b.task[propertyName] &&
        typeof b.task[propertyName] === "string"
      ) {
        return a.task[propertyName].toLowerCase().localeCompare(b.task[propertyName].toLowerCase());
      }

      if (a.task[propertyName] < b.task[propertyName]) {
        return -1;
      }
      if (a.task[propertyName] > b.task[propertyName]) {
        return 1;
      }
      return 0;
    };
  }

  sortByLastnameAndFirstname(a, b) {
    const lastnameA = a.lastname.toLowerCase();
    const lastnameB = b.lastname.toLowerCase();
    const firstnameA = a.firstname.toLowerCase();
    const firstnameB = b.firstname.toLowerCase();

    if (lastnameA === lastnameB) {
      return firstnameB - firstnameA;
    }
    return lastnameA > lastnameB ? 1 : -1;
  }

  saveScrollPosition(page: string, position: number) {
    this.scrollPositions[page] = position;
  }

  getScrollPosition(page: string) {
    if (this.scrollPositions[page]) {
      return this.scrollPositions[page];
    } else {
      return 0;
    }
  }

  getLabelForValue(valueLabelArray: ValueLabel[], value: string) {
    const found = valueLabelArray.find((valueLabel) => valueLabel.value === value);
    return found ? found.label : "";
  }
}
