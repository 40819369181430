<div class="row header-top-margin">
  <div class="col-sm-12">
    <gem-expansion-panel
      *ngIf="activity"
      class="top-panel"
      [title]="getHeaderTitle()"
      [expanded]="true"
    >
      <div header class="d-flex align-items-center mr-1">
        <mat-icon svgIcon="gem-folder" class="material-icons nav-icons"></mat-icon>
      </div>
      <header-content body [buid]="this.buid" [taskId]="this.taskid"></header-content>
      <gem-radio-button-group
        *ngIf="showFilter()"
        [control]="form.get('filter')"
        [label]="'Filter'"
        [valueLabels]="getFilterOptions()"
      ></gem-radio-button-group>
    </gem-expansion-panel>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <gem-expansion-panel class="top-panel" [title]="'help_documentation'">
      <div header class="d-flex align-items-center mr-1">
        <mat-icon header svgIcon="gem-help" class="material-icons nav-icons"></mat-icon>
      </div>
      <help-content body [activity]="activity"></help-content>
    </gem-expansion-panel>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <mat-card appearance="outlined">
      <div *ngIf="activity === 'ut_report_service_level'">
        <div class="row bold header">
          <div class="col-sm-2">Service Level</div>
          <div class="col-sm-1">Sollwertebereich</div>
          <div class="col-sm-1">SL-Wert</div>
          <div class="col-sm-2">Begründungskategorie</div>
          <div class="col-sm-1">Neubewertung</div>
          <div class="col-sm-1">Begründung</div>
          <div class="col-sm-1">Geplante Maßnahmen</div>
          <div class="col-sm-1">Durchgeführte Maßnahmen</div>
          <div class="col-sm-1 icons"></div>
        </div>
      </div>
      <div *ngIf="activity === 'ut_formal_material_check'">
        <div class="row bold header">
          <div class="col-sm-2">Service Level</div>
          <div class="col-sm-1">Sollwertebereich</div>
          <div class="col-sm-1">SL-Wert</div>
          <div class="col-sm-1">Neubewertung</div>
          <div class="col-sm-1">Begründung</div>
          <div class="col-sm-1">Geplante Maßnahmen</div>
          <div class="col-sm-1">Durchgeführte Maßnahmen</div>
          <div class="col-sm-2">Akzeptanzkategorie</div>
          <div class="col-sm-2">{{ "ablehnungsHinweis" | translate }}</div>
        </div>
      </div>
      <div *ngIf="activity === 'ut_content_check'">
        <div class="row bold header">
          <div class="col-sm-2">Service Level</div>
          <div class="col-sm-1">Sollwertebereich</div>
          <div class="col-sm-1">SL-Wert</div>
          <div class="col-sm-1">Neubewertung TI SDM</div>
          <div class="col-sm-1">Begründung</div>
          <div class="col-sm-2">Entscheidung GTI SDM</div>
          <div class="col-sm-1">Neubewertung GTI SDM</div>
          <div class="col-sm-1">Hinweis für TI SDM</div>
          <div class="col-sm-1">Interne Notiz</div>
          <div class="col-sm-1 icons"></div>
        </div>
      </div>
      <div *ngIf="activity === 'ut_result_check'">
        <div class="row bold header">
          <div class="col-sm-2">Service Level</div>
          <div class="col-sm-1">Sollwertebereich</div>
          <div class="col-sm-1">SL-Wert</div>
          <div class="col-sm-1">Entscheidung GTI SDM</div>
          <div class="col-sm-1">Neubewertung GTI SDM</div>
          <div class="col-sm-2">Hinweis für TI SDM</div>
          <div class="col-sm-1">Stellungnahme</div>
          <div class="col-sm-2">Widerspruchsbegründung</div>
          <div class="col-sm-1 icons"></div>
        </div>
      </div>
      <div *ngIf="activity === 'ut_objection_handling'">
        <div class="row bold header">
          <div class="col-sm-2">Service Level</div>
          <div class="col-sm-1">Sollwertebereich</div>
          <div class="col-sm-1">SL-Wert</div>
          <div class="col-sm-2">Widerspruchsbegründung</div>
          <div class="col-sm-2">Finale Entscheidung GTI SDM</div>
          <div class="col-sm-1">Finale Festlegung</div>
          <div class="col-sm-1">Hinweis für TI SDM</div>
          <div class="col-sm-1">Interne Notiz</div>
          <div class="col-sm-1 icons"></div>
        </div>
      </div>
      <div *ngIf="activity === 'ut_report_service_level'">
        <service-level-entry
          *ngFor="let serviceLevel of serviceLevels"
          [slDto]="serviceLevel.slDto"
          [slInput]="serviceLevel.slInput"
          [activity]="this.activity"
        >
        </service-level-entry>
      </div>
      <div *ngIf="activity === 'ut_formal_material_check'">
        <service-level-formal-check
          *ngFor="let serviceLevel of serviceLevels"
          [slDto]="serviceLevel.slDto"
          [slInput]="serviceLevel.slInput"
          [activity]="this.activity"
        >
        </service-level-formal-check>
      </div>
      <div *ngIf="activity === 'ut_content_check'">
        <service-level-content-check
          *ngFor="let serviceLevel of serviceLevels"
          [slDto]="serviceLevel.slDto"
          [slInput]="serviceLevel.slInput"
          [activity]="this.activity"
        >
        </service-level-content-check>
      </div>
      <div *ngIf="activity === 'ut_result_check'">
        <service-level-result-check
          *ngFor="let serviceLevel of serviceLevels"
          [slDto]="serviceLevel.slDto"
          [slInput]="serviceLevel.slInput"
          [activity]="this.activity"
        >
        </service-level-result-check>
      </div>
      <div *ngIf="activity === 'ut_objection_handling'">
        <service-level-objection-handling
          *ngFor="let serviceLevel of serviceLevels"
          [slDto]="serviceLevel.slDto"
          [slInput]="serviceLevel.slInput"
          [activity]="this.activity"
        >
        </service-level-objection-handling>
      </div>
      <div class="row">
        <div class="col-sm-12 center">
          <button (click)="onSave()" class="btn btn-secondary resetFilter">
            {{ "save" | translate }}
          </button>
          <button (click)="onSave(true)" class="btn btn-primary resetFilter">
            {{ "submit_form" | translate }}
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
