import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GemComponentsModule } from "gematik-form-library";
import { ServiceLevelReportPageComponent } from "./service-level-report-page/service-level-report-page.component";
import { UtilsModule } from "gematik-form-library";
import { PageComponentsModule } from "./page-components/page-components.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { OauthRedirectComponent } from "./oauth-redirect/oauth-redirect.component";

@NgModule({
  declarations: [ServiceLevelReportPageComponent, NotFoundPageComponent, OauthRedirectComponent],
  imports: [CommonModule, GemComponentsModule, UtilsModule, PageComponentsModule, NgbModule],
})
export class PagesModule {}
