import { Injectable } from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ServiceLevelDto, ServiceLevelInputDto } from "../../services/service-level.service";
import { ValueLabel } from "gematik-form-library/lib/gem-components/value-label";
import { FormHelperService } from "dist/gematik-form-library";
import { TranslateService } from "@ngx-translate/core";
import { DetailsDialogComponent } from "./details-dialog/details-dialog.component";
import { FieldDialogComponent } from "./field-dialog/field-dialog.component";
import { MeasuresDialogComponent } from "./measures-dialog/measures-dialog.component";
import { SlrFormatService } from "./slr-format.service";
import { GemDialogComponent, GemErrorDialogComponent } from "gematik-form-library";
import { NotesDialogComponent } from "./notes-dialog/notes-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ServiceLevelFrontEndService {
  constructor(
    private formHelper: FormHelperService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private slrFormatService: SlrFormatService,
  ) {}

  acceptanceOptions: ValueLabel[] = [
    { value: "ACCEPTED", label: "acceptance_option_ACCEPTED" },
    { value: "REJECTED_F", label: "acceptance_option_REJECTED_F" },
    { value: "REJECTED_M", label: "acceptance_option_REJECTED_M" },
    { value: "REJECTED_AM", label: "acceptance_option_REJECTED_AM" },
    { value: "CORRECTION", label: "acceptance_option_CORRECTION" },
  ];

  objectionOptions: ValueLabel[] = [
    { value: "ACCEPTED", label: "objection_option_ACCEPTED" },
    { value: "REJECTED", label: "objection_option_REJECTED" },
  ];

  // objectionResponseOptions: ValueLabel[] = [
  //   { value: "ACCEPTED", label: "objection_response_option_ACCEPTED" },
  //   { value: "REJECTED", label: "objection_response_option_REJECTED" },
  // ];

  objectionResponseOptions: ValueLabel[] = [
    { value: "ACCEPTED_GTI_SDM", label: "objection_response_option_ACCEPTED_GTI_SDM" },
    { value: "ACCEPTED_TI_SDM", label: "objection_response_option_ACCEPTED_TI_SDM" },
    { value: "REJECTED", label: "objection_response_option_REJECTED" },
  ];

  reasonOptions: ValueLabel[] = [
    { value: "FM", label: "reason_category_option_FM" },
    { value: "FB", label: "reason_category_option_FB" },
    { value: "SF", label: "reason_category_option_SF" },
    { value: "TF", label: "reason_category_option_TF" },
    { value: "MF", label: "reason_category_option_MF" },
  ];

  reducedReasonOptions: ValueLabel[] = [
    { value: "SF", label: "reason_category_option_SF" },
    { value: "TF", label: "reason_category_option_TF" },
    { value: "MF", label: "reason_category_option_MF" },
  ];

  decisonOptionsFull: ValueLabel[] = [
    { value: "akzeptiert", label: "decision_option_accepted" },
    { value: "kulanz", label: "decision_option_goodwill" },
    { value: "abgelehntUndNeubewertung", label: "decision_option_reject" },
  ];

  decisonOptionsReject: ValueLabel[] = [
    { value: "akzeptiert", label: "decision_option_accepted" },
    { value: "abgelehntUndNeubewertung", label: "decision_option_reject" },
  ];

  decisonOptionsGoodwill: ValueLabel[] = [
    { value: "akzeptiert", label: "decision_option_accepted" },
    { value: "kulanz", label: "decision_option_goodwill" },
  ];

  filterOptions: ValueLabel[] = [
    { value: "*", label: "filter_option_all" },
    { value: "P", label: "filter_option_pending" },
  ];

  public getDecisionOptions(slInput: UntypedFormGroup, slDto: ServiceLevelDto): ValueLabel[] {
    let select = 1;
    let istWert = +slInput.get("sltIstMessWertNeubewertung").value;
    if (!istWert) {
      istWert = +slInput.get("sltIstMessWertEingabe").value;
    }
    if (
      !this.isInTargetRange(istWert, slDto) &&
      this.isManualEntryOrHasCorrectedValue(slInput, slDto) &&
      slInput.get("sltIstMessWertEingabe").value !== "NB" &&
      slInput.get("sltIstMessWertEingabe").value !== "NV" &&
      slInput.get("sltIstMessWertNeubewertung").value !== "NB" &&
      slInput.get("sltIstMessWertNeubewertung").value !== "NV"
    ) {
      return this.decisonOptionsFull;
    }
    if (this.isManualEntryOrHasCorrectedValue(slInput, slDto)) {
      return this.decisonOptionsReject;
    }
    return this.decisonOptionsGoodwill;
  }

  public isManualEntryOrHasCorrectedValue(slInput: UntypedFormGroup, slDto: ServiceLevelDto) {
    if (slDto.szenario === 3) {
      return true;
    }
    if (slInput.get("sltIstMessWertNeubewertung").value) {
      return true;
    }
    if (slDto.istWert !== slInput.get("sltIstMessWertEingabe").value) {
      return true;
    }
    return false;
  }

  public isNbOrNv(slInput: UntypedFormGroup) {
    let istWert = slInput.get("sltIstMessWertEingabe").value;
    if (istWert === "NB" || istWert === "NV") {
      return true;
    }
    let value = slInput.get("sltIstMessWertNeubewertung").value;
    if (value === "NB" || value === "NV") {
      return true;
    }
    return false;
  }

  private openReasonOnlyMeasures(
    title: string,
    text: string,
    activity: string,
    slInput: UntypedFormGroup,
  ) {
    let dialogData = new UntypedFormGroup({
      sltVerfehltBegruendung: new UntypedFormControl(slInput.get("sltVerfehltBegruendung").value),
    });
    dialogData
      .get("sltVerfehltBegruendung")
      .setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
    if (activity !== "ut_report_service_level") {
      dialogData.get("sltVerfehltBegruendung").disable();
    }

    this.openMeasuresDialog(title, text, dialogData, (updValue: UntypedFormGroup) => {
      if (updValue) {
        slInput
          .get("sltVerfehltBegruendung")
          .setValue(updValue.get("sltVerfehltBegruendung").value);
        slInput.get("sltVerfehltBegruendung").markAsTouched();
      }
    });
  }

  public openMeasures(slInput: UntypedFormGroup, activity: string) {
    const title = this.translateService.instant("measures_dialog_title");
    const text = this.translateService.instant("measures_dialog_text");
    let dialogData = new UntypedFormGroup({
      sltVerfehltBegruendung: new UntypedFormControl(slInput.get("sltVerfehltBegruendung").value),
      sltVerfehltMassnahmeGeplant: new UntypedFormControl(
        slInput.get("sltVerfehltMassnahmeGeplant").value,
      ),
      sltVerfehltMassnahmeDurchgefuehrt: new UntypedFormControl(
        slInput.get("sltVerfehltMassnahmeDurchgefuehrt").value,
      ),
    });
    dialogData
      .get("sltVerfehltBegruendung")
      .setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
    dialogData
      .get("sltVerfehltMassnahmeGeplant")
      .setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
    dialogData
      .get("sltVerfehltMassnahmeDurchgefuehrt")
      .setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
    if (activity !== "ut_report_service_level") {
      dialogData.get("sltVerfehltBegruendung").disable();
      dialogData.get("sltVerfehltMassnahmeGeplant").disable();
      dialogData.get("sltVerfehltMassnahmeDurchgefuehrt").disable();
    }
    let istWert = slInput.get("sltIstMessWertEingabe").value;
    let neuBewertung = slInput.get("sltIstMessWertNeubewertung").value;
    if (istWert === "NB" || istWert === "NV" || neuBewertung === "NB" || neuBewertung === "NV") {
      this.openReasonOnlyMeasures(title, text, activity, slInput);
      return;
    }

    this.openMeasuresDialog(title, text, dialogData, (updValue: UntypedFormGroup) => {
      if (updValue) {
        slInput
          .get("sltVerfehltBegruendung")
          .setValue(updValue.get("sltVerfehltBegruendung").value);
        slInput
          .get("sltVerfehltMassnahmeGeplant")
          .setValue(updValue.get("sltVerfehltMassnahmeGeplant").value);
        slInput
          .get("sltVerfehltMassnahmeDurchgefuehrt")
          .setValue(updValue.get("sltVerfehltMassnahmeDurchgefuehrt").value);
        slInput.get("sltVerfehltBegruendung").markAsTouched();
        slInput.get("sltVerfehltMassnahmeGeplant").markAsTouched();
        slInput.get("sltVerfehltMassnahmeDurchgefuehrt").markAsTouched();
      }
    });
  }

  private openMeasuresDialog(
    title: string,
    text: string,
    slInput: UntypedFormGroup,
    confirmAction: any,
  ) {
    this.dialog.open(MeasuresDialogComponent, {
      data: {
        title,
        text,
        slInput,
        confirmAction,
      },
      panelClass: "measures-dialog-style",
    });
  }

  public openDetailsDialog(slInput: UntypedFormGroup, slDto: ServiceLevelDto) {
    const title = this.translateService.instant("details_dialog_title");
    this.dialog.open(DetailsDialogComponent, {
      data: {
        title,
        slInput,
        slDto,
      },
      // panelClass: "details-dialog-style",
    });
  }

  public showMeasures(slInput: UntypedFormGroup, slDto: ServiceLevelDto) {
    let plannedControl = slInput.get("sltVerfehltMassnahmeGeplant");
    let doneControl = slInput.get("sltVerfehltMassnahmeDurchgefuehrt");
    let istWert = slInput.get("sltIstMessWertEingabe").value;
    if (istWert === "NB" || istWert === "NV") {
      plannedControl.reset();
      doneControl.reset();
      plannedControl.disable();
      doneControl.disable();
      return false;
    }
    if (this.isInTargetRange(slInput.get("sltIstMessWertEingabe").value, slDto)) {
      plannedControl.reset();
      doneControl.reset();
      plannedControl.disable();
      doneControl.disable();
      return false;
    }
    if (this.isInTargetRange(slInput.get("sltIstMessWertNeubewertung").value, slDto)) {
      plannedControl.reset();
      doneControl.reset();
      plannedControl.disable();
      doneControl.disable();
      return false;
    }
    let category = slInput.get("sltVerfehltBegruendungsKategorie").value;
    if (!category) {
      plannedControl.reset();
      doneControl.reset();
      plannedControl.disable();
      doneControl.disable();
      return false;
    }
    if (category === "SF" || category === "TF" || category === "MF") {
      plannedControl.enable();
      doneControl.enable();
      return true;
    }
    let value = slInput.get("sltIstMessWertNeubewertung").value;
    if (value && value !== "NB" && value !== "NV") {
      plannedControl.enable();
      doneControl.enable();
      return true;
    }
    plannedControl.reset();
    doneControl.reset();
    plannedControl.disable();
    doneControl.disable();
    return false;
  }

  private isInTargetRange(value: any, slDto: ServiceLevelDto): boolean {
    if (value) {
      let istWert = this.slrFormatService.getOriginal(value, slDto.unitId);
      if (slDto.sollMin !== null && slDto.sollMax !== null) {
        if (istWert >= slDto.sollMin && istWert <= slDto.sollMax) {
          return true;
        }
      }
      if (slDto.sollMin !== null) {
        if (istWert >= slDto.sollMin) {
          return true;
        }
      }
      if (slDto.sollMax !== null) {
        if (istWert <= slDto.sollMax) {
          return true;
        }
      }
    }
    return false;
  }

  openRejectionNote(slInput: UntypedFormGroup) {
    const title = this.translateService.instant("rejection_dialog_title");
    const fieldname = "ablehnungsHinweis";
    let control = new UntypedFormControl(slInput.get("ablehnungsHinweis").value);
    control.setValidators([Validators.required]);
    this.openFieldDialog(title, null, fieldname, control, (updValue: UntypedFormControl) => {
      if (updValue) {
        slInput.get("ablehnungsHinweis").setValue(updValue.value);
        slInput.get("ablehnungsHinweis").markAsTouched();
      }
    });
  }

  public openInternalNote(slInput: UntypedFormGroup, activity: string) {
    const title = this.translateService.instant("internal_note_dialog_title");
    const showDecisionNote = this.showDecisionNote(slInput);
    let dialogData;

    if (activity === "ut_content_check" && showDecisionNote) {
      dialogData = new UntypedFormGroup({
        sltAbschlussProtokoll: new UntypedFormControl(slInput.get("sltAbschlussProtokoll").value),
        sltAbschlussInterneNotiz: new UntypedFormControl(
          slInput.get("sltAbschlussInterneNotiz").value,
        ),
      });
      dialogData.get("sltAbschlussProtokoll").setValidators([Validators.required]);
    } else {
      dialogData = new UntypedFormGroup({
        sltAbschlussInterneNotiz: new UntypedFormControl(
          slInput.get("sltAbschlussInterneNotiz").value,
        ),
      });
    }
    if (activity !== "ut_content_check") {
      dialogData.get("sltAbschlussProtokoll").disable();
    }

    this.openNotesDialog(title, null, dialogData, (updValue: UntypedFormGroup) => {
      if (updValue) {
        if (
          showDecisionNote &&
          (activity === "ut_content_check" || activity === "ut_objection_handling")
        ) {
          slInput
            .get("sltAbschlussProtokoll")
            .setValue(updValue.get("sltAbschlussProtokoll").value);
          slInput
            .get("sltAbschlussInterneNotiz")
            .setValue(updValue.get("sltAbschlussInterneNotiz").value);
          slInput.get("sltAbschlussProtokoll").markAsTouched();
          slInput.get("sltAbschlussInterneNotiz").markAsTouched();
        } else if (activity === "ut_content_check" || activity === "ut_objection_handling") {
          slInput
            .get("sltAbschlussInterneNotiz")
            .setValue(updValue.get("sltAbschlussInterneNotiz").value);
          slInput.get("sltAbschlussInterneNotiz").markAsTouched();
        }
      }

      // if ((updValue && activity === "ut_content_check") || activity === "ut_objection_handling") {
      //   slInput.get("sltAbschlussProtokoll").setValue(updValue.get("sltAbschlussProtokoll").value);
      //   slInput
      //     .get("sltAbschlussInterneNotiz")
      //     .setValue(updValue.get("sltAbschlussInterneNotiz").value);
      //   slInput.get("sltAbschlussProtokoll").markAsTouched();
      //   slInput.get("sltAbschlussInterneNotiz").markAsTouched();
      // }
    });
  }

  public openFinalInternalNote(slInput: UntypedFormGroup) {
    const title = this.translateService.instant("internal_note_dialog_title");
    const fieldname = "sltAbschlussInterneNotiz";
    let control = new UntypedFormControl(slInput.get("sltFinalInterneNotiz").value);
    this.openFieldDialog(title, null, fieldname, control, (updValue: UntypedFormControl) => {
      if (updValue) {
        slInput.get("sltFinalInterneNotiz").setValue(updValue.value);
        slInput.get("sltFinalInterneNotiz").markAsTouched();
      }
    });
  }

  public openDecisionNote(slInput: UntypedFormGroup, activity: string) {
    const title = this.translateService.instant("notes_dialog_title");

    let dialogData;

    if (activity === "ut_content_check" || activity === "ut_objection_handling") {
      dialogData = new UntypedFormGroup({
        sltAbschlussProtokoll: new UntypedFormControl(slInput.get("sltAbschlussProtokoll").value),
        sltAbschlussInterneNotiz: new UntypedFormControl(
          slInput.get("sltAbschlussInterneNotiz").value,
        ),
      });
    } else if (activity === "ut_result_check") {
      dialogData = new UntypedFormGroup({
        sltAbschlussProtokoll: new UntypedFormControl(slInput.get("sltAbschlussProtokoll").value),
      });
    }

    dialogData.get("sltAbschlussProtokoll").setValidators([Validators.required]);

    if (activity !== "ut_content_check" && activity !== "ut_objection_handling") {
      dialogData.get("sltAbschlussProtokoll").disable();
    }

    this.openNotesDialog(title, null, dialogData, (updValue: UntypedFormGroup) => {
      if ((updValue && activity === "ut_content_check") || activity === "ut_objection_handling") {
        slInput.get("sltAbschlussProtokoll").setValue(updValue.get("sltAbschlussProtokoll").value);
        slInput
          .get("sltAbschlussInterneNotiz")
          .setValue(updValue.get("sltAbschlussInterneNotiz").value);
        slInput.get("sltAbschlussProtokoll").markAsTouched();
        slInput.get("sltAbschlussInterneNotiz").markAsTouched();
      }
    });
  }

  private openNotesDialog(
    title: string,
    text: string,
    slInput: UntypedFormGroup,
    confirmAction: any,
  ) {
    this.dialog.open(NotesDialogComponent, {
      data: {
        title,
        text,
        slInput,
        confirmAction,
      },
      panelClass: "measures-dialog-style",
    });
  }

  public openFieldDialog(
    title: string,
    text: string,
    fieldname: string,
    control: UntypedFormControl,
    confirmAction: any,
  ) {
    this.dialog.open(FieldDialogComponent, {
      data: {
        title,
        text,
        fieldname,
        control,
        confirmAction,
      },
      panelClass: "field-dialog-style",
    });
  }

  public showReason(slInput: UntypedFormGroup, slDto: ServiceLevelDto) {
    let control = slInput.get("sltVerfehltBegruendung");
    if (this.isNbOrNv(slInput)) {
      control.enable();
      return true;
    }
    if (this.showMeasures(slInput, slDto)) {
      control.enable();
      return true;
    }
    control.reset();
    control.disable();
    return false;
  }

  public showRevaluation(slInput: UntypedFormGroup) {
    let control = slInput.get("sltIstMessWertNeubewertung");
    if (
      slInput.get("sltVerfehltBegruendungsKategorie").value === "FM" ||
      slInput.get("sltVerfehltBegruendungsKategorie").value === "FB"
    ) {
      control.enable();
      return true;
    }
    control.reset();
    control.disable();
    return false;
  }

  public showReasonCategory(slInput: UntypedFormGroup, slDto: ServiceLevelDto) {
    let szenario = slDto.szenario;
    let control = slInput.get("sltVerfehltBegruendungsKategorie");
    // if (szenario === 3) {
    //   control.disable();
    //   return false;
    // }
    let istWert = slInput.get("sltIstMessWertEingabe").value as string;
    if (!istWert) {
      control.disable();
      return false;
    }
    if (istWert === "NB" || istWert === "NV") {
      control.disable();
      return false;
    }
    if (this.isInTargetRange(istWert, slDto)) {
      control.disable();
      return false;
    }
    slInput.get("sltVerfehltBegruendungsKategorie").enable();
    return true;
  }

  public getReasonCategoryLabelForValue(slInput: UntypedFormGroup) {
    let value = slInput.get("sltVerfehltBegruendungsKategorie").value;
    return this.formHelper.getLabelForValue(this.reasonOptions, value);
  }

  public showRejectionNote(slInput: UntypedFormGroup) {
    let control = slInput.get("ablehnungsHinweis");
    let value = slInput.get("akzeptanzKategorie").value;
    if (value && value !== "ACCEPTED") {
      control.enable();
      return true;
    }
    control.disable();
    return false;
  }

  // public getRejectionToolTip(slInput: UntypedFormGroup) {
  //   let acceptance = slInput.get("akzeptanzKategorie").value;
  //   let toolTip = "";
  //   if (acceptance) {
  //     let label = this.formHelper.getLabelForValue(this.acceptanceOptions, acceptance);
  //     let text = "Correctionnote: " + this.translateService.instant(label);
  //     toolTip = text;
  //     if (slInput.get("ablehnungsHinweis").value) {
  //       toolTip += "<br> Remark: " + slInput.get("ablehnungsHinweis").value;
  //     }
  //   }
  //   return toolTip;
  // }

  public getRejectionDecisionToolTip(slInput: UntypedFormGroup) {
    let acceptance = slInput.get("akzeptanzKategorie").value;
    let toolTip = "";
    if (acceptance) {
      let label = this.formHelper.getLabelForValue(this.acceptanceOptions, acceptance);
      let text =
        this.translateService.instant("rejectionDecisionTooltipLabel") +
        ": " +
        this.translateService.instant(label);
      toolTip = text;
    }
    return toolTip;
  }

  public getRejectionRemarksToolTip(slInput: UntypedFormGroup) {
    let remarks = slInput.get("ablehnungsHinweis").value;
    let toolTip = "";
    if (remarks) {
      toolTip = this.translateService.instant("rejectionRemarksTooltipLabel") + ": " + remarks;
    }
    return toolTip;
  }

  public hasRejection(slInput: UntypedFormGroup) {
    let acceptance = slInput.get("akzeptanzKategorie").value;
    if (acceptance && acceptance !== "ACCEPTED") {
      return true;
    }
    return false;
  }

  public showDecisionNote(slInput: UntypedFormGroup) {
    let control = slInput.get("sltAbschlussProtokoll");
    let decision = slInput.get("sltBestaetigungKulanz").value;
    if (decision && decision !== "akzeptiert") {
      control.enable();
      return true;
    }
    control.disable();
    slInput.get("sltAbschlussProtokoll").reset();
    return false;
  }

  public showSdmRevaluation(slInput: UntypedFormGroup) {
    let control = slInput.get("sltIstMessWertNeubewertungSdmOne");
    let decision = slInput.get("sltBestaetigungKulanz").value;
    if (decision && decision === "abgelehntUndNeubewertung") {
      control.enable();
      return true;
    }
    control.disable();
    return false;
  }

  public showFinalRevaluation(slInput: UntypedFormGroup, slDto: ServiceLevelDto) {
    let control = slInput.get("sltIstMessWertNeubewertungSdmTwo");
    let decision = slInput.get("gemWiderspruchStatus").value;
    if (decision) {
      if (decision === "REJECTED") {
        control.enable();
        return true;
      } else {
        if (decision === "ACCEPTED_TI_SDM") {
          const sltIstMessWertNeubewertung = slInput.get("sltIstMessWertNeubewertung").value;
          const sltIstMessWertEingabe = slInput.get("sltIstMessWertEingabe").value;
          const istWert = slDto.istWert;
          if (sltIstMessWertNeubewertung) {
            control.setValue(sltIstMessWertNeubewertung);
          } else if (sltIstMessWertEingabe) {
            control.setValue(sltIstMessWertEingabe);
          } else {
            control.setValue(istWert);
          }
        } else if (decision === "ACCEPTED_GTI_SDM") {
          control.setValue(slInput.get("sltIstMessWertNeubewertungSdmOne").value);
        }
        control.disable();
      }
      return true;
    }
    control.disable();
    return false;
  }

  public openObjectionNote(slInput: UntypedFormGroup, activity: string) {
    const title = this.translateService.instant("objection_dialog_title");
    const fieldname = "tiTeilnehmerWiderspruch";
    let control = new UntypedFormControl(slInput.get("tiTeilnehmerWiderspruch").value);
    if (activity !== "ut_result_check") {
      control.disable();
    }
    control.setValidators([Validators.required]);
    this.openFieldDialog(title, null, fieldname, control, (updValue: UntypedFormControl) => {
      if (updValue) {
        slInput.get("tiTeilnehmerWiderspruch").setValue(updValue.value);
        slInput.get("tiTeilnehmerWiderspruch").markAsTouched();
      }
    });
  }

  public getDecisionLabelForValue(slInput: UntypedFormGroup) {
    let value = slInput.get("sltBestaetigungKulanz").value;
    return this.formHelper.getLabelForValue(this.decisonOptionsFull, value);
  }

  public getObjectionLabelForValue(slInput: UntypedFormGroup) {
    let value = slInput.get("objectionState").value;
    return this.formHelper.getLabelForValue(this.objectionOptions, value);
  }

  public getValueOrDisplayDash(val: string, slDto: ServiceLevelDto) {
    if (val) {
      const value = val.replace(",", "");
      if (isNaN(+value)) {
        return value;
      }
      return val + " " + this.slrFormatService.getFormat(+value, slDto.unitId).suffix;
    }
    return "--";
  }

  public getEnteredValueOrDisplayDash(value: string, slDto: ServiceLevelDto) {
    if (
      value &&
      (!slDto.istWert ||
        value != this.slrFormatService.getFormat(slDto.istWert, slDto.unitId).displayValue)
    ) {
      if (isNaN(+value)) {
        return value;
      }
      return value + " " + this.slrFormatService.getFormat(+value, slDto.unitId).suffix;
    }
    return "--";
  }

  public showObjectionNote(slInput: UntypedFormGroup) {
    let control = slInput.get("tiTeilnehmerWiderspruch");
    if (slInput.get("objectionState").value === "REJECTED") {
      control.enable();
      return true;
    }
    control.disable();
    return false;
  }

  hasError(control: AbstractControl) {
    return control.errors !== null && control.touched;
  }

  isPending(slInput: UntypedFormGroup, slDto: ServiceLevelDto, activity: string): boolean {
    const showReasonCategory: boolean = this.showReasonCategory(slInput, slDto);
    const istWert = slInput.get("sltIstMessWertEingabe").value;
    const isInRange: boolean = this.isInTargetRange(+istWert, slDto);

    let isDisabled: boolean = false;
    if (slDto?.szenario === 1 || slDto?.szenario === 4) {
      isDisabled = true;
    }

    return (
      (activity === "ut_report_service_level" &&
        slInput.get("akzeptanzKategorie").value !== "ACCEPTED" &&
        showReasonCategory) ||
      (activity === "ut_report_service_level" &&
        slInput.get("akzeptanzKategorie").value !== "ACCEPTED" &&
        !isInRange &&
        !isDisabled) ||
      (activity === "ut_report_service_level" &&
        slInput.get("akzeptanzKategorie").value !== "ACCEPTED" &&
        !isDisabled) ||
      (activity === "ut_formal_material_check" && showReasonCategory) ||
      activity === "ut_content_check" ||
      (activity === "ut_result_check" &&
        slInput.get("sltBestaetigungKulanz").value === "abgelehntUndNeubewertung") ||
      (activity === "ut_objection_handling" &&
        slInput.get("sltBestaetigungKulanz").value === "abgelehntUndNeubewertung")
    );
  }

  isRejected(sls: ServiceLevelInputDto[], activity: string): boolean {
    for (let sl of sls) {
      if (activity === "ut_formal_material_check") {
        if (sl.akzeptanzKategorie && sl.akzeptanzKategorie !== "ACCEPTED") {
          return true;
        }
      } else if (activity === "ut_content_check") {
        if (sl.sltBestaetigungKulanz && sl.sltBestaetigungKulanz === "abgelehntUndNeubewertung") {
          return true;
        }
      } else if (activity === "ut_result_check") {
        if (sl.objectionState && sl.objectionState === "REJECTED") {
          return true;
        }
      }
    }
    return false;
  }

  openDialog(
    title: string,
    text: string,
    copy?: {
      value: string;
      successMessage: string;
      snackBarDuration: number;
    },
  ): MatDialogRef<GemDialogComponent, any> {
    return this.dialog.open(GemDialogComponent, {
      data: {
        title,
        text,
        style: "text-align: center",
        copy,
      },
    });
  }

  openErrorDialog(
    title: string,
    errors: Array<string>,
  ): MatDialogRef<GemErrorDialogComponent, any> {
    return this.dialog.open(GemErrorDialogComponent, {
      data: {
        title,
        errors,
      },
    });
  }
}
