<div class="row">
  <div class="col-sm-3">
    <gem-input [placeholder]="'Vorgangsnummer'" [control]="buidControl"></gem-input>
  </div>
  <div class="col-sm-3">
    <gem-input [placeholder]="'data_03_reportDate'" [control]="reportDateControl"></gem-input>
  </div>
  <div class="col-sm-3" *ngIf="taskGroup.get('due').value">
    <!-- <gem-input [placeholder]="'Frist'" [control]="taskGroup.get('due')"></gem-input> -->
    <gem-datepicker [placeholder]="'Frist'" [control]="taskGroup.get('due')"></gem-datepicker>
  </div>
  <div class="col-sm-3">
    <!-- <gem-input [placeholder]="'data_02_tid_details'" [control]="tidDetailsControl"></gem-input> -->
  </div>
</div>
<div class="row">
  <div class="col-sm-3">
    <gem-input [placeholder]="'data_01_tid'" [control]="tidGroup.get('tid')"></gem-input>
  </div>
  <div class="col-sm-3">
    <gem-input [placeholder]="'Partner'" [control]="tidGroup.get('partnerName')"></gem-input>
  </div>
  <div class="col-sm-3">
    <gem-input
      [placeholder]="'Partnertyp'"
      [control]="tidGroup.get('betriebsTypAlias')"
    ></gem-input>
  </div>
  <div class="col-sm-3">
    <gem-input [placeholder]="'Rolle'" [control]="taskGroup.get('role')"></gem-input>
  </div>
</div>
