import { Component, OnInit } from "@angular/core";

@Component({
  selector: "not-found-page",
  templateUrl: "./not-found-page.component.html",
  styleUrls: ["./not-found-page.component.sass"],
})
export class NotFoundPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  onClose(): void {
    window.close();
  }
}
