import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

export interface DialogData {
  title: string;
  text: string;
  slInput: UntypedFormGroup;
  confirmAction: any;
}
@Component({
  selector: "measures-dialog",
  templateUrl: "./measures-dialog.component.html",
  styleUrls: ["./measures-dialog.component.scss"],
})
export class MeasuresDialogComponent implements OnInit {
  slInput: UntypedFormGroup;

  ngOnInit(): void {}

  constructor(
    public dialogRef: MatDialogRef<MeasuresDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translateService: TranslateService,
  ) {
    if (data.slInput) {
      this.slInput = data.slInput;
    }
  }
  cancelAction(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    if (this.slInput) {
      this.data.confirmAction(this.slInput);
    }
    this.dialogRef.close();
  }
}
