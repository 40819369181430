import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivationEnd, Router } from "@angular/router";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { EnvService, TokenHelperService } from "gematik-form-library";
import { GemFeedbackComponent } from "../gematik-feedback/containers/gem-feedback.component";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Subscription, take } from "rxjs";
import * as globals from "../globals/globals";
import { QuickAction } from "../models/quick-action.model";
import { PortalService } from "../services/portal.service";
import { UtilService } from "../services/util.service";
import { OAuthService } from "angular-oauth2-oidc";

@Component({
  selector: "gem-header",
  styleUrls: ["gematik-header.component.scss"],
  template: `
    <nav *ngIf="!smallScreenBreakpointMatched" style="background-color: var(--gematik-blue)">
      <div class="container d-flex flex-wrap pt-0">
        <ul class="nav me-auto"></ul>
        <div class="nav">
          <div class="nav-item d-flex align-items-center">
            <a (click)="onLangChange()" class="d-flex nav-link link-white px-2">
              <span
                data-testid="lang"
                (click)="onLangChange()"
                class="material-icons me-2"
                style="color: white !important; text-decoration: none;"
              >
                language
              </span>
              {{ lang === "de" ? "English" : "Deutsch" }}
            </a>
          </div>
          <div *ngIf="guidedTourId" class="nav-item ms-3 d-flex align-items-center">
            <a (click)="onHelp()" class="d-flex nav-link link-white px-2">
              <img src="/gematik-shared/assets/icons/help_outline.svg" class="me-2" />
              {{ "header.help.label" | translate }}
            </a>
          </div>
          <div id="btnFeedback" class="nav-item ms-3 d-flex align-items-center">
            <a (click)="onFeedback()" class="d-flex nav-link link-white px-2">
              <img src="/gematik-shared/assets/icons/feedback.svg" class="me-2" />
              {{ "header.feedback.label" | translate }}
            </a>
          </div>
          <div
            *ngIf="isLoggedIn && showQuickAction"
            class="nav-item ms-3 d-flex align-items-center"
          >
            <a (click)="onAddQuickAction()" class="d-flex nav-link link-white px-2">
              <span class="material-icons me-2" style="color: white"> add </span>
              {{ "header.quickAction.label" | translate }}
            </a>
          </div>
        </div>
      </div>
    </nav>

    <nav class="py-2 border-bottom" style="background-color: white">
      <div class="container p-3 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <a (click)="onHome()">
            <div class="d-flex">
              <div>
                <img height="30px" src="/gematik-shared/assets/icons/logo.svg" alt="" />
              </div>
              <div class="align-self-end">
                <span class="gem-color-dark-grey ms-1">myServices</span>
              </div>
            </div>
          </a>
        </div>
        <div *ngIf="!smallScreenBreakpointMatched" class="d-flex align-items-center">
          <button
            *ngIf="!isLoggedIn && showRegistration"
            class="btn btn-primary-neon me-5"
            (click)="onRegistration()"
          >
            {{ "header.registration.label" | translate }}
          </button>

          <div *ngIf="!isLoggedIn" class="d-flex align-items-center">
            <img src="/gematik-shared/assets/icons/person.svg" alt="" class="me-1" />
            <a class="gem-link-blue" (click)="onLogin()">
              {{ "header.login.label" | translate }}
            </a>
          </div>

          <div *ngIf="isLoggedIn" class="d-flex align-items-center">
            <img src="/gematik-shared/assets/icons/person.svg" alt="" class="me-1" />
            <span class="gem-link-blue" [matMenuTriggerFor]="userMenu" style="cursor: pointer"
              >&nbsp;&nbsp;{{ fullName }}</span
            >
          </div>
        </div>
        <div *ngIf="smallScreenBreakpointMatched">
          <img
            src="/gematik-shared/assets/icons/toggler.svg"
            alt=""
            style="cursor: pointer"
            (click)="openTop(content)"
          />
        </div>
        <ng-template #content let-offcanvas>
          <div class="offcanvas-body">
            <div class="d-flex justify-content-end">
              <img
                src="/gematik-shared/assets/icons/close.svg"
                style="cursor: pointer"
                (click)="onCloseSmallScreenMenu()"
              />
            </div>
            <ul class="mt-5 list-group list-group-flush">
              <li *ngIf="!isLoggedIn && showRegistration" class="list-group-item">
                <a class="gem-link-blue me-5" (click)="onRegistration()">
                  {{ "header.registration.label" | translate }}
                </a>
              </li>
              <li *ngIf="isLoggedIn" class="list-group-item">
                <a class="gem-link-blue me-5" (click)="onDashboard()">
                  {{ "header.dashboard.label" | translate }}
                </a>
              </li>
              <li *ngIf="!isLoggedIn" class="list-group-item">
                <a class="gem-link-blue me-5" (click)="onLogin()">
                  {{ "header.login.label" | translate }}
                </a>
              </li>
              <li *ngIf="isLoggedIn" class="list-group-item">
                <a class="gem-link-blue me-5" (click)="onLogout()">
                  {{ "header.logout.label" | translate }}
                </a>
              </li>
              <li class="list-group-item">
                <div class="d-flex align-items-center ">
                  <img
                    style="cursor: pointer;"
                    class="me-2"
                    src="/gematik-shared/assets/icons/translate-blue.svg"
                  />
                  <a class="gem-link-blue" (click)="onLangChange()">
                    {{ lang === "de" ? "English" : "Deutsch" }}
                  </a>
                </div>
              </li>
              <li *ngIf="guidedTourId" class="list-group-item">
                <a class="gem-link-blue" (click)="onHelp()">
                  {{ "header.help.label" | translate }}
                </a>
              </li>
              <li class="list-group-item">
                <a class="gem-link-blue" (click)="onFeedback()">
                  {{ "header.feedback.label" | translate }}
                </a>
              </li>
              <li *ngIf="isLoggedIn && showQuickAction" class="list-group-item">
                <a class="gem-link-blue" (click)="onAddQuickAction()">
                  {{ "header.quickAction.label" | translate }}
                </a>
              </li>
            </ul>
          </div>
        </ng-template>
      </div>
    </nav>
    <mat-menu #userMenu="matMenu">
      <ng-template [ngTemplateOutlet]="userMenuTemplateRef"></ng-template>
      <button id="logout-button" mat-menu-item (click)="onSignOut()">
        {{ "header.logout.label" | translate }}
      </button>
    </mat-menu>
  `,
})
export class GematikHeaderComponent implements OnInit, OnDestroy {
  @Input() postLoginPath: string = undefined;
  @Input() showQuickAction: boolean = true;
  @Input() showRegistration: boolean = true;
  @Input() quickActionIcon: string;
  @Input() userMenuTemplateRef: TemplateRef<any>;

  @Output() startGuidedTour: EventEmitter<number> = new EventEmitter();

  smallScreenBreakpointMatched: boolean = false;
  guidedTourId: number;
  subscriptions: Subscription[] = [];

  constructor(
    private offcanvasService: NgbOffcanvas,
    private translateService: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private env: EnvService,
    private breakpointObserver: BreakpointObserver,
    private portalService: PortalService,
    private tokenHelperService: TokenHelperService,
    private utilService: UtilService,
    private oauthService: OAuthService,
  ) {}

  ngOnInit(): void {
    const sub = this.breakpointObserver
      .observe([`(max-width: ${globals.smallScreenBreakpoint})`])
      .subscribe((state) => {
        this.smallScreenBreakpointMatched =
          state.breakpoints[`(max-width: ${globals.smallScreenBreakpoint})`];
      });
    this.subscriptions.push(sub);

    const routerSub = this.router.events.subscribe((e) => {
      if (e instanceof ActivationEnd && e.snapshot.component) {
        if (e.snapshot.data.guidedTourId) {
          this.guidedTourId = e.snapshot.data.guidedTourId;
        } else {
          this.guidedTourId = undefined;
        }
      }
    });
    this.subscriptions.push(routerSub);
  }

  openTop(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: "top", panelClass: "height-100" });
  }

  onLangChange() {
    if (this.translateService.currentLang === "de") {
      this.translateService.use("en");
      localStorage.setItem("gem-locale", "en");
    } else {
      this.translateService.use("de");
      localStorage.setItem("gem-locale", "de");
    }
  }

  onHelp(): void {
    this.onCloseSmallScreenMenu();
    this.startGuidedTour.emit(this.guidedTourId);
  }

  onFeedback(): void {
    this.onCloseSmallScreenMenu();
    this.dialog.open(GemFeedbackComponent, {
      data: {
        backendUrl: this.env.feedbackBackendUrl,
        application: "Portal",
        component: "Portal Public page",
      },
      width: "800px",
    });
  }

  onRegistration(): void {
    this.onCloseSmallScreenMenu();
    window.open(this.env.registrationUrl);
  }

  onLogin(): void {
    this.onCloseSmallScreenMenu();
    const path: string = this.postLoginPath ? this.postLoginPath : window.location.pathname;
    this.oauthService.initLoginFlow(path);
  }

  onSignOut(): void {
    const url = window.location.origin;
    window.location.href = `https://login.windows.net/${this.env.oauthTennantId}/oauth2/logout?post_logout_redirect_uri=${url}`;
    window.sessionStorage.clear();
    localStorage.clear();
  }

  onCloseSmallScreenMenu(): void {
    this.offcanvasService.dismiss();
  }

  onHome(): void {
    this.router.navigate(["/"]);
  }

  onDashboard(): void {
    this.offcanvasService.dismiss();
    this.router.navigate(["dashboard"]);
  }

  onAddQuickAction(): void {
    this.offcanvasService.dismiss();
    const elements = window.document.querySelectorAll("[data-quick-action-app-id]");
    let applicationId: string = undefined;
    let titleKey: string = undefined;
    let titlePrefixKey: string = undefined;

    if (elements && elements.length > 0) {
      const element = elements[0] as HTMLAnchorElement;
      applicationId = element.dataset.quickActionAppId;
      titleKey = element.dataset.quickActionDisplayKey;
      titlePrefixKey = element.dataset.quickActionDisplayPrefixKey;
    }
    if (applicationId && titleKey) {
      this.utilService
        .getQuickAction(applicationId, titleKey, this.quickActionIcon, titlePrefixKey)
        .pipe(take(1))
        .subscribe((qa: QuickAction) => {
          this.portalService.addQuickAction(this.env.backendUrl, qa);
        });
    } else {
      alert("Quick action application and/or title is undefined");
      console.error("Quick action application and/or title is undefined");
    }
  }

  get lang(): string {
    return this.translateService.currentLang;
  }

  get isLoggedIn(): boolean {
    return this.tokenHelperService.isLoggedIn();
  }

  get fullName(): string {
    return this.tokenHelperService.getFullname();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
