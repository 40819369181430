import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Feedback } from "../../models/feedback.model";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { GemValidators, TokenHelperService } from "gematik-form-library";

@Component({
  selector: "gem-feedback-feature-form",
  styleUrls: ["gem-feedback-feature-form.component.scss"],
  template: ` <form [formGroup]="form">
    <div class="row mb-3">
      <div class="col-sm-12 mt-3">
        <p>{{ "feedback.featureComments" | translate }}</p>
        <gem-textarea
          [placeholder]="'feedback.featureCommentsPlaceholder' | translate"
          [control]="this.form.get('comments')"
          [maxChars]="500"
          [hint]="
            translateService.instant('feedback.commentsHint') +
            ' (' +
            this.form.get('comments').value.length +
            ' / 500' +
            ')'
          "
        ></gem-textarea>
      </div>

      <div class="col-sm-12 mt-3">
        <p>{{ "feedback.featureContact" | translate }}</p>
        <gem-radio-button-group-vertical
          [valueLabels]="radioButtonOptions"
          [control]="this.form.get('contact')"
        ></gem-radio-button-group-vertical>
      </div>

      <div class="col-sm-12 mt-3">
        <p>
          {{
            emailIsRequired
              ? ("feedback.featureEmailRequired" | translate)
              : ("feedback.featureEmailOptional" | translate)
          }}
        </p>
        <gem-input
          [placeholder]="'email' | translate"
          [hint]="'feedback.emailHint' | translate"
          [control]="email"
        >
        </gem-input>
      </div>

      <div class="col-sm-12 text-end mt-3">
        <button
          type="button"
          class="btn btn-tertiary"
          style="display: inline-flex"
          (click)="onBack()"
        >
          <mat-icon class="me-2">chevron_left</mat-icon>
          {{ "feedback.back" | translate }}
        </button>

        <button
          class="mat-focus-indicator btn btn-primary mat-button mat-button-base"
          style="display: inline-flex"
          cdkFocusInitial
          type="button"
          [disabled]="!isFormValid()"
          (click)="onSubmit($event)"
        >
          {{ "feedback.send" | translate }}
          <mat-icon iconPositionEnd class="ms-2">send</mat-icon>
        </button>
      </div>
    </div>
  </form>`,
})
export class GemFeedbackFeatureFormComponent implements OnInit, OnDestroy {
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() feedback: EventEmitter<Feedback> = new EventEmitter<Feedback>();

  form = this.fb.group({
    category: ["FEATURE", Validators.required],
    rating: [1],
    comments: ["", Validators.required],
    contact: ["ANONYMOUS"],
    email: [undefined],
  });

  radioButtonOptions = [
    { value: "ANONYMOUS", label: this.translateService.instant("feedback.radioButtonAnonymous") },
    { value: "WITH_EMAIL", label: this.translateService.instant("feedback.radioButtonWithEmail") },
  ];

  subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    private tokenHelperService: TokenHelperService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.form.get("contact").valueChanges.subscribe((change) => {
      if (change === "WITH_EMAIL") {
        this.form.get("email").addValidators([Validators.required, GemValidators.email]);
        this.form.get("email").updateValueAndValidity();
        if (this.tokenHelperService.isLoggedIn()) {
          this.form.get("email").setValue(this.tokenHelperService.getEmail());
        }
      } else {
        this.form.get("email").clearValidators();
        this.form.get("email").updateValueAndValidity();
        this.form.get("email").reset();
      }
    });
  }

  isFormValid(): boolean {
    return this.form.valid;
  }

  onBack(): void {
    this.back.emit();
  }

  onSubmit(event: any): void {
    const feedback: Feedback = this.form.getRawValue();
    this.feedback.emit(feedback);
    event.target.disabled = true;
  }

  get email(): FormControl {
    return this.form.get("email") as FormControl;
  }

  get emailIsRequired(): boolean {
    return this.email.hasValidator(Validators.required);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
